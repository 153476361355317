import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import CustomIcon from "components/CustomIcon";
import CustomModal from "components/CustomModal";
import { connect } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { uploadFile } from "store/actions/program";
import FileUploaderModel from "../EditProgram/FileUploaderModel";

const CardImageUploader = (props) => {
  const { setFieldValue, values } = props.formik;
  const { isLibrary = true } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showImageUploader, setImageUploader] = useState(false);
  const [file, setFile] = useState(null);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [count, setCount] = useState(id ? 0 : 1);

  const videoRef = useRef(null);

  useEffect(() => {
    setFile(props.thumbnail);
  }, [props.thumbnail]);

  useEffect(() => {
    if (values.thumbnail) {
      setFile(values.thumbnail);
    }
  }, [values.thumbnail]);

  const toggleImageUploader = () => {
    setImageUploader(!showImageUploader);
  };

  const togglePreviewModal = () => {
    setOpenPreviewModal(!openPreviewModal);
  };

  const styles = {
    position: "absolute",
    right: "50%",
    transform: "translate(50%,-50%)",
    top: "50%",
  };

  const renderImage = () => {
    if (file) {
      if (typeof file === "string") {
        return file;
      } else {
        return URL.createObjectURL(file);
      }
    } else {
      return "/assets/images/Photothumbnail.jpg";
    }
  };

  const renderVideo = () => {
    if (values.video) {
      return values.video;
    } else {
      return "/assets/images/Photothumbnail.jpg";
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleSeeked = () => {
      // Wait for the next frame to ensure the video is fully decoded
      requestAnimationFrame(captureThumbnail);
    };
    if (videoElement) {
      videoElement.addEventListener("loadeddata", captureThumbnail);

      return () => {
        videoElement.removeEventListener("loadeddata", captureThumbnail);
      };
    }
  }, [values.video]);

  function hasImage(canvas) {
    const context = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;

    // Get pixel data from the entire canvas
    const imageData = context.getImageData(0, 0, width, height);

    // Loop through the pixel data and check if there is any non-transparent pixel
    for (let i = 0; i < imageData.data.length; i += 4) {
      // Each pixel has 4 values: R, G, B, A
      const alpha = imageData.data[i + 3]; // alpha channel
      if (alpha > 0) {
        return true; // Image or drawing exists
      }
    }

    return false; // No visible image or drawing
  }

  const captureThumbnail = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Get video dimensions
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;

      // Set canvas size based on desired resolution
      // For example, creating a 1280x720 resolution thumbnail for better quality
      const targetWidth = 1280;
      const targetHeight = 720;

      // Maintain the aspect ratio
      const aspectRatio = videoWidth / videoHeight;
      if (aspectRatio > 1) {
        canvas.width = targetWidth;
        canvas.height = targetWidth / aspectRatio;
      } else {
        canvas.height = targetHeight;
        canvas.width = targetHeight * aspectRatio;
      }

      // Seek to a specific point in the video (middle of the video)
      const seekTime = video.duration / 2; // Capture thumbnail at the middle of the video
      video.currentTime = seekTime;
      // Wait for the video to load and capture the image when it’s ready
      video.onseeked = () => {
        // Use requestAnimationFrame to ensure the video has fully rendered
        requestAnimationFrame(() => {
          // Draw the video frame onto the canvas
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert canvas to a PNG data URL
          const img = canvas.toDataURL("image/png");
          if (hasImage(canvas) && count) {
            dispatch(uploadFile(img, props.imgApiKey)).then((response) => {
              setFieldValue("thumbnail", response);
            });
          }

          setCount(1);
        });
      };
    }
  };

  const getUploadItemLabel = () => {
    const mediaUrl = props.isVideo
      ? values.video
      : props.thumbnail || values.thumbnail;
    let mediaName = "";
    const mediaLabelStyled = {
      color: "#0001FC",
      fontWeight: "600",
      fontSize: "14px",
      textTransform: "none",
      flex: "10",
      textAlign: "center",
    };
    if (mediaUrl && props.isVideo) {
      const splitUrl = mediaUrl.split("/");
      mediaName = splitUrl[splitUrl.length - 1];
      return (
        <Typography
          variant="subtitle1"
          sx={{
            ...mediaLabelStyled,
            color: "#111111",
            fontSize: "12px",
            textAlign: "left",
            cursor: "default",
          }}
        >
          {mediaName}
        </Typography>
      );
    } else if (mediaUrl && !props.isVideo) {
      const regex = /\/\d+_(.+)$/;
      const match = mediaUrl.match(regex);
      if (match && match[1]) {
        mediaName = match[1];
      }
      if (!mediaName) {
        const splitUrl = mediaUrl.split("/");
        mediaName = splitUrl[splitUrl.length - 1];
      }
      return (
        <Typography
          variant="subtitle1"
          sx={{
            ...mediaLabelStyled,
            color: "#111111",
            fontSize: "12px",
            textAlign: "left",
            cursor: "default",
          }}
        >
          {mediaName}
        </Typography>
      );
    } else {
      mediaName = props.label;
      return (
        <Typography
          variant="subtitle1"
          onClick={toggleImageUploader}
          sx={{ ...mediaLabelStyled }}
        >
          {mediaName}
        </Typography>
      );
    }
  };
  return (
    <Card
      sx={{
        flex: 1,
        display: "flex",
        boxShadow: "0px 2px 8px 0px rgba(31, 79, 232, 0.04)",
        borderRadius: "16px",
        border: "1px solid #E0E4EA",
        cursor: "pointer",
        padding: "16px",
        mb: 3,
      }}
    >
      {props.isVideo ? (
        <div
          style={{
            position: "relative",
            background: "#E4EAFC",
            minHeight: "120px",
            minWidth: "96px",
            borderRadius: "8px",
          }}
          onClick={() =>
            values.video ? togglePreviewModal() : toggleImageUploader()
          }
        >
          {!values.video && (
            <img src="/assets/images/video.png" alt="" style={styles} />
          )}

          <CardMedia
            component="video"
            sx={{
              maxWidth: 96,
              height: "120px",
              objectFit: "contain",
              ml: "-1px",
            }}
            crossOrigin="anonymous"
            ref={videoRef}
            image={renderVideo()}
            alt="Upload image"
          />
        </div>
      ) : (
        <div
          style={{
            position: "relative",
            background: "#E4EAFC",
            minHeight: "120px",
            minWidth: "96px",
            borderRadius: "8px",
          }}
          onClick={() => {
            file ? togglePreviewModal() : toggleImageUploader();
          }}
        >
          {!file && (
            <img src="/assets/images/camera.png" alt="" style={{ ...styles }} />
          )}
          {file && (
            <CardMedia
              component="img"
              sx={{
                maxWidth: 96,
                height: "120px",
                objectFit: "contain",
                ml: "-1px",
              }}
              src={renderImage()}
              alt="Upload image my side"
            />
          )}
        </div>
      )}
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <CardContent
          sx={{
            flex: "1 0 auto",
            alignItems: "center",
            "&.MuiCardContent-root": {
              display: "flex",
              alignItems: "center",
              padding: "10px 0px 10px 20px",
            },
          }}
        >
          {getUploadItemLabel()}
          {props.isVideo && values.video && (
            <Box sx={{ flex: 2, justifyContent: "right" }}>
              <IconButton
                color="inherit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleImageUploader();
                }}
              >
                <CustomIcon
                  iconHoverSrc="/assets/images/video.png"
                  iconSrc="/assets/images/video_gray.png"
                />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setFieldValue(props.name, "");
                }}
              >
                <CustomIcon
                  iconHoverSrc="/assets/logo/trash_icon_hover.svg"
                  iconSrc="/assets/logo/trash_icon.svg"
                />
              </IconButton>
            </Box>
          )}
          {!props.isVideo && file && (
            <Box sx={{ flex: 2, justifyContent: "right" }}>
              <IconButton
                color="inherit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleImageUploader();
                }}
              >
                <CustomIcon
                  iconHoverSrc="/assets/images/camera.png"
                  iconSrc="/assets/images/camera_gray.png"
                />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setFieldValue(props.name, "");
                  setFile(null);
                }}
              >
                <CustomIcon
                  iconHoverSrc="/assets/logo/trash_icon_hover.svg"
                  iconSrc="/assets/logo/trash_icon.svg"
                />
              </IconButton>
            </Box>
          )}
        </CardContent>
      </Box>
      <FileUploaderModel
        isOpen={showImageUploader}
        toggle={toggleImageUploader}
        isVideo={props.isVideo}
        name={props.name}
        view={props.view}
        isLibrary={isLibrary}
        libraryApi={props.libraryApi}
        callback={(file) => {
          return dispatch(uploadFile(file, props.apiKey)).then((response) => {
            setFieldValue(props.name, response);
            setFile(file);
            toggleImageUploader();
          });
        }}
        libraryUploadcallback={(fileUrl) => {
          setFieldValue(props.name, fileUrl);
          toggleImageUploader();
        }}
      />
      <CustomModal open={openPreviewModal} onCloseHandler={togglePreviewModal}>
        {props.isVideo ? (
          <CardMedia
            component="video"
            image={renderVideo()}
            alt="Live from space album cover"
            controls
          />
        ) : (
          <CardMedia
            component="img"
            image={renderImage()}
            alt="Live from space album cover"
          />
        )}
      </CustomModal>
    </Card>
  );
};

export default connect(CardImageUploader);
