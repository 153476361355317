import { Box, Button, Stack } from "@mui/material";
import { FormContext } from "components/FormContext";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveDay } from "store/actions/program";

const CreateProgramDays = () => {
  const { formikRef } = useContext(FormContext);
  const dispatch = useDispatch();
  const [values, setValues] = useState([]);
  const activeDay = useSelector((state) => state.programs.activeDay);
  const programItem = useSelector((state) => state.programs.programItem);
  const activeWeek = useSelector((state) => state.programs.activeWeek);
  const [days, setDays] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [sessionsStatus, setSessionsStatus] = useState([]);
  const valuesSession = formikRef?.current?.values;

  useEffect(() => {
    setDays([1, 2, 3, 4, 5, 6, 7]);
  }, [programItem?.week]);

  useEffect(() => {
    let statuses = [];
    setTimeout(() => {
      if (formikRef) {
        days.forEach((val) => {
          statuses.push(
            formikRef?.current?.values.week[activeWeek][val - 1]?.isCompleted
          );
        });
        setSessionsStatus(statuses);
      }
    }, 500);
  }, [JSON.stringify(formikRef), activeWeek, activeDay]);
  const toggleButton = (response) => {
    dispatch(setActiveDay(response));
  };

  const getSessionStatus = (day) => {
    return formikRef?.current?.values?.week[activeWeek][day - 1]?.isCompleted;
  };
  useEffect(() => {
    if (programItem?.weeks) {
      if (programItem["weeks"][activeWeek + 1]) {
        setValues(Object.keys(programItem["weeks"][activeWeek + 1] || {}));
      } else {
        let program = programItem["weeks"];
        program[activeWeek + 1] = {};
        setValues(Object.keys(program[activeWeek + 1] || {}));
      }
    }
  }, [programItem, activeWeek, activeDay]);

  return (
    <Stack my={2}>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap="8px"
        justifyContent="space-between"
        mt={2}
      >
        {days?.map((ele, index) => (
          <Button
            color={
              activeDay === index || values.includes(String(ele))
                ? sessionsStatus[ele - 1]
                  ? "success"
                  : "primary"
                : "info"
            }
            key={index}
            className={sessionsStatus[ele - 1] ? "completed" : ""}
            variant={
              activeDay === index || !values.includes(String(ele))
                ? "contained"
                : "outlined"
            }
            onClick={() => toggleButton(index)}
            sx={{
              flex: 1,
              borderRadius: "16px",
              padding: "8px 16px",
              boxShadow: "none",
              minHeight: "64px",
              textTransform: "capitalize",
              fontSize: "12px",
              display: "inline-block",
              "&.MuiButton-outlinedPrimary": {
                backgroundColor: "#ffffff",
                border: "0 none",
                color: "#0001FC",
              },
              "&.MuiButton-outlinedSuccess": {
                backgroundColor: "#ffffff",
                border: "0 none",
                color: "#0001FC",
                "&.completed": {
                  border: "1px solid #119C83",
                  color: "#119C83",
                  backgroundColor: "#E4F2E5",
                },
              },
              "&.MuiButton-containedInfo": {
                backgroundColor: "transparent",
                border: "1px dashed #0001FC",
                color: "#0001FC",
              },
            }}
          >
            <Box variant="div" sx={{ width: "100%" }}>
              Jour {ele}
            </Box>
            {/* <Box
              variant="div"
              sx={{
                maxWidth: "100%",
                fontSize: "14px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {currentDay[ele]}
            </Box> */}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};

export default CreateProgramDays;
