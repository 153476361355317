import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Collapse, Grid } from "@mui/material";
import AddVariableModel from "components/CreateProgramPage/AddVariableModel";
import { connect } from "formik";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import AddRecipeModel from "../AddRecipeModel";
import ExerciseCard from "./ExerciseCard";
import ExerciseDetailModel from "./ExerciseDetailModel";

const ExerciseNutrition = ({ formik }) => {
  const { setFieldValue, values } = formik;
  const [current, setCurrent] = React.useState(null);
  const [addExercises, setAddExercisesModel] = useState(false);
  const [open, setOpen] = React.useState([]);

  const recipesLists = useSelector((state) => state.recipes.searchedRecipes);
  const activeWeek = useSelector((state) => state.nutritions.activeWeek);
  const activeDay = useSelector((state) => state.nutritions.activeDay);

  const whereTo = `week.${activeWeek}.[${activeDay}]`;
  const activeElement = values["week"]
    ? values["week"][activeWeek]
      ? values["week"][activeWeek][activeDay]
      : {}
    : {};

  const toggleCollapse = (id = "") => {
    let list = open;
    if (list.includes(id)) {
      list = list.filter((ele) => ele !== id);
    } else {
      list.push(id);
    }
    setOpen([...list]);
  };

  const toggleExerciseModel = (response = null) => {
    setCurrent(response);
    setAddExercisesModel(!addExercises);
  };

  const renderExercisesLists = useMemo(() => {
    let list = [];
    const meals = activeElement ? (activeElement.meals || []).length : 0;
    for (let index = 0; index < meals; index++) {
      const recipes = activeElement.meals[index]
        ? activeElement.meals[index].recipes
        : [];
      const elements = _.isEmpty(recipesLists)
        ? []
        : _.map(recipes, (_id) => {
            return _.find(recipesLists, { _id: _id });
          });
      const exercisesList = elements ? elements : [];
      list.push(
        <Draggable draggableId={`recipegroup_${index}`} index={index}>
          {(provided) => (
            <Grid
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
              container
              my={3}
              columnSpacing={1}
              alignItems="center"
              key={index}
              sx={{
                background: "#ffffff",
                padding: "24px",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Collapse
                in={true}
                timeout="auto"
                sx={{
                  width: "100%",
                  p: 2,
                  borderRadius: "16px",
                  backgroundColor: "#F5F5F5",
                }}
                unmountOnExit
              >
                <ExerciseCard index={index} exercisesList={exercisesList} />
                <Box mt={2} textAlign="center">
                  <Button
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => toggleExerciseModel(index)}
                  >
                    Ajouter une recette
                  </Button>
                </Box>
              </Collapse>
            </Grid>
          )}
        </Draggable>
      );
    }
    return list;
  }, [activeElement, open]);
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const sourceRecipeGroupIndex = parseInt(source.droppableId.split("_")[1]);
    const destinationRecipeGroupIndex = parseInt(
      destination.droppableId.split("_")[1]
    );
    if (source.droppableId === destination.droppableId) {
      if (result.type === "droppableSubItem") {
        const items = reorder(
          activeElement["meals"][sourceRecipeGroupIndex].recipes,
          result.source.index,
          result.destination.index
        );

        setFieldValue(whereTo + `.meals[${sourceRecipeGroupIndex}].recipes`, [
          ...items,
        ]);
      }
      if (result.type === "droppableItem") {
        keepListItemInExistingState(source, destination);
        const items = reorder(
          activeElement["meals"],
          result.source.index,
          result.destination.index
        );

        setFieldValue(whereTo + ".meals", items);
      }
    } else {
      const sourceRecipes =
        activeElement["meals"][sourceRecipeGroupIndex].recipes;
      const destinationRecipes =
        activeElement["meals"][destinationRecipeGroupIndex].recipes;
      moveItemBetweenList(
        sourceRecipes,
        destinationRecipes,
        source,
        destination,
        sourceRecipeGroupIndex,
        destinationRecipeGroupIndex
      );
    }
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const keepListItemInExistingState = (source, destination) => {
    const sourceItemIndex = _.indexOf(open, source.index);
    const destinationItemIndex = _.indexOf(open, destination.index);
    if (sourceItemIndex !== -1) {
      open.splice(sourceItemIndex, 1, destination.index);
      setOpen(open);
    } else if (destinationItemIndex !== -1) {
      open.splice(sourceItemIndex, 1, source.index);
      setOpen(open);
    } else {
      const updatedOpen = _.map(open, (o) => {
        if (destination.index < o) {
          o++;
        } else if (destination.index > o) {
          o--;
        }
        return o;
      });
      setOpen(updatedOpen);
    }
  };

  const moveItemBetweenList = (
    source,
    destination,
    droppableSource,
    droppableDestination,
    sourceRecipeGroupIndex,
    destinationRecipeGroupIndex
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);

    const [removed] = sourceClone.splice(droppableSource.index, 1);

    if (_.indexOf(destClone, removed) !== -1) {
      toast.error("La recette existe déjà");
      return;
    }
    destClone.splice(droppableDestination.index, 0, removed);
    setFieldValue(
      whereTo + `.meals[${sourceRecipeGroupIndex}].recipes`,
      sourceClone
    );
    setFieldValue(
      whereTo + `.meals[${destinationRecipeGroupIndex}].recipes`,
      destClone
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box>
        <Droppable
          droppableId={`nutritionlist_${activeDay}`}
          type="droppableItem"
        >
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {renderExercisesLists}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        <ExerciseDetailModel />
        <AddVariableModel />
        {addExercises && (
          <AddRecipeModel
            title="Ajouter une recette"
            isOpen={addExercises}
            current={current}
            toggle={toggleExerciseModel}
          />
        )}
      </Box>
    </DragDropContext>
  );
};

export default connect(ExerciseNutrition);
