import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  styled,
} from "@mui/material";
import CustomIcon from "components/CustomIcon";
import Field from "components/Field";
import { connect } from "formik";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddExerciseModel from "../AddExerciseModel";
import AddVariableModel from "../AddVariableModel";
import ExerciseCard from "./ExerciseCard";

const StyledCollapse = styled(Collapse)(() => ({
  "&.MuiCollapse-hidden .MuiList-root": {
    display: "none",
  },
}));

const SeanceExercise = ({ formik }) => {
  const { setFieldValue, values } = formik;
  const { id } = useParams();
  const activeDay = useSelector((state) => state.programs.activeDay);
  const activeWeek = useSelector((state) => state.programs.activeWeek);
  const [addExercides, setaddExercidesModel] = useState(false);
  const [open, setOpen] = React.useState([0]);
  const [current, setCurrent] = React.useState(null);
  const whereTo = `week.${activeWeek}.[${activeDay}]`;
  const activeElement = values["week"]
    ? values["week"][activeWeek]
      ? values["week"][activeWeek][activeDay]
      : {}
    : {};

  useEffect(() => {
    // reset expanded boxes
    setOpen([0]);
  }, [activeDay, activeWeek]);
  const addGroup = () => {
    let exercisesList = activeElement ? activeElement["exercise_groups"] : [];
    const newElement = {
      name: "",
      exercises: [],
      muscle: 1,
    };
    setOpen((prev) => [...prev, (exercisesList || []).length]);
    setFieldValue(whereTo + `.exercise_groups`, [...exercisesList, newElement]);
  };

  const delteGroup = (index = null) => {
    let exercisesList = activeElement ? activeElement["exercise_groups"] : [];

    const data = [...exercisesList];
    data.splice(index, 1);
    setFieldValue(whereTo + `.exercise_groups`, [...data]);
  };

  const toggleCollapse = (id = "") => {
    let list = open;
    if (list.includes(id)) {
      list = list.filter((ele) => ele !== id);
    } else {
      list.push(id);
    }
    setOpen([...list]);
  };

  const toggleExerciseModel = (response = null) => {
    setCurrent(response);
    setaddExercidesModel(!addExercides);
  };

  const renderExercisesLists = useMemo(() => {
    let list = [];
    const exercise_groups = activeElement
      ? (activeElement.exercise_groups || []).length
      : 0;
    for (let index = 0; index < exercise_groups; index++) {
      const exercises = activeElement.exercise_groups[index]
        ? activeElement.exercise_groups[index].exercises
        : [];
      const totalSets = exercises
        ? exercises[0]
          ? (exercises[0].sets || []).length
          : 1
        : 1;
      const exercisesList = exercises ? _.map(exercises, "program") : [];
      list.push(
        <Draggable draggableId={`exercise_${index}`} index={index}>
          {(provided) => (
            <Box
              mb={3}
              py={2}
              px={3}
              mt={3}
              sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
            >
              <Grid
                ref={provided.innerRef}
                {...provided.dragHandleProps}
                {...provided.draggableProps}
                key={index}
                container
                columnSpacing={1}
                alignItems="center"
                sx={{
                  position: "relative",
                  paddingLeft: "50px",
                }}
              >
                <IconButton
                  sx={{ position: "absolute", left: "0px", top: "5px" }}
                  onClick={() => toggleCollapse(index)}
                >
                  {open.includes(index) ? <ExpandMore /> : <ChevronRightIcon />}
                </IconButton>
                {!_.isEmpty(exercisesList) && (
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      "&.MuiGrid-item": {
                        paddingLeft: "0px",
                      },
                    }}
                  >
                    <AvatarGroup max={3} spacing="small">
                      {exercisesList.map((img, index) => (
                        <Avatar alt="image" key={index} src={img?.thumbnail} />
                      ))}
                    </AvatarGroup>
                  </Grid>
                )}
                <Grid item xs={12} md={_.isEmpty(exercisesList) ? 1 : 1.5}>
                  <Field
                    mb={0}
                    type="select"
                    label="Séries"
                    name={whereTo + `.exercise_groups[${index}].muscle`}
                    size="small"
                    placeholder="sets"
                    disabled={
                      _.isEmpty(exercisesList) || activeElement?.isCompleted
                    }
                    defaultValue={totalSets}
                    selectsx={{ background: "white", borderRadius: "10px" }}
                  >
                    <MenuItem value={1}>1 série</MenuItem>
                    <MenuItem value={2}>2 séries</MenuItem>
                    <MenuItem value={3}>3 séries</MenuItem>
                    <MenuItem value={4}>4 séries</MenuItem>
                    <MenuItem value={5}>5 séries</MenuItem>
                    <MenuItem value={6}>6 séries</MenuItem>
                  </Field>
                </Grid>
                <Grid
                  item
                  md={_.isEmpty(exercisesList) ? 10 : 8.5}
                  sx={{ mt: { xs: 2, md: 0 } }}
                >
                  <Field
                    type="text"
                    name={whereTo + `.exercise_groups[${index}].name`}
                    size="small"
                    placeholder="Titre de la section"
                    disabled={activeElement?.isCompleted}
                    mb={0}
                    selectsx={{ background: "white", borderRadius: "10px" }}
                  />
                </Grid>
                <Grid item md={1} alignItems="center">
                  <Stack direction="row" justifyContent="end">
                    <IconButton onClick={() => delteGroup(index)}>
                      <CustomIcon
                        title="Delete Exercise"
                        iconSrc="/assets/logo/trash_icon.svg"
                        iconHoverSrc="/assets/logo/trash_icon_hover.svg"
                      />
                    </IconButton>
                    <IconButton>
                      <DragIndicatorIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
              <StyledCollapse
                in={open.includes(index)}
                timeout="auto"
                sx={{
                  width: "100%",
                  backgroundColor: "#F5F5F5",

                  borderRadius: "8px",
                  marginTop: open.includes(index) ? 3 : 0,
                }}
              >
                <Box p={3}>
                  <ExerciseCard index={index} exercisesList={exercisesList} />
                  <Box mt={2} textAlign="center">
                    <Button
                      variant="text"
                      startIcon={<AddIcon />}
                      onClick={() => toggleExerciseModel(index)}
                    >
                      Ajouter un exercice
                    </Button>
                  </Box>
                </Box>
              </StyledCollapse>
            </Box>
          )}
        </Draggable>
      );
    }
    return list;
  }, [activeElement, open]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const sourceExerciseGroupIndex = parseInt(source.droppableId.split("_")[1]);
    const destinationExterciseGroupIndex = parseInt(
      destination.droppableId.split("_")[1]
    );
    if (source.droppableId === destination.droppableId) {
      if (result.type === "droppableSubItem") {
        const items = reorder(
          activeElement["exercise_groups"][sourceExerciseGroupIndex].exercises,
          result.source.index,
          result.destination.index
        );
        setFieldValue(
          whereTo + `.exercise_groups[${sourceExerciseGroupIndex}].exercises`,
          [...items]
        );
      }
      if (result.type === "droppableItem") {
        keepListItemInExistingState(source, destination);
        const items = reorder(
          activeElement["exercise_groups"],
          result.source.index,
          result.destination.index
        );
        const updatedActiveElement = {
          ...activeElement,
          exercise_groups: [...items],
        };

        setFieldValue(whereTo, updatedActiveElement);
      }
    } else {
      const sourceExercises =
        activeElement["exercise_groups"][sourceExerciseGroupIndex].exercises;
      const destinationExercises =
        activeElement["exercise_groups"][destinationExterciseGroupIndex]
          .exercises;
      moveItemBetweenList(
        sourceExercises,
        destinationExercises,
        source,
        destination,
        sourceExerciseGroupIndex,
        destinationExterciseGroupIndex
      );
    }
  };

  const keepListItemInExistingState = (source, destination) => {
    const sourceItemIndex = _.indexOf(open, source.index);
    const destinationItemIndex = _.indexOf(open, destination.index);
    if (sourceItemIndex !== -1) {
      open.splice(sourceItemIndex, 1, destination.index);
      setOpen(open);
    } else if (destinationItemIndex !== -1) {
      open.splice(sourceItemIndex, 1, source.index);
      setOpen(open);
    } else {
      const updatedOpen = _.map(open, (o) => {
        if (destination.index < o) {
          o++;
        } else if (destination.index > o) {
          o--;
        }
        return o;
      });
      setOpen(updatedOpen);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const moveItemBetweenList = (
    source,
    destination,
    droppableSource,
    droppableDestination,
    sourceExerciseGroupIndex,
    destinationExterciseGroupIndex
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    setFieldValue(
      whereTo + `.exercise_groups[${sourceExerciseGroupIndex}].exercises`,
      sourceClone
    );
    setFieldValue(
      whereTo + `.exercise_groups[${destinationExterciseGroupIndex}].exercises`,
      destClone
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box>
        <Droppable
          droppableId={`exterciseslist_${activeDay}`}
          type="droppableItem"
        >
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {renderExercisesLists}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        <Box mt={2} textAlign="center">
          <Button variant="outlined" startIcon={<AddIcon />} onClick={addGroup}>
            Ajouter une section
          </Button>
        </Box>

        {addExercides && (
          <AddExerciseModel
            isOpen={addExercides}
            current={current}
            toggle={toggleExerciseModel}
          />
        )}
        <AddVariableModel />
      </Box>
    </DragDropContext>
  );
};

export default connect(SeanceExercise);
