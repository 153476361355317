import { Box, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import jwtDecode from "jwt-decode";
import NavSection from "layout/NavItems";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setPrompt } from "store/actions/prompt";
import Scrollbar from "utils/Scrollbar";
import { isDynamicRouteMatch } from "utils/common";
import { ATTENTION_MESSAGE } from "utils/constants";
import Cookie from "utils/cookie";
import useResponsive from "utils/hooks/useResponsive";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 272;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const token = Cookie.get("jwt");
  const decodedToken = token ? jwtDecode(token) : null;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const showPromptModal = (nextUrl) => {
    if (isDynamicRouteMatch(pathname)) {
      dispatch(
        setPrompt({
          isOpen: true,
          message: ATTENTION_MESSAGE,
          primaryBtnHandler: () => {
            navigate(nextUrl);
          },
          btnAlign: "right",
          primaryBtnColor: "error",
          secondaryBtnText: "Annuler",
        })
      );
    } else {
      navigate(nextUrl);
    }
  };

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          "& .simplebar-content-wrapper": {
            maxHeight: "100vh",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
              backgroundColor: "#F5F5F5",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: " inset 0 0 8px rgba(0,0,0,0.3)",
              borderRadius: "12px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4F5668",
              borderRadius: "12px",
            },
          },
          "& .simplebar-content": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box
          onClick={() => showPromptModal("/")}
          sx={{
            display: "flex",
            cursor: "pointer",
            justifyContent: "center",
            pt: 4,
            pb: 1,
          }}
        >
          <img
            src="/assets/images/evocoach-logo.png"
            alt="logo"
            height="26px"
          />
        </Box>
        <NavSection role={decodedToken?.type} />
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      {isDesktop ? (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              background: "#FFFFFF",
              height: "auto",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              background: "#111111",
              backgroundImage: "url(./assets/images/sidebar.svg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center bottom",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
