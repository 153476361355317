import { combineReducers } from "redux";
import exercise from "store/reducer/exercise";
import program from "store/reducer/program";
import clients from "./reducer/clients";
import coaches from "./reducer/coaches";
import error from "./reducer/error";
import header from "./reducer/header";
import nutritions from "./reducer/nutritions";
import prompt from "./reducer/prompt";
import recipes from "./reducer/recipes";
import sessions from "./reducer/sessions";
import user from "./reducer/user";

const rootReducer = combineReducers({
  programs: program,
  exercise,
  recipes,
  nutritions,
  clients,
  user,
  sessions,
  header,
  coaches,
  prompt,
});

export default rootReducer;
