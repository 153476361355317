import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ConfirmationModel from "../../../ConfirmationModel";
import { connect, useSelector } from "react-redux";
import { toggleExerciseModel } from "../../../../store/actions/program";

const ExerciseDetailModel = (props) => {
    const currentElment = useSelector((state) => state.programs.currentElment);

    return (
        <ConfirmationModel
            showActions={false}
            maxWidth="xs"
            isOpen={props.isOpenExerciseModel}
            title="Détails de l’exercice"
            body={
                <Card sx={{ boxShadow: "none" }}>
                    {currentElment && (
                        <>
                            <CardMedia
                                sx={{ height: 216 }}
                                image={currentElment.thumbnail}
                                title="green iguana"
                            />
                            <CardContent sx={{ px: 0, py: 3 }}>
                                <Typography gutterBottom variant="h5" component="div" color="#222222" fontSize="18px">
                                    {currentElment.name}
                                </Typography>
                                <Typography variant="body2" color="#222222">
                                    <div dangerouslySetInnerHTML={{__html:currentElment.description}}/>
                                </Typography>
                            </CardContent>
                            {/* <CardActions sx={{ justifyContent: "center" }}>
                                <Button size="small">LIRE PLUS</Button>
                            </CardActions> */}
                        </>
                    )}
                </Card>
            }
            handleClose={() => props.toggleExerciseModel()}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        isOpenExerciseModel: state.programs.isOpenExerciseModel
    }
}
export default connect(mapStateToProps, {
    toggleExerciseModel
})(ExerciseDetailModel);
