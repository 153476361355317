import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CardImageUploader from "components/CardImageUploader";
import ConfirmationModel from "components/ConfirmationModel";
import Field from "components/Field";
import { multiSelect, required } from "components/Field/validate";
import Loader from "components/Loader";
import TitleHeader from "components/TitleHeader";
import WysiwygEditor from "components/WysiwygEditor";
import { Formik } from "formik";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createExercises, getCurrentExercises } from "store/actions/exercise";
import { setHeaderDetails } from "store/actions/header";
import { THUMBNAIL_PLACEHOLDER } from "utils/constants";
import { muscle } from "utils/gym.utils";

const EditExercice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const formRef = useRef(null);
  const currentExercise = useSelector(
    (state) => state.exercise.currentExercise
  );
  const userDetails = useSelector((state) => state.user.userDetails);
  const isLoadingExercise = useSelector(
    (state) => state.exercise.isLoadingExercise
  );
  const [editorValue, setEditorValue] = useState("<p></p>");
  const [savedEditorValue, setSavedEditorValue] = useState("<p></p>");

  const [showImageModel, setImageModel] = useState(false);
  const [selectedFile, setselectedFile] = useState("thumbnail");
  const [showRequiredVideoErr, setShowRequiredVideoErr] = useState(false);

  useEffect(() => {
    if (currentExercise) {
      setSavedEditorValue(currentExercise.description);
    }
  }, [currentExercise]);

  useEffect(() => {
    if (id) {
      dispatch(getCurrentExercises(id));
    }
    const navDetail = {
      isBreadcrumns: true,
      title: "",
      breadcrumsList: [
        {
          title: "Bibliothèque d’exercices",
          link: "/fitness/exercices",
        },
        {
          title: "Créer un exercice",
          link: "",
        },
      ],
    };
    dispatch(setHeaderDetails(navDetail));
  }, []);

  const toggleImageModel = (file = "thumbnail") => {
    setImageModel(!showImageModel);
    setselectedFile(file);
  };

  const getOptionValue = (primary_muscle) => {
    const data = primary_muscle
      ? _.map(primary_muscle.split(","), (n) => {
          return muscle.find((e) => e.name === n);
        })
      : [];
    return data;
  };

  function removeHtmlTags(text = "") {
    const element = document.createElement("div");
    element.innerHTML = text;
    return element.textContent || element.innerText || "";
  }

  const initialValues = () => {
    const data = {
      name: currentExercise ? currentExercise.name : "",
      primary_muscle: currentExercise
        ? getOptionValue(currentExercise.primary_muscle)
        : "",
      secondary_muscle: currentExercise
        ? getOptionValue(currentExercise.secondary_muscle)
        : "",
      thumbnail: currentExercise ? currentExercise.thumbnail : "",
      video: currentExercise ? currentExercise.video : "",
      description: currentExercise ? currentExercise.description : "",
    };
    return data;
  };

  if (isLoadingExercise) {
    return <Loader isLoading={isLoadingExercise} />;
  }
  const handleFormSubmit = () => {
    formRef.current?.handleSubmit();
  };
  return (
    <>
      <Container
        maxWidth="xxl"
        className="header-box"
        sx={{ padding: "0 !important", marginBottom: "20px" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <TitleHeader title="Créer un exercice" />
          {(!id || currentExercise?.created_by === userDetails?.user_id) && (
            <Button
              type="submit"
              color="success"
              variant="contained"
              onClick={() => handleFormSubmit()}
            >
              Sauvegarder
            </Button>
          )}
        </Stack>
      </Container>
      <Container className="detail" maxWidth="xxl">
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={initialValues()}
          onSubmit={(values) => {
            setIsFormDirty(false);
            if (!values.video) {
              setShowRequiredVideoErr(true);
              return;
            }

            const data = { ...values };
            data.primary_muscle = _.map(data.primary_muscle, "name").join(",");
            data.secondary_muscle = _.map(data.secondary_muscle, "name").join(
              ","
            );
            data.thumbnail = data.thumbnail
              ? data.thumbnail
              : THUMBNAIL_PLACEHOLDER;
            data.code = null;
            data.description = editorValue;
            return dispatch(createExercises(data, id));
          }}
        >
          {(props) => {
            const { handleSubmit, isSubmitting, values, dirty } = props;
            if (!isSubmitting) setIsFormDirty(dirty);
            return (
              <form onSubmit={handleSubmit}>
                <Stack>
                  <Grid container spacing={5}>
                    <Grid item sm={6}>
                      <Box pr={5} sx={{ borderRight: "1px solid #E0E4EA" }}>
                        <TitleHeader
                          el="h3"
                          mb={3}
                          title="Details"
                          fw={600}
                          fs={16}
                        />
                        <Field
                          type="text"
                          label="Titre"
                          placeholder="Titre de I'exercice"
                          name="name"
                          validate={required}
                          mb={4}
                        />
                        <Field
                          type="multiselect"
                          label="Muscles primaires"
                          placeholder="Muscles primaires"
                          name="primary_muscle"
                          notched={true}
                          options={muscle}
                          validate={multiSelect}
                          mb={4}
                        />
                        <Field
                          type="multiselect"
                          label="Muscles secondaires"
                          placeholder="Muscles secondaires"
                          name="secondary_muscle"
                          options={muscle}
                          notched={true}
                          mb={4}
                        />
                        <WysiwygEditor
                          editorValue={editorValue}
                          setEditorValue={setEditorValue}
                          savedEditorValue={savedEditorValue}
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={6}>
                      <Box mb={2} sx={{ fontSize: "12px", fontWeight: "600" }}>
                        Image de l’exercice
                      </Box>
                      <CardImageUploader
                        name="thumbnail"
                        label="Ajouter une image"
                        view="portrait"
                        isLibrary={false}
                        libraryApi="/api/coach/exercise/image/library"
                        thumbnail={
                          currentExercise ? currentExercise.thumbnail : null
                        }
                        apiKey="/api/upload/coach/thumbs/exercise/new_thumb"
                      />
                      <Box mb={2} sx={{ fontSize: "12px", fontWeight: "600" }}>
                        Vidéo de l’exercice
                      </Box>
                      <CardImageUploader
                        name="video"
                        label="Ajouter une vidéo"
                        isVideo={true}
                        apiKey="/api/upload/coach/videos/exercises/new_video"
                        imgApiKey="/api/upload/coach/thumbs/exercise/new_thumb"
                      />
                      <video
                        src="C:/Users/dharm/Downloads/burpees-complet.mov"
                        preload="metadata"
                        type="video/mov"
                      />
                      {showRequiredVideoErr && !values.video && (
                        <FormHelperText
                          sx={{ color: "#F05063", fontSize: "12px" }}
                        >
                          Ce champ est obligatoire.
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            );
          }}
        </Formik>

        <ConfirmationModel
          showActions={false}
          maxWidth="sm"
          isOpen={showImageModel}
          title="Modifier la vignette"
          body={
            <Stack
              textAlign="center"
              sx={{
                background: "#F1F1F1",
                padding: { xs: "10px", sm: "50px" },
                borderRadius: "12px",
              }}
              onClick={() => document.querySelector("#file_uploader").click()}
            >
              <input
                type="file"
                accept={
                  selectedFile === "thumbnail"
                    ? "image/png, image/jpeg"
                    : "video/*, video/x-matroska, video/quicktime"
                }
                style={{ visibility: "hidden" }}
                id="file_uploader"
                multiple={false}
                onChange={(e) => {
                  toggleImageModel();
                }}
              />
              <img src="/assets/logo/upload.svg" alt="upload" height="24px" />
              <Typography
                color="#0001FC"
                fontSize="14px"
                fontWeight="600"
                mt={1}
              >
                Ajouter un fichier
              </Typography>
              <Typography
                color="#999999"
                fontSize="12px"
                fontWeight="500"
                mt={1}
              >
                Only JPEG or PNG files up to 5 MB in size.
              </Typography>
            </Stack>
          }
          handleClose={toggleImageModel}
        />
      </Container>
    </>
  );
};

export default EditExercice;
