import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CustomIcon from "components/CustomIcon";
import TitleHeader from "components/TitleHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getClientDetails } from "store/actions/clients";
import { setHeaderDetails } from "store/actions/header";
import ClientDocuments from "../ClientDocuments";
import ClientEvolutions from "../ClientEvolutions";
import ClientFitnessProgram from "../ClientFitnessPrograms";
import ClientNotes from "../ClientNotes";
import ClientNutritionPrograms from "../ClientNutritionPrograms";
import ClientSessions from "../ClientSessions";
import InviteClient from "../ClientsList/InviteClient";

const StyleList = styled(List)(() => ({
  ".MuiListItem-root": {
    padding: "24px 32px 32px 32px",
  },
  ".MuiListItemAvatar-root": {
    minWidth: "120px",
  },
  ".MuiAvatar-root": {
    width: "96px",
    height: "96px",
  },
}));

const ClientDetails = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(1);
  const [showInviteClientModal, setShowInviteClientModal] = useState(false);
  const ClientDetails = useSelector((state) => state.clients.selectedClient);
  const [clientName, setClientName] = useState("");
  const params = useParams();

  const toggleInviteClientModal = () => {
    setShowInviteClientModal(!showInviteClientModal);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (ClientDetails?.first_name || ClientDetails?.last_name) {
      setClientName(
        `${ClientDetails?.first_name || ""} ${ClientDetails?.last_name || ""}`
      );
    } else {
      setClientName("");
    }
  }, [ClientDetails]);
  useEffect(() => {
    const navDetail = {
      isBreadcrumns: true,
      title: "",
      breadcrumsList: [
        {
          title: "Clients",
          link: "/clients",
        },
        {
          title: clientName,
          link: "",
        },
      ],
    };
    dispatch(setHeaderDetails(navDetail));
  }, [clientName]);
  useEffect(() => {
    dispatch(getClientDetails(params.id));
  }, []);
  return (
    <>
      <Container
        maxWidth="xxl"
        sx={{ backgroundColor: "#E4EAFC", padding: "0px !important" }}
      >
        <StyleList>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              {ClientDetails?.thumb ? (
                <Avatar alt="user image" src={ClientDetails?.thumb} />
              ) : (
                <Avatar sx={{ bgcolor: "#0001FC", fontSize: "40px" }}>
                  {ClientDetails?.first_name
                    ? ClientDetails?.first_name[0]
                    : ""}
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <TitleHeader title={clientName} fs={28} fw={700}>
                    <IconButton
                      sx={{ marginLeft: "10px" }}
                      onClick={() => setShowInviteClientModal(true)}
                    >
                      <CustomIcon iconSrc="/assets/logo/pencile.svg" />
                    </IconButton>
                  </TitleHeader>
                </>
              }
              secondary={
                <>
                  <Typography
                    component="div"
                    mb={0.5}
                    color="#111111"
                    variant="body2"
                  >
                    {ClientDetails?.email}
                  </Typography>
                  <Typography component="div" color="#111111" variant="body2">
                    {ClientDetails?.phone}
                  </Typography>
                </>
              }
            />
          </ListItem>
        </StyleList>
      </Container>
      <Container
        maxWidth="xxl"
        sx={{ marginTop: 3, padding: "16px 32px !important" }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
            sx={{
              ".MuiButtonBase-root": {
                color: "#4F5668",
                textTransform: "none",
                "&.Mui-selected": {
                  color: "#111111",
                  fontWeight: 600,
                },
              },
            }}
          >
            <Tab label="Prog d’entrainement" value={1} />
            <Tab label="Séances" value={2} />
            <Tab label="Prog de nutrition" value={3} />
            <Tab label="Évolutions" value={4} />
            <Tab label="Notes" value={5} />
            <Tab label="Documents" value={6} />
          </Tabs>
        </Box>
        {value === 1 && <ClientFitnessProgram />}
        {value === 2 && <ClientSessions />}
        {value === 3 && <ClientNutritionPrograms />}
        {value === 4 && <ClientEvolutions />}
        {value === 5 && <ClientNotes />}
        {value === 6 && <ClientDocuments />}
      </Container>
      <InviteClient
        showModal={showInviteClientModal}
        toggleInviteClientModal={toggleInviteClientModal}
        title="Détails du client"
        cleintDetails={ClientDetails}
      />
    </>
  );
};

export default ClientDetails;
