import _ from "lodash";
import "moment/locale/fr";
import moment from "moment/moment";
import { ROUTES_WITH_PROMPT_WARNING } from "./constants";

export function formatDate(date) {
  moment.locale("fr");
  if (date) {
    date = moment(date).format("DD MMMM YYYY");
  }
  return date;
}

export const fullName = (fName, lName) => {
  let fullName = "-";
  if (fName && lName) {
    fullName = `${fName} ${lName}`;
  } else if (fName && !lName) {
    fullName = fName;
  } else if (!fName && lName) {
    fullName = lName;
  }
  return fullName;
};

export const filterRecords = (list, search, filters, key) => {
  let updatedList = list;
  if (search) {
    updatedList = (updatedList || []).filter((e) =>
      e?.name?.toLowerCase().includes(search.toLowerCase())
    );
  }
  if (filters.length > 0) {
    updatedList = (updatedList || []).filter((e) =>
      e[key]?.split(",").some((value) => filters.includes(value))
    );
  }
  return updatedList;
};

export const isDynamicRouteMatch = (url) => {
  return _.some(ROUTES_WITH_PROMPT_WARNING, (route) => {
    const regex = new RegExp(`^${route.replace(/:\w+/g, "\\w+")}$`);
    return regex.test(url);
  });
};
