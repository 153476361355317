import axios from "axios";
import history from "components/history";
import _ from "lodash";
import { filterRecords } from "utils/common";
import { recipes } from "utils/recipe.utils";

export const SET_LOADING_RECIPES = "set_loading_recipes";
export const setLoadingRecipes = (response = false) => {
  return {
    type: SET_LOADING_RECIPES,
    payload: response,
  };
};

export const GET_RECIPES_LISTS = "get_recipes_lists";
export const getRecipeLists = () => {
  return (dispatch, getState) => {
    dispatch(setLoadingRecipes(true));
    return axios
      .get("/api/coach/recipes")
      .then((res) => {
        dispatch(setLoadingRecipes(false));
        dispatch({
          type: GET_RECIPES_LISTS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(setLoadingRecipes(false));
      });
  };
};

export const SEARCH_RECIPE_DATA = "search_recipe_data";
export const searchRecipes = (search = "", ownerFilters = [], filters = []) => {
  return (dispatch, getState) => {
    let filteredItem = getState().recipes.searchedRecipes || [];
    filteredItem = filterRecords(filteredItem, search, filters, "meal_type");
    if (ownerFilters.length > 0) {
      if (ownerFilters.includes("Moi") && ownerFilters.includes("Evocoach")) {
        filteredItem = (filteredItem || []).filter(
          (e) =>
            e?.created_by === process.env.REACT_APP_EVOCOACH_USER_ID ||
            e?.created_by !== process.env.REACT_APP_EVOCOACH_USER_ID
        );
      } else {
        if (ownerFilters.includes("Moi")) {
          filteredItem = (filteredItem || []).filter(
            (e) => e?.created_by !== process.env.REACT_APP_EVOCOACH_USER_ID
          );
        }
        if (ownerFilters.includes("Evocoach")) {
          filteredItem = (filteredItem || []).filter(
            (e) => e?.created_by === process.env.REACT_APP_EVOCOACH_USER_ID
          );
        }
      }
    }
    dispatch({
      type: SEARCH_RECIPE_DATA,
      payload: filteredItem,
    });
  };
};

export const getCurrentRecipe = (id = "", formikRef) => {
  return (dispatch, getState) => {
    const { setFieldValue } = formikRef;
    dispatch(setLoadingRecipes(true));
    return axios
      .get(`/api/coach/recipes/${id}`)
      .then((res) => {
        dispatch(setLoadingRecipes(false));

        const response = res.data.data[0];
        const recipe_ingredients = _.map(
          response.recipe_ingredients,
          "description"
        );
        const recipe_nutritions = response.recipe_nutritions;
        const explore = _.map(response.explore, (n) => {
          return recipes.categorysList.find((e) => e.name === n);
        });
        setFieldValue("name", response.name);
        setFieldValue("prep_time", response.prep_time);
        setFieldValue("cook_time", response.cook_time);
        setFieldValue("thumbnail", response.thumbnail);
        setFieldValue("video", response.video);
        setFieldValue("kcal", response.kcal);
        setFieldValue("steps", response.steps);
        setFieldValue("recipe_ingredients", recipe_ingredients);
        setFieldValue("protin", recipe_nutritions[0].value);
        setFieldValue("lipides", recipe_nutritions[1].value);
        setFieldValue("glucides", recipe_nutritions[2].value);
        setFieldValue("serving", response.serving);
        setFieldValue("meal_type", response.meal_type);
      })
      .catch((err) => {
        dispatch(setLoadingRecipes(false));
      });
  };
};

export const updateRecipe = (id, values = {}) => {
  return (dispatch, getState) => {
    return axios
      .patch(`/api/coach/recipes/${id}`, values)
      .then((res) => {
        history.push("/nutrition/recipes");
      })
      .catch((err) => {});
  };
};

export const deleteRecipe = (id) => {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/coach/recipes/${id}`)
      .then((res) => {
        dispatch(getRecipeLists());
        history.push("/nutrition/recipes");
      })
      .catch((err) => {});
  };
};

export const createNewRecipe = (values = {}) => {
  return (dispatch, getState) => {
    return axios
      .post("/api/coach/recipes", values)
      .then((res) => {
        history.push("/nutrition/recipes");
      })
      .catch((err) => {});
  };
};

export const PAGINATE_RECIPE_DATA = "paginate_recipe_data";
export const changePagination = (response = 1) => {
  return (dispatch, getState) => {
    dispatch({
      type: PAGINATE_RECIPE_DATA,
      payload: response,
    });
  };
};
