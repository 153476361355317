import {
  GET_COACHES_DATA,
  LOADING_COACHES_DATA,
  PAGINATION_COACHES_DATA,
  SEARCH_COACHES_DATA,
} from "store/actions/coaches";
export default function coaches(state = null, action) {
  if (state == null) {
    return {
      searchedCoaches: [],
      coaches: [],
      currentPage: 1,
      totalItem: 0,
      isLoadingCoaches: false,
    };
  }
  switch (action.type) {
    case GET_COACHES_DATA:
      return {
        ...state,
        coaches: action.payload,
        searchedCoaches: action.payload,
      };
    case PAGINATION_COACHES_DATA:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SEARCH_COACHES_DATA:
      return {
        ...state,
        currentPage: 1,
        coaches: action.payload,
      };
    case LOADING_COACHES_DATA:
      return {
        ...state,
        isLoadingCoaches: action.payload,
      };

    default:
      return state;
  }
}
