import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import WestIcon from "@mui/icons-material/West";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Menu,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import TitleHeader from "components/TitleHeader";
import { connect } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setPrompt } from "store/actions/prompt";
import { isDynamicRouteMatch } from "utils/common";
import { ATTENTION_MESSAGE } from "utils/constants";
import Cookie from "utils/cookie";

const pathName = {
  "/fitness/programs": "Fitness Plans",
  "/fitness/exercices": "Exercise Librrary",
  "/fitness/sessions": "Sessions",
  "/nutrition/recipes": "Mes Recettes",
  "/nutrition/programs": "Nutrition Plans",
  "/clients": "Clients",
  "/": "Bienvenue",
};

const StyledMenu = styled(Menu)((theme) => ({
  ".MuiButton-root": {
    textTransform: "capitalize",
  },
  ".MuiMenu-list": {
    border: "1px solid #0001FC",
    borderRadius: "8px",

    minWidth: "240px",
    overflow: "hidden",
  },
  ".MuiMenuItem-root": {
    "&:hover": {
      background: "#E4EAFC",
      ".MuiTypography-root, .MuiCheckbox-root": {
        color: "#0001FC",
      },
    },
  },
  ".MuiFormControlLabel-root": {
    marginLeft: 0,
    width: "100%",
  },
  ".MuiCheckbox-root": {
    padding: "5px",
  },
  ".MuiTypography-root": {
    flex: 2,
    color: "#111111",
    fontSize: "14px",
  },
}));

const NavHeader = ({ location }) => {
  const dispatch = useDispatch();

  const header = useSelector((state) => state.header);
  const userDetails = useSelector((state) => state.user.userDetails);

  const { title, breadcrumsList, isBreadcrumns } = header;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const signOut = () => {
    Cookie.delete("jwt");
    Cookie.delete("user");
    navigate("/login");
  };
  const breadcrumbs = breadcrumsList?.map((crums, index) => {
    if (index === 0) {
      return (
        <Link
          underline="hover"
          key={index}
          color="#0001FC"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (crums.command) {
              crums.command();
            } else {
              navigate(crums.link);
            }
          }}
        >
          <WestIcon sx={{ mr: 1, fontSize: "16px", verticalAlign: "middle" }} />
          {crums.title}
        </Link>
      );
    } else if (crums.link) {
      return (
        <Link
          underline="hover"
          key={index}
          color="#0001FC"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (crums.command) {
              crums.command();
            } else {
              navigate(crums.link);
            }
          }}
        >
          {crums.title}
        </Link>
      );
    } else {
      return (
        <Typography key={index} fontSize={12} color="#111111">
          {crums.title}
        </Typography>
      );
    }
  });

  const showPromptModal = (nextUrl) => {
    if (isDynamicRouteMatch(pathname)) {
      dispatch(
        setPrompt({
          isOpen: true,
          message: ATTENTION_MESSAGE,
          primaryBtnHandler: () => {
            navigate(nextUrl);
          },
          btnAlign: "right",
          primaryBtnColor: "error",
          secondaryBtnText: "Annuler",
        })
      );
    } else {
      navigate(nextUrl);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        padding: "8px 16px",
        width: "100%",
        borderRadius: "8px",
      }}
    >
      <Grid container sx={{ justifyContent: { xs: "space-between" } }}>
        <Grid item sm={6}>
          {isBreadcrumns ? (
            <Breadcrumbs
              separator={<ArrowRightIcon color="#4F5668" />}
              sx={{ fontSize: "12px", marginTop: "9px" }}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          ) : (
            <TitleHeader
              sx={{ marginTop: "4px" }}
              title={title}
              fs={20}
              fw={600}
            />
          )}
        </Grid>
        <Grid item sm={6} textAlign="right">
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="primary"
            onClick={handleClick}
            startIcon={<AccountCircleIcon />}
            endIcon={<ArrowDropDownIcon />}
          >
            <Box
              sx={{
                whiteSpace: "nowrap",
                "@media (max-width: 600px)": {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "50px",
                },
              }}
            >
              Salut, {userDetails?.first_name} 👋
            </Box>
          </Button>
          <StyledMenu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                showPromptModal("settings");
              }}
            >
              Paramètres du compte
            </MenuItem>
            <MenuItem onClick={() => signOut()}>Déconnexion</MenuItem>
          </StyledMenu>
        </Grid>
      </Grid>
    </Box>
  );
};

export default connect(NavHeader);
