import { initialPromptData, SET_PROMPT } from "store/actions/prompt";

export default function prompt(state = null, action) {
  if (state == null) {
    return {
      ...initialPromptData,
    };
  }
  switch (action.type) {
    case SET_PROMPT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
