import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  ListItemAvatar,
  TextField,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/styles";
import ConfirmationModel from "components/ConfirmationModel";
import { connect } from "formik";
import _ from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNutrition } from "store/actions/nutritions";
import { searchRecipes } from "store/actions/recipes";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";

const Demo = styled("div")(({ theme }) => ({
  overflow: "auto",
  maxHeight: "350px",
  minHeight: "350px",
  border: "1px solid #E0E4EA",
  borderRadius: "12px",
  backgroundColor: theme.palette.background.paper,
  "&::-webkit-scrollbar": {
    width: "8px",
    backgroundColor: "#F5F5F5",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: " inset 0 0 8px rgba(0,0,0,0.3)",
    borderRadius: "12px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#222222",
    borderRadius: "12px",
  },
}));

const AddRecipeModel = (props) => {
  const dispatch = useDispatch();
  const { setFieldValue, values } = props.formik;
  const { current } = props;
  const programData = useSelector((state) => state.recipes.recipesLists);
  const activeWeek = useSelector((state) => state.nutritions.activeWeek);
  const activeDay = useSelector((state) => state.nutritions.activeDay);
  const [selectedRecipe, setSelectedRecipe] = useState([]);

  const activeElement = values["week"]
    ? values["week"][activeWeek]
      ? values["week"][activeWeek][activeDay]
      : {}
    : {};

  let exercisesList = activeElement ? activeElement["meals"] : [];

  const handleSelecteRecipe = (recipe) => {
    setSelectedRecipe([...selectedRecipe, recipe["_id"]]);
  };

  const removeSelectedRecipe = (id) => {
    const recipes = _.remove(selectedRecipe, (n) => n !== id);
    setSelectedRecipe(recipes);
  };

  const addRecipesToProgram = () => {
    dispatch(
      addNutrition(
        selectedRecipe,
        current,
        setFieldValue,
        exercisesList[current].recipes
      )
    );
    props.toggle();
  };

  const isRecipeAdded = (id) => {
    return _.includes(selectedRecipe, id);
  };

  return (
    <ConfirmationModel
      confirmButtonColor="primary"
      confirm="Ajouter une recette"
      back="Close"
      isOpen={props.isOpen || false}
      title={props.title ? props.title : "Add Exercise"}
      maxWidth="md"
      disableConfirmButton={selectedRecipe.length === 0}
      body={
        <>
          <TextField
            placeholder="Search recipes"
            variant="outlined"
            fullWidth={true}
            size="small"
            onChange={(e) => dispatch(searchRecipes(e.target.value))}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />

          {(programData || []).length === 0 ? (
            <span>Not Available</span>
          ) : (
            <Box mt={3}>
              <Demo>
                <List>
                  {(programData || []).map((program, index) => {
                    const element = {
                      week: activeWeek,
                      groupIndex: current,
                      exercisesList: exercisesList[current].recipes || [],
                    };
                    return (
                      <ListItem
                        sx={{
                          paddingRight: "70px",
                          backgroundColor: isRecipeAdded(program._id)
                            ? "#E4F2E5"
                            : "none",
                        }}
                        key={index}
                        secondaryAction={
                          <>
                            {isRecipeAdded(program._id) ? (
                              <Button
                                color="success"
                                startIcon={<CheckCircleIcon />}
                                size="small"
                                variant="outlined"
                                sx={{ backgroundColor: "#ffffff" }}
                                onClick={() => {
                                  removeSelectedRecipe(program._id);
                                }}
                              >
                                Select
                              </Button>
                            ) : (
                              <Button
                                color="success"
                                startIcon={<AddIcon />}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  handleSelecteRecipe(program);
                                }}
                              >
                                Select
                              </Button>
                            )}
                          </>
                        }
                      >
                        <ListItemAvatar sx={{ display: "flex" }}>
                          <img
                            src={program.thumbnail}
                            height="40px"
                            width="40px"
                            alt="gym.jpg"
                            style={{ objectFit: "contain" }}
                            onError={(event) => {
                              event.target.src = THUMBNAIL_PLACEHOLDER_GRAY;
                              event.onerror = null;
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={program.name} />
                      </ListItem>
                    );
                  })}
                </List>
              </Demo>
            </Box>
          )}
        </>
      }
      handleClose={props.toggle}
      onConfirm={() => {
        addRecipesToProgram();
      }}
    />
  );
};

export default connect(AddRecipeModel);
