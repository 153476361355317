import axios from "axios";
import _ from "lodash";
import { filterRecords } from "utils/common";
import { setPrompt } from "./prompt";
export const GET_CLIENTS_DATA = "get_clients_data";
export const getClients = () => {
  return (dispatch) => {
    dispatch(loadingClients(true));
    axios
      .get("api/coach/users")
      .then((res) => {
        dispatch({
          type: GET_CLIENTS_DATA,
          payload: res.data.data,
        });
        dispatch(loadingClients(false));
      })
      .catch(() => {
        dispatch({
          type: GET_CLIENTS_DATA,
          payload: [],
        });
        dispatch(loadingClients(false));
      });
  };
};

export const PAGINATION_CLIENTS_DATA = "pagination_clients_data";
export const changePagination = (response = 1) => {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_CLIENTS_DATA,
      payload: response,
    });
  };
};

export const SEARCH_CLIENTS_DATA = "search_clients_data";
export const searchClients = (search = "", filters = []) => {
  return (dispatch, getState) => {
    let filteredItem = (getState().clients.searchedClients || []).filter(
      (e) => {
        const firstName = e.first_name?.toLowerCase() || "";
        const lastName = e.last_name?.toLowerCase() || "";
        const email = e.email?.toLowerCase() || "";
        return (
          firstName.includes(search.toLowerCase()) ||
          lastName.includes(search.toLowerCase()) ||
          email.includes(search.toLowerCase())
        );
      }
    );
    filteredItem = filterRecords(filteredItem, "", filters, "status");
    dispatch({
      type: SEARCH_CLIENTS_DATA,
      payload: filteredItem,
    });
  };
};

export const LOADING_CLIENTS_DATA = "loading_clients_data";
export const loadingClients = (response = false) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_CLIENTS_DATA,
      payload: response,
    });
  };
};

export const inviteClientHandler = (data, toggleInviteClientModal) => {
  return (dispatch) => {
    return axios
      .post(`/api/coach/invite/client`, data)
      .then(() => {
        dispatch(getClients());
        toggleInviteClientModal();
      })
      .catch((err) => {
        toggleInviteClientModal();
        dispatch(
          setPrompt({
            isOpen: true,
            message: err?.response?.data?.message,
            hidePrimaryBtn: true,
          })
        );
      });
  };
};

export const deactivateClientById = (id = "", toggleDeactivateModal) => {
  return (dispatch) => {
    return axios
      .delete(`/api/coach/users/${id}`)
      .then(() => {
        toggleDeactivateModal();
      })
      .catch((err) => {
        toggleDeactivateModal();
      });
  };
};

export const deleteClientById = (id = "", toggleDeleteModal) => {
  return (dispatch) => {
    return axios
      .delete(`/api/coach/users/${id}`)
      .then(() => {
        dispatch(getClients());
      })
      .catch((err) => {
        toggleDeleteModal();
      });
  };
};

export const SET_SELECTED_CLIENT_DETAILS = "set_selected_client_details";
export const getClientDetails = (id = "") => {
  return (dispatch) => {
    return axios
      .get(`/api/coach/users/${id}`)
      .then((res) => {
        dispatch({
          type: SET_SELECTED_CLIENT_DETAILS,
          payload: res.data.data,
        });
      })
      .catch((err) => {});
  };
};

export const updateClientDetails = (data, id, toggleInviteClientModal) => {
  return (dispatch, getState) => {
    return axios
      .patch(`/api/coach/users/${id}`, data)
      .then((res) => {
        const selectedClient = getState().clients.selectedClient;
        dispatch({
          type: SET_SELECTED_CLIENT_DETAILS,
          payload: { ...selectedClient, ...data },
        });
        toggleInviteClientModal();
      })
      .catch((err) => {
        toggleInviteClientModal();
      });
  };
};

export const SET_CLIENT_FITNESS_PROGRAMS = "set_client_fitness_program";
export const getClientFitnessPrograms = (id) => {
  return (dispatch) => {
    return axios
      .get(`/api/coach/programs/user/${id}`)
      .then((res) => {
        dispatch({
          type: SET_CLIENT_FITNESS_PROGRAMS,
          payload: res.data.data,
        });
      })
      .catch((err) => {});
  };
};

export const getSessionByProgramId = (program_id, user_id) => {
  return (dispatch, getState) => {
    return axios
      .get(`/api/coach/sessions/user/${user_id}/${program_id}`)
      .then((res) => {
        let clientFitnessPrograms = getState().clients.clientFitnessPrograms;
        clientFitnessPrograms = clientFitnessPrograms.map((program) => {
          if (program._id === program_id) {
            program = {
              ...program,
              sessions: _.sortBy(res.data.data, "created_on"),
            };
          }
          return program;
        });

        dispatch({
          type: SET_CLIENT_FITNESS_PROGRAMS,
          payload: clientFitnessPrograms,
        });
      })
      .catch((err) => {});
  };
};

export const deleteClientFitnessProgram = (id, toggleDeleteModel) => {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/programs/${id}`)
      .then((res) => {
        const clientFitnessPrograms = getState().clients.clientFitnessPrograms;
        let updatedClientFitnessPrograms = clientFitnessPrograms.filter(
          (program) => program._id !== id
        );
        dispatch({
          type: SET_CLIENT_FITNESS_PROGRAMS,
          payload: updatedClientFitnessPrograms,
        });
        toggleDeleteModel();
      })
      .catch((err) => {
        toggleDeleteModel();
      });
  };
};

export const SET_CLIENT_SESSIONS = "set_client_sessions";
export const getClientSessions = (id) => {
  return (dispatch) => {
    return axios
      .get(`/api/coach/sessions/user/${id}?independent_session=true`)
      .then((res) => {
        dispatch({
          type: SET_CLIENT_SESSIONS,
          payload: res.data.data,
        });
      })
      .catch((err) => {});
  };
};

export const deleteClientSession = (id, toggleDeleteModel) => {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/sessions/${id}`)
      .then((res) => {
        const clientSessions = getState().clients.clientSessions;
        let updatedClientSessions = clientSessions.filter(
          (session) => session._id !== id
        );
        dispatch({
          type: SET_CLIENT_SESSIONS,
          payload: updatedClientSessions,
        });
        toggleDeleteModel();
      })
      .catch((err) => {
        toggleDeleteModel();
      });
  };
};

export const SET_CLIENT_NUTRITION_PROGRAMS = "set_client_nutrition_programs";
export const getClientNutritionPrograms = (id) => {
  return (dispatch) => {
    return axios
      .get(`/api/coach/nutrition_programs/user/${id}`)
      .then((res) => {
        dispatch({
          type: SET_CLIENT_NUTRITION_PROGRAMS,
          payload: res.data.data,
        });
      })
      .catch((err) => {});
  };
};

export const deleteClientNutritionProgram = (id, toggleDeleteModel) => {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/nutrition_programs/${id}`)
      .then((res) => {
        const clientNutritionPrograms =
          getState().clients.clientNutritionPrograms;
        let updatedClientNutritionPrograms = clientNutritionPrograms.filter(
          (program) => program._id !== id
        );
        dispatch({
          type: SET_CLIENT_NUTRITION_PROGRAMS,
          payload: updatedClientNutritionPrograms,
        });
        toggleDeleteModel();
      })
      .catch((err) => {
        toggleDeleteModel();
      });
  };
};

export const SET_CLIENT_EVOLUTIONS = "set_client_evolutions";
export const getClientEvolutions = (id) => {
  return (dispatch) => {
    return axios
      .get(`/api/coach/evolutions/user/${id}`)
      .then((res) => {
        dispatch({
          type: SET_CLIENT_EVOLUTIONS,
          payload: res.data.data,
        });
      })
      .catch((err) => {});
  };
};
export const createClientEvolution = (data) => {
  return (dispatch) => {
    return axios
      .post(`/api/coach/evolutions`, data)
      .then((res) => {
        dispatch(getClientEvolutions(data.user_id));
      })
      .catch((err) => {});
  };
};

export const editClientEvolution = (data) => {
  return (dispatch, getState) => {
    return axios
      .patch(`/api/coach/evolutions/${data._id}`, data)
      .then((res) => {
        const clientEvolutions = getState().clients.clientEvolutions;
        let updatedClientEvolutions = clientEvolutions.map((evolution) => {
          if (evolution._id === data._id) {
            evolution = { ...evolution, ...data };
          }
          return evolution;
        });
        dispatch({
          type: SET_CLIENT_EVOLUTIONS,
          payload: updatedClientEvolutions,
        });
      })
      .catch((err) => {});
  };
};

export const deleteClientEvolution = (id, toggleDeleteModel) => {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/coach/evolutions/${id}`)
      .then((res) => {
        const clientEvolutions = getState().clients.clientEvolutions;
        let updatedClientEvolutions = clientEvolutions.filter(
          (evolution) => evolution._id !== id
        );
        dispatch({
          type: SET_CLIENT_EVOLUTIONS,
          payload: updatedClientEvolutions,
        });
        toggleDeleteModel();
      })
      .catch((err) => {
        toggleDeleteModel();
      });
  };
};

export const SET_CLIENT_NOTES = "set_client_notes";
export const getClientNotes = (id) => {
  return (dispatch) => {
    return axios
      .get(`/api/coach/notes/user/${id}`)
      .then((res) => {
        dispatch({
          type: SET_CLIENT_NOTES,
          payload: res.data.data,
        });
      })
      .catch((err) => {});
  };
};

export const createClientNote = (data) => {
  return (dispatch) => {
    return axios
      .post(`/api/coach/notes`, data)
      .then((res) => {
        dispatch(getClientNotes(data.user_id));
      })
      .catch((err) => {});
  };
};

export const editClientNote = (data) => {
  return (dispatch, getState) => {
    return axios
      .patch(`/api/coach/notes/${data._id}`, data)
      .then((res) => {
        const clientNotes = getState().clients.clientNotes;
        let updatedClientNotes = clientNotes.map((note) => {
          if (note._id === data._id) {
            note = { ...note, ...data };
          }
          return note;
        });
        dispatch({
          type: SET_CLIENT_NOTES,
          payload: updatedClientNotes,
        });
      })
      .catch((err) => {});
  };
};

export const deleteClientNote = (id, toggleDeleteModel) => {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/coach/notes/${id}`)
      .then((res) => {
        const clientNotes = getState().clients.clientNotes;
        let updatedClientNotes = clientNotes.filter((note) => note._id !== id);
        dispatch({
          type: SET_CLIENT_NOTES,
          payload: updatedClientNotes,
        });
        toggleDeleteModel();
      })
      .catch((err) => {
        toggleDeleteModel();
      });
  };
};

export const SET_CLIENT_DOCUMENTS = "set_client_documents";
export const getClientDocuments = (id) => {
  return (dispatch) => {
    return axios
      .get(`/api/coach/documents/user/${id}`)
      .then((res) => {
        dispatch({
          type: SET_CLIENT_DOCUMENTS,
          payload: res.data.data,
        });
      })
      .catch((err) => {});
  };
};

export const createClientDocument = (data) => {
  return (dispatch) => {
    return axios
      .post(`/api/coach/documents`, data)
      .then((res) => {
        dispatch(getClientDocuments(data.user_id));
      })
      .catch((err) => {});
  };
};

export const deleteClientDocument = (id, toggleDeleteModel) => {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/coach/documents/${id}`)
      .then((res) => {
        const clientDocuments = getState().clients.clientDocuments;
        let updatedClientDocuments = clientDocuments.filter(
          (doc) => doc._id !== id
        );
        dispatch({
          type: SET_CLIENT_DOCUMENTS,
          payload: updatedClientDocuments,
        });
        toggleDeleteModel();
      })
      .catch((err) => {
        toggleDeleteModel();
      });
  };
};
