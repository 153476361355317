export const THUMBNAIL_PLACEHOLDER =
  "https://evodream.nyc3.cdn.digitaloceanspaces.com/thumbs/exercice-placeholder-black.png";

export const THUMBNAIL_PLACEHOLDER_GRAY =
  "https://evodream.nyc3.cdn.digitaloceanspaces.com/thumbs/program-placeholder-gray.png";

export const ROUTES_WITH_PROMPT_WARNING = [
  "/fitness/exercices/new",
  "/fitness/exercices/:id",
  "/fitness/sessions/create",
  "/fitness/sessions/:id",
  "/fitness/sessions/final",
  "/fitness/sessions/final/:id",
  "/fitness/programs/create",
  "/fitness/programs/final",
  "/fitness/programs/final/:id",
  "/fitness/programs/:id",
  "/nutrition/recipes/create",
  "/nutrition/recipes/:id",
  "/nutrition/programs/create",
  "/nutrition/programs/:id",
  "/nutrition/programs/final",
  "/nutrition/programs/final/:id",
];

export const ATTENTION_MESSAGE =
  "Vous êtes sur le point de quitter sans enregistrer, ce qui entraînera la perte de vos modifications. Êtes-vous sûr de vouloir continuer cette action ?";
