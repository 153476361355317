import axios from "axios";
import toast from "react-hot-toast";
import Cookie from "utils/cookie";
export const SET_USER_DETAILS = "set_user_details";
export const setUserDetails = (response = {}) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DETAILS,
      payload: response,
    });
  };
};

export const updateNotificationPreference = (data) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails || {};
    const { type } = userDetails;
    const url =
      type === "OWNER"
        ? "/api/owner/notification_preferences"
        : "/api/coach/notification_preferences";
    return axios
      .patch(url, data)
      .then((res) => {
        toast.success("Vos préférences de notification ont été enregistrées.");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const changePassword = (data) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails || {};
    const { type } = userDetails;
    const url =
      type === "OWNER"
        ? "/api/owner/me/changepassword"
        : "/api/coach/me/changepassword";
    return axios
      .post(url, data)
      .then((res) => {
        toast.success("Votre mot de passe a été changé avec succès.");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const SET_USER_PROFILE = "set_user_profile";
export const getProfile = () => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails || {};
    const { type } = userDetails;
    const url = type === "OWNER" ? "/api/owner/me" : "/api/coach/me";
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: SET_USER_PROFILE,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const updateProfile = (data, flag = "") => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails || {};
    const userProfile = getState().user.userProfile || {};
    const { type } = userDetails;
    const url =
      type === "OWNER" ? "/api/owner/updateMe" : "/api/coach/updateMe";
    return axios
      .patch(url, data)
      .then((res) => {
        const updateProfile = {
          ...userProfile,
          ...data,
        };

        dispatch({
          type: SET_USER_PROFILE,
          payload: updateProfile,
        });
        if (flag !== "photo_upload") {
          toast.success("Détails du profil sauvegardés.");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const deleteMyAccount = (toggleDeleteModal) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails || {};
    const { type } = userDetails;
    const url = type === "OWNER" ? "/api/owner/me" : "/api/coach/me";

    return axios
      .delete(url)
      .then((res) => {
        Cookie.delete("jwt");
        Cookie.delete("user");
        window.location.href = "/login";
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};
