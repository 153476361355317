import { Box, Button, Grid, IconButton, Stack, styled } from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import TitleHeader from "components/TitleHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteClientSession, getClientSessions } from "store/actions/clients";

const StyledBox = styled(Box)(() => ({
  border: "1px solid #E0E4EA",
  width: "100%",
  padding: "12px 16px",
  alignItems: "center",
  color: "#4F5668",
  fontSize: "14px",
  borderRadius: "8px",
  marginBottom: "8px",
  backgroundColor: "#ffffff",
  "&:last-child": {
    marginBottom: "0px",
  },
}));
const NoSessionBox = styled(Box)(({ theme }) => ({
  border: "1px solid #E0E4EA",
  borderRadius: "8px",
  padding: "10px",
  textAlign: "center",
  fontSize: "14px",
  color: "#4F5668",
  backgroundColor: "#ffffff",
}));
const ClientSessions = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const clientSessions = useSelector((state) => state.clients.clientSessions);
  const [showDeleteModel, setShowDeleteModel] = useState();
  const [selectedSession, setSelectedSession] = useState();

  const toggleDeleteModel = () => {
    setShowDeleteModel(!showDeleteModel);
  };
  useEffect(() => {
    if (clientSessions.length === 0) {
      dispatch(getClientSessions(params?.id));
    }
  }, []);
  return (
    <>
      <Box py={3}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          mb={4}
        >
          <TitleHeader title="Séances d’entraînement" fs={20} fw={600} />
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate("/fitness/sessions/create");
            }}
          >
            Créer une séance
          </Button>
        </Stack>
        {clientSessions.length === 0 && (
          <NoSessionBox>Aucune séance ajoutée.</NoSessionBox>
        )}
        {clientSessions?.map((item) => (
          <StyledBox key={item._id} variant="outlined" severity="info">
            <Grid container>
              <Grid
                item
                sm={11}
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                {item.name}
              </Grid>
              <Grid
                item
                sm={1}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => navigate(`/fitness/sessions/${item._id}`)}
                >
                  <Box
                    component="img"
                    src="/assets/logo/detail_icon.svg"
                    alt="session details"
                  ></Box>
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => {
                    setSelectedSession(item);
                    toggleDeleteModel();
                  }}
                >
                  <Box
                    component="img"
                    src="/assets/logo/trash_icon.svg"
                    alt="Session delete"
                  ></Box>
                </IconButton>
              </Grid>
            </Grid>
          </StyledBox>
        ))}
      </Box>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer la session"
        body={
          <Box>
            Vous êtes sur le point de supprimer{" "}
            <strong>{selectedSession?.name}</strong>. Cela le supprimera
            définitivement de votre bibliothèque.
          </Box>
        }
        maxWidth="sm"
        handleClose={toggleDeleteModel}
        onConfirm={() => {
          dispatch(
            deleteClientSession(selectedSession?._id, toggleDeleteModel)
          );
          setSelectedSession(null);
        }}
      />
    </>
  );
};
export default ClientSessions;
