export const adminConfig = [
  {
    title: "Dashboard",
    path: "/",
    icon: <img src="/assets/logo/home.svg" alt="home" />,
    iconHover: <img src="/assets/logo/home_white.svg" alt="home" />,
    references: ["/"],
    featureName: "dashboard",
    permissions: ["COACH", "OWNER"],
  },
  {
    title: "Membres",
    permissions: ["COACH", "OWNER"],
    featureName: "membres",
    children: [
      {
        title: "Clients",
        path: "/clients",
        icon: <img src="/assets/logo/users.svg" alt="My Client" />,
        iconHover: <img src="/assets/logo/users_white.svg" alt="My Client" />,
        references: ["/clients"],
        permissions: ["COACH", "OWNER"],
      },
      {
        title: "Entraîneurs",
        path: "/coaches",
        icon: <img src="/assets/logo/coaches.svg" alt="Entraîneurs" />,
        iconHover: (
          <img src="/assets/logo/coaches_white.svg" alt="Entraîneurs" />
        ),
        references: ["/coaches"],
        permissions: ["OWNER"],
      },
    ],
  },
  {
    title: "Fitness",
    references: [
      "/fitness/exercices",
      "/fitness/sessions",
      "/fitness/programs",
    ],
    featureName: "fitness",
    permissions: ["COACH", "OWNER"],
    children: [
      {
        title: "Exercices",
        path: "/fitness/exercices",
        icon: <img src="/assets/logo/exercices.svg" alt="Exercices" />,
        iconHover: (
          <img src="/assets/logo/exercices_white.svg" alt="Exercices" />
        ),
        references: [
          "/fitness/exercices/1",
          "/fitness/exercices",
          "/fitness/exercices/new",
        ],
        permissions: ["COACH", "OWNER"],
      },
      {
        title: "Séances",
        path: "/fitness/sessions",
        icon: <img src="/assets/logo/sessions.svg" alt="Sessions" />,
        iconHover: <img src="/assets/logo/sessions_white.svg" alt="Sessions" />,
        references: [
          "/fitness/sessions/1",
          "/fitness/sessions",
          "/fitness/sessions/create",
        ],
        permissions: ["COACH", "OWNER"],
      },
      {
        title: "Programmes",
        path: "/fitness/programs",
        icon: <img src="/assets/logo/programmes.svg" alt="Programmes" />,
        iconHover: (
          <img src="/assets/logo/programmes_white.svg" alt="Programmes" />
        ),
        references: [
          `/fitness/programs/1`,
          `/fitness/programs`,
          `/fitness/programs/final`,
          `/fitness/programs/create`,
        ],
        permissions: ["COACH", "OWNER"],
      },
    ],
  },
  {
    title: "Nutrition",
    references: ["/nutrition/recipes", "/nutrition/programs"],
    permissions: ["COACH", "OWNER"],
    featureName: "nutrition",
    children: [
      {
        title: "Recettes",
        path: "/nutrition/recipes",
        icon: <img src="/assets/logo/recipes.svg" alt="Recipes" />,
        iconHover: <img src="/assets/logo/recipes_white.svg" alt="Recipes" />,
        references: [
          "/nutrition/recipes/1",
          "/nutrition/recipes",
          "/nutrition/recipes/create",
        ],
        permissions: ["COACH", "OWNER"],
      },
      {
        title: "Programmes",
        path: "/nutrition/programs",
        icon: (
          <img src="/assets/logo/recipe_programs.svg" alt="Recipes programs" />
        ),
        iconHover: (
          <img
            src="/assets/logo/recipe_programs_white.svg"
            alt="Recipes programs"
          />
        ),
        references: [
          "/nutrition/programs/create",
          "/nutrition/programs",
          "/nutrition/programs/final",
        ],
        permissions: ["COACH", "OWNER"],
      },
    ],
  },
  {
    title: "Marketing",
    references: [],
    permissions: ["COACH", "OWNER"],
    featureName: "marketing",
    children: [
      {
        title: "Site web",
        path: "#",
        icon: <img src="/assets/logo/web.svg" alt="Web" />,
        iconHover: <img src="/assets/logo/web_white.svg" alt="Web" />,
        references: [],
        permissions: ["COACH", "OWNER"],
      },
      // {
      //   title: "Formations",
      //   path: "#",
      //   icon: <img src="/assets/logo/formations.svg" alt="Formations" />,
      //   references: [],
      //   permissions: ["COACH", "OWNER"],
      // },
    ],
  },
  {
    title: "Support",
    references: [],
    permissions: ["COACH", "OWNER"],
    featureName: "support",
    children: [
      // {
      //   title: "FAQ",
      //   path: "#",
      //   icon: <img src="/assets/logo/faq.svg" alt="FAQ" />,
      //   references: [],
      //   permissions: ["COACH", "OWNER"],
      // },
      {
        title: "Paramètres",
        path: "/settings",
        icon: <img src="/assets/logo/settings.svg" alt="Settings" />,
        iconHover: <img src="/assets/logo/settings_white.svg" alt="Settings" />,
        references: [],
        permissions: ["COACH", "OWNER"],
      },
    ],
  },
];
