import {
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import Field from "components/Field";
import ImageLibrary from "components/Library";
import { useState } from "react";
import { useSelector } from "react-redux";
import { required } from "../../Field/validate";

const FileUploaderModel = ({
  isOpen,
  toggle,
  callback,
  libraryUploadcallback,
  name,
  isVideo,
  title,
  view,
  libraryApi,
  isLibrary,
  validationMsg = "Uniquement les fichiers JPEG ou PNG jusqu'à 100MB.",
  allowFileTypes = ["image/jpeg", "image/png"],
}) => {
  const [isValidFile, setIsValidFile] = useState(true);
  const isLoadingFile = useSelector((state) => state.programs.isLoadingFile);
  const [value, setValue] = useState(1);
  const allowVideoFormats = [
    "video/*",
    "video/mp4",
    "video/x-matroska",
    "video/quicktime",
  ];
  const validateUploadFile = (file) => {
    const fileSize = file?.size / (1024 * 1024);
    let validFile = true;
    if (isVideo) {
      if (!allowVideoFormats.includes(file.type) || fileSize > 100) {
        validFile = false;
      }
    } else {
      if (!allowFileTypes.includes(file.type) || fileSize > 100) {
        validFile = false;
      }
    }
    setIsValidFile(validFile);
    return validFile;
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const triggerCallBack = (url) => {
    libraryUploadcallback(url);
  };
  return (
    <ConfirmationModel
      showActions={false}
      maxWidth="sm"
      isOpen={isOpen}
      title={
        title ? title : isVideo ? "Modifier la video" : "Modifier la vignette"
      }
      body={
        <>
          {!isVideo && isLibrary && (
            <Box mb={2} sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="fullWidth"
                sx={{
                  ".MuiButtonBase-root": {
                    color: "#4F5668",
                    textTransform: "none",
                    "&.Mui-selected": {
                      color: "#111111",
                      fontWeight: 600,
                    },
                  },
                }}
              >
                <Tab label="Ajouter un fichier" value={1} />
                <Tab label="Libraries d’images" value={2} />
              </Tabs>
            </Box>
          )}
          {value === 1 && (
            <Stack
              textAlign="center"
              sx={{
                background: isLoadingFile ? "rgba(0, 0, 0, 0.5)" : "#F1F1F1",
                padding: { xs: "10px", sm: "50px" },
                cursor: "pointer",
                borderRadius: "12px",
              }}
              onClick={() => {
                if (!isLoadingFile) {
                  document.querySelector("#file_uploader").click();
                }
              }}
            >
              {isLoadingFile && (
                <CircularProgress
                  color="primary"
                  sx={{ color: "white", margin: "auto" }}
                />
              )}
              <Box sx={{ display: isLoadingFile ? "none" : "" }}>
                <Field
                  type="file"
                  name={name ? name + "1" : "file1"}
                  accept={
                    isVideo
                      ? allowVideoFormats.toString()
                      : allowFileTypes.toString()
                  }
                  style={{ visibility: "hidden" }}
                  id="file_uploader"
                  multiple={false}
                  onChange={(e) => {
                    if (validateUploadFile(e.target.files[0])) {
                      callback(e.target.files[0]);
                    }
                  }}
                  validate={required}
                />
                <img src="/assets/logo/upload.svg" alt="upload" height="24px" />
                <Typography
                  color="#0001FC"
                  fontSize="14px"
                  fontWeight="600"
                  mt={1}
                >
                  Ajouter un fichier
                </Typography>
                <Typography
                  color={isValidFile ? "#999999" : "#B72136"}
                  fontSize="12px"
                  fontWeight="500"
                  mt={1}
                >
                  {isVideo
                    ? "Uniquement les fichiers MP4, OGG, WEBM, AVI, MOV et X-MATROSKA jusqu'à 100Mo."
                    : validationMsg}
                </Typography>
              </Box>
            </Stack>
          )}
          {value === 2 && (
            <ImageLibrary
              libraryApi={libraryApi}
              triggerCallBack={triggerCallBack}
              view={view}
            />
          )}
        </>
      }
      handleClose={toggle}
    />
  );
};

export default FileUploaderModel;
