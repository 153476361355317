import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  Pagination,
  PaginationItem,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import ConfirmationModel from "components/ConfirmationModel";
import FilterMenu from "components/FilterMenu";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changePagination,
  cleanupExercise,
  GET_EXERCISE_CURRENT,
  getExercises,
  searchExercise,
} from "store/actions/exercise";
import { setHeaderDetails } from "store/actions/header";
import { muscle } from "utils/gym.utils";
import useDocumentTitle from "utils/hooks/useDocumentTitle";
import Loader from "../../components/Loader";
import ExcerciceItem from "./ExecrciceItem";

const Header = styled(Grid)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  color: "#757E97",
}));

const HomePage = () => {
  useDocumentTitle("Mes Exercices", true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const exercises = useSelector((state) => state.exercise.exercises);
  const currentPage = useSelector((state) => state.exercise.currentPage);
  const isLoadingExercise = useSelector(
    (state) => state.exercise.isLoadingExercise
  );

  useEffect(() => {
    dispatch(getExercises());
    const navDetail = {
      isBreadcrumns: false,
      title: "Bibliothèque d’exercices",
    };

    dispatch(setHeaderDetails(navDetail));
  }, []);

  const [showDeleteModel, setDeleteModel] = useState(false);
  const [current, setCurrent] = useState({});
  const [showInfoModel, setInfoModel] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [ownerFilters, setOwnerFilters] = useState([]);
  const [muscleFilters, setMuscleFilters] = useState([]);

  useEffect(() => {
    dispatch({
      type: GET_EXERCISE_CURRENT,
      payload: [],
    });
  }, []);

  const toggleDeleteModel = (item = {}) => {
    setDeleteModel(!showDeleteModel);
    setCurrent(item);
  };

  const toggleInfoModel = () => {
    setInfoModel(!showInfoModel);
  };

  const deleteItem = () => {
    axios
      .delete(`/api/coach/exercises/${current._id}`)
      .then((res) => {
        dispatch(getExercises());
        toggleDeleteModel();
      })
      .catch((err) => {
        toggleInfoModel();
      });
  };

  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentPageItems = (exercises || []).slice(start, end).length;

  const filerMenuList = [
    {
      id: 1,
      name: "Moi",
    },
    {
      id: 2,
      name: "Evocoach",
    },
  ];

  const getOwnerFilterValues = (values) => {
    setOwnerFilters(values);
    dispatch(searchExercise(searchQuery, values, muscleFilters));
  };
  const getMuscleFilterValues = (values) => {
    setMuscleFilters(values);
    dispatch(searchExercise(searchQuery, ownerFilters, values));
  };

  return (
    <>
      <Container
        maxWidth="xxl"
        className="header-box"
        sx={{ padding: "0 !important", marginBottom: "20px" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <TextField
              placeholder="Rechercher un exercice"
              name="searchExercice"
              sx={{
                width: { sm: "100%", md: "414px" },
                ".MuiInputBase-root": {
                  background: "#ffffff",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                dispatch(
                  searchExercise(e.target.value, ownerFilters, muscleFilters)
                );
              }}
            />
            <FilterMenu
              menuTitle="Créer par"
              getSelectedValues={getOwnerFilterValues}
              menuList={filerMenuList}
            />
            <FilterMenu
              menuTitle="Zones musculaires"
              getSelectedValues={getMuscleFilterValues}
              menuList={muscle}
            />
          </Stack>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(cleanupExercise());
              navigate("/fitness/exercices/new");
            }}
          >
            {" "}
            Créer un exercice
          </Button>
        </Stack>
      </Container>
      <Container maxWidth="xxl">
        <Stack gap={3}>
          <Stack gap={1}>
            <Loader isLoading={isLoadingExercise} />
            {!isLoadingExercise && (
              <Box sx={{ minHeight: "686px" }}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "6px 16px 14px",
                  }}
                >
                  <Header
                    item
                    xs={4}
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    Titre de l’exercice
                  </Header>
                  <Header item md={1} sx={{ textAlign: "right" }}>
                    Actions
                  </Header>
                </Grid>
                {(exercises || []).length === 0 && !isLoadingExercise && (
                  <Box component="h5" textAlign="center">
                    Vous n'avez pas encore d’exercices.
                  </Box>
                )}
                {(exercises || []).slice(start, end).map((item, index) => (
                  <ExcerciceItem
                    key={index}
                    item={item}
                    toggleDeleteModel={toggleDeleteModel}
                    toggleInfoModel={toggleInfoModel}
                  />
                ))}
              </Box>
            )}
          </Stack>

          {!isLoadingExercise && (exercises || []).length > 10 && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color="#000000" fontSize="12px" fontWeight="500">
                Affichage de {start + 1} à {start + currentPageItems} sur{" "}
                {exercises.length} résultats
              </Typography>
              <Pagination
                page={currentPage}
                onChange={(page, value) => dispatch(changePagination(value))}
                count={Math.ceil(exercises.length / 10)}
                variant="outlined"
                shape="rounded"
                color="secondary"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
        <ConfirmationModel
          isOpen={showDeleteModel}
          title="Supprimer l’exercice"
          body={[
            "Vous êtes sur le point de supprimer",
            <strong> {current?.name}</strong>,
            ". Cela le supprimera définitivement de votre bibliothèque.",
          ]}
          maxWidth="sm"
          handleClose={toggleDeleteModel}
          onConfirm={() => {
            deleteItem();
          }}
        />
        <ConfirmationModel
          showActions={false}
          isOpen={showInfoModel}
          title="Supprimer l’exercice"
          body="
                    Impossible de supprimer cet exercice car il fait
                    partie d’un programme en cours.
                "
          handleClose={toggleInfoModel}
        />
      </Container>
    </>
  );
};

export default HomePage;
