import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import CustomIcon from "components/CustomIcon";
import CustomModal from "components/CustomModal";
import Field from "components/Field";
import { required } from "components/Field/validate";
import { Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteClientById,
  inviteClientHandler,
  updateClientDetails,
} from "store/actions/clients";
const InviteClient = ({
  title = "Ajouter un client",
  showModal = false,
  toggleInviteClientModal,
  cleintDetails = null,
}) => {
  const dispatch = useDispatch();
  const closeModalHandler = () => {
    toggleInviteClientModal();
  };
  const [showDeleteModel, setDeleteModel] = useState(false);
  const toggleDeleteModel = () => {
    setDeleteModel(!showDeleteModel);
  };
  return (
    <>
      <CustomModal
        open={showModal}
        onCloseHandler={closeModalHandler}
        modalWidth="636"
        title={title}
      >
        <Formik
          initialValues={{
            first_name: cleintDetails?.first_name || "",
            last_name: cleintDetails?.last_name || "",
            email: cleintDetails?.email || "",
            phone: cleintDetails?.phone || "",
            role: cleintDetails?.role || "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (cleintDetails?._id) {
              dispatch(
                updateClientDetails(
                  values,
                  cleintDetails?._id,
                  toggleInviteClientModal
                )
              );
            } else {
              dispatch(inviteClientHandler(values, toggleInviteClientModal));
            }
          }}
        >
          {(props) => {
            const { handleSubmit, isSubmitting } = props;
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Field
                      type="text"
                      label="Prénom"
                      placeholder="Prénom *"
                      name="first_name"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Field
                      type="text"
                      label="Nom"
                      placeholder="Nom *"
                      name="last_name"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Field
                      type="text"
                      label="Adresse e-mail *"
                      placeholder="Adresse e-mail *"
                      name="email"
                      validate={required}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Field
                      type="text"
                      label="Numéro de téléphone"
                      placeholder="Numéro de téléphone"
                      name="phone"
                    />
                  </Grid>
                </Grid>
                {cleintDetails && (
                  <>
                    <Divider sx={{ margin: "10px 0 20px 0" }} />
                    <Box mb={3} sx={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          closeModalHandler();
                          toggleDeleteModel();
                        }}
                        endIcon={
                          <CustomIcon iconSrc="/assets/logo/trash_white.svg" />
                        }
                      >
                        Supprimer le client
                      </Button>
                    </Box>
                  </>
                )}

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                  mt={2}
                >
                  <Button
                    sx={{ width: "100%" }}
                    color="secondary"
                    variant="outlined"
                    onClick={() => closeModalHandler()}
                  >
                    Fermer
                  </Button>
                  <Button
                    sx={{ width: "100%" }}
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {cleintDetails?._id ? "Sauvegarder" : "Ajouter"}
                  </Button>
                </Stack>
              </form>
            );
          }}
        </Formik>
      </CustomModal>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer Client"
        body={[
          "Vous allez supprimer ce client. Cette action est irréversible. Pour continuer, cliquez sur Supprimer.",
        ]}
        handleClose={toggleDeleteModel}
        maxWidth="sm"
        onConfirm={() => {
          dispatch(
            deleteClientById(cleintDetails?._id, toggleDeleteModel)
          ).then(() => toggleDeleteModel());
        }}
      />
    </>
  );
};

export default InviteClient;
