import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Toaster } from "react-hot-toast";

const Toast = () => {
  return (
    <Toaster
      position="bottom-center"
      reverseOrder={false}
      gutter={2}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "",
        duration: 1500,

        // Default options for specific types
        success: {
          duration: 3000,
          style: {
            background: "rgb(246, 253, 242)",
            color: "rgb(68, 96, 50)",
            fontSize: "14px",
            borderRadius: "2px",
            padding: "12px",
          },
          icon: <CheckIcon fontSize="small" />,
          theme: {
            primary: "green",
            secondary: "black",
          },
        },
        error: {
          duration: 3000,
          style: {
            background: "rgb(255, 245, 243)",
            color: "rgb(102, 65, 56)",
            fontSize: "14px",
            borderRadius: "2px",
            padding: "12px",
          },
          icon: <ErrorOutlineIcon fontSize="small" />,
        },
      }}
    />
  );
};

export default Toast;
