import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ConfirmationModel from "components/ConfirmationModel";
import {toggleExerciseModel} from "store/actions/program";
import {connect, useSelector} from "react-redux";
import {Grid} from "@mui/material";

const ExerciseDetailModel = (props) => {
    const currentElment = useSelector((state) => state.programs.currentElment);

    return (
        <ConfirmationModel
            showActions={false}
            maxWidth="xs"
            isOpen={props.isOpenExerciseModel}
            title="Recipe Details"
            body={
                <Card sx={{boxShadow: "none"}}>
                    <CardMedia
                        sx={{height: 216}}
                        image={currentElment ? currentElment["thumbnail"] : ""}
                        title="green iguana"
                    />
                    <CardContent sx={{px: 0, py: 3}}>
                        <Typography
                            gutterBottom
                            fontFamily='Poppins'
                            variant="h5"
                            fontWeight="600"
                            color="#222222"
                            fontSize="18px"
                        >
                            {currentElment ? currentElment["name"] : ""}
                        </Typography>
                        <Grid container mt={2}>
                            <Grid item md={6}>
                                <Typography fontFamily='Poppins' fontSize="16px" fontWeight="400">
                                    kcal: <b>{currentElment ? currentElment["kcal"] : ""} kcal</b>
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography fontFamily='Poppins' fontSize="16px" fontWeight="400">
                                    level: <b>{currentElment ? currentElment["difficulty_level"] : ""}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container my={2}>
                            <Grid item md={6}>
                                <Typography fontFamily='Poppins' fontSize="16px" fontWeight="400">
                                    Prep Time: <b>{currentElment ? currentElment["prep_time"] : ""} min</b>
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography fontFamily='Poppins' fontSize="16px" fontWeight="400">
                                    Cook Time: <b>{currentElment ? currentElment["cook_time"] : ""} min</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions sx={{justifyContent: "space-between", p: 0}}>
                        <Typography fontFamily='Poppins' fontSize="16px" fontWeight="400">
                            Protein: <b>{currentElment ? currentElment["recipe_nutritions"][0].value : ""}g</b>
                        </Typography>
                        <Typography fontFamily='Poppins' fontSize="16px" fontWeight="400">
                            Glucides: <b>{currentElment ? currentElment["recipe_nutritions"][2].value : ""}g</b>
                        </Typography>
                        <Typography fontFamily='Poppins' fontSize="16px" fontWeight="400">
                            Lipides: <b>{currentElment ? currentElment["recipe_nutritions"][1].value : ""}g</b>
                        </Typography>
                    </CardActions>
                </Card>
            }
            handleClose={() => props.toggleExerciseModel()}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        isOpenExerciseModel: state.programs.isOpenExerciseModel
    }
}
export default connect(mapStateToProps, {
    toggleExerciseModel
})(ExerciseDetailModel);
