import axios from "axios";
import { useEffect, useState } from "react";

const { ImageList, ImageListItem } = require("@mui/material");

const ImageLibrary = ({ libraryApi, triggerCallBack, view = "portrait" }) => {
  const [imageList, setImageList] = useState([]);
  useEffect(() => {
    axios
      .get(libraryApi)
      .then((response) => {
        setImageList(response?.data?.data);
      })
      .catch((err) => {});
  }, []);
  const selectImage = (url) => {
    triggerCallBack(url);
  };
  return (
    <ImageList
      sx={{
        width: 500,

        paddingLeft: "15px",
        "@media (max-width: 600px)": {
          width: "100%",
        },
      }}
      cols={3}
      gap={8}
      rowHeight={view === "portrait" ? 228 : 90}
    >
      {imageList.map((item) => (
        <ImageListItem
          sx={{
            img: { borderRadius: "8px" },
            overflow: "hidden",
            cursor: "pointer",
          }}
          key={item._id}
        >
          <img
            onClick={() => selectImage(item.url)}
            src={`${item.url}?w=${view === "portrait" ? 228 : 151}&h=${
              view === "portrait" ? 228 : 90
            }&fit=crop&auto=format`}
            alt={item._id}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImageLibrary;
