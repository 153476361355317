import { Rings } from "react-loader-spinner";

const Loader = ({ isLoading }) => {
  if (isLoading) {
    return (
      <Rings
        height="250"
        width="250"
        color="#0933C6"
        radius="6"
        wrapperStyle={{
          justifyContent: "center",
          alignItems: "center",
          height: 300,
        }}
        wrapperClass=""
        visible={true}
        ariaLabel="rings-loading"
      />
    );
  }
};

export default Loader;
