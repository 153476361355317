import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemAvatar,
  Stack,
  styled,
} from "@mui/material";
import ListItemButton from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ConfirmationModel from "components/ConfirmationModel";
import CustomIcon from "components/CustomIcon";
import Field from "components/Field";
import { required } from "components/Field/validate";
import { Formik, connect } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { toggleExerciseModel } from "store/actions/program";
import { THUMBNAIL_PLACEHOLDER } from "utils/constants";
import SubTitleCard from "../SubTitleCard";

const StyledListItem = styled(ListItemButton)(() => ({
  padding: 0,
  gap: "10px",
  overflow: "hidden",
  cursor: "pointer",
  padding: "8px 16px 8px 35px",
  position: "relative",
}));

const StyledCollapse = styled(Collapse)(() => ({
  width: "100%",
  gap: "10px",
  display: "grid",
  ".MuiCollapse-wrapperInner": {
    gap: "10px",
    display: "grid",
  },
  ".MuiCollapse-wrapper": {
    padding: "15px",
  },
}));

const ExerciseCard = ({ index: exerciseIndex, formik, exercisesList }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = formik;
  const [open, setOpen] = React.useState([]);
  const activeDay = useSelector((state) => state.programs.activeDay);
  const activeWeek = useSelector((state) => state.programs.activeWeek);
  const [commentExerciseIndex, setCommentExerciseIndex] = useState(0);
  const [comment, setComment] = useState("");

  const activeElement = values["week"][activeWeek][activeDay];

  const [showCommentModal, setShowCommentModal] = useState(false);
  const commentFormRef = useRef(null);

  const getExerciseComment = (index) => {
    setCommentExerciseIndex(index);
    const exerciseComment =
      activeElement.exercise_groups[exerciseIndex]?.exercises[index]?.comment ||
      "";
    setComment(exerciseComment);
    return exerciseComment;
  };
  useEffect(() => {
    // reset expanded boxes
    setOpen([]);
  }, [activeDay, activeWeek]);
  const setExerciseComment = () => {
    setFieldValue(
      whereTo +
        `.exercise_groups[${exerciseIndex}].exercises[${commentExerciseIndex}].comment`,
      commentFormRef?.current?.values.comment
    );
  };

  const handleClick = (id = "") => {
    let list = open;
    if (list.includes(id)) {
      list = list.filter((ele) => ele !== id);
    } else {
      list.push(id);
    }
    setOpen([...list]);
  };

  const whereTo = `week.${activeWeek}.[${activeDay}]`;
  return (
    <>
      <Droppable
        droppableId={`exercisegroup_${exerciseIndex}`}
        type="droppableSubItem"
      >
        {(provided) => (
          <Stack
            ref={provided.innerRef}
            {...provided.droppableProps}
            mb={1}
            sx={{
              overflow: "hidden",
            }}
          >
            <List component="div" disablePadding>
              {(exercisesList || []).map((exercise, index) => {
                const exercises = activeElement.exercise_groups[exerciseIndex]
                  ? activeElement.exercise_groups[exerciseIndex].exercises
                  : [];
                return (
                  <Draggable
                    draggableId={
                      whereTo +
                      `.exercise_groups[${exerciseIndex}].exercises[${index}]`
                    }
                    index={index}
                  >
                    {(provided) => (
                      <Box
                        key={index}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        sx={{
                          position: "relative",
                          marginBottom: "5px",
                          border: "1px solid #E0E4EA",
                          borderRadius: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <Field
                          type="text"
                          name={
                            whereTo +
                            `.exercise_groups[${exerciseIndex}].exercises[${index}].exercise_id`
                          }
                          size="small"
                          sx={{ display: "none" }}
                        />
                        <StyledListItem
                          secondaryAction={
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap="10px"
                            >
                              <div>
                                <IconButton
                                  onClick={(e) => {
                                    setShowCommentModal(true);
                                    getExerciseComment(index);
                                  }}
                                  sx={{ position: "relative" }}
                                >
                                  {activeElement.exercise_groups[exerciseIndex]
                                    ?.exercises[index]?.comment && (
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        width: "7px",
                                        height: "7px",
                                        borderRadius: "50%",
                                        backgroundColor: "#FFCC00",
                                        top: "5px",
                                        right: "7px",
                                      }}
                                    ></Box>
                                  )}
                                  <CustomIcon
                                    title="Ajouter un commentaire"
                                    iconSrc="/assets/logo/comment.svg"
                                  />
                                </IconButton>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (open.includes(exercise._id + index)) {
                                      handleClick(exercise._id);
                                    }
                                    const data = [...exercises];
                                    data.splice(index, 1);
                                    setFieldValue(
                                      whereTo +
                                        `.exercise_groups[${exerciseIndex}].exercises`,
                                      [...data]
                                    );
                                  }}
                                >
                                  <CustomIcon
                                    title="Supprimer le programme"
                                    iconSrc="/assets/logo/trash_icon.svg"
                                    iconHoverSrc="/assets/logo/trash_icon_hover.svg"
                                  />
                                </IconButton>
                                <IconButton>
                                  <DragIndicatorIcon fontSize="small" />
                                </IconButton>
                              </div>
                            </Stack>
                          }
                          onClick={() => handleClick(exercise._id + index)}
                        >
                          <IconButton
                            sx={{
                              position: "absolute",
                              left: "0px",
                              top: "5px",
                            }}
                          >
                            {open.includes(exercise._id + index) ? (
                              <ExpandMore />
                            ) : (
                              <ChevronRightIcon />
                            )}
                          </IconButton>
                          <ListItemAvatar
                            sx={{ display: "flex", minWidth: "1px" }}
                            onClick={() =>
                              dispatch(toggleExerciseModel(exercise))
                            }
                          >
                            <img
                              src={
                                exercise.thumbnail
                                  ? exercise.thumbnail
                                  : THUMBNAIL_PLACEHOLDER
                              }
                              height="32"
                              width="32"
                              alt="gym.jpg"
                              style={{
                                borderRadius: "8px",
                              }}
                              onError={(event) => {
                                event.target.src = THUMBNAIL_PLACEHOLDER;
                                event.onerror = null;
                              }}
                            />
                          </ListItemAvatar>
                          <ListItemText primary={exercise.name} />
                        </StyledListItem>
                        <StyledCollapse
                          in={open.includes(exercise._id + index)}
                          timeout="auto"
                          sx={{
                            width: "100%",
                            gap: "10px",
                            display: "grid",
                          }}
                        >
                          <SubTitleCard
                            exerciseIndex={exerciseIndex}
                            index={index}
                            exercise={exercise}
                          />
                        </StyledCollapse>
                      </Box>
                    )}
                  </Draggable>
                );
              })}
            </List>
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
      <ConfirmationModel
        isOpen={showCommentModal}
        confirmButtonColor="primary"
        confirm="Add Comment"
        back="Close"
        title="Add Comment"
        body={
          <>
            <Formik
              enableReinitialize={true}
              innerRef={commentFormRef}
              initialValues={{ comment: comment }}
            >
              <Field
                type="text"
                placeholder="Entrer un commentaire..."
                name="comment"
                notched={true}
                multiline={true}
                rows={10}
                validate={required}
              />
            </Formik>
          </>
        }
        maxWidth="sm"
        handleClose={() => setShowCommentModal(false)}
        onConfirm={() => {
          setExerciseComment();
          setShowCommentModal(false);
        }}
      />
    </>
  );
};

export default connect(ExerciseCard);
