import { Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import Field from "components/Field";
import { email, required } from "components/Field/validate";
import { Formik } from "formik";
import sha512 from "js-sha512";
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserDetails } from "store/actions/user";
import Cookie from "../../../utils/cookie";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Grid
      item
      lg={7}
      md={12}
      sx={{
        width: "100%",
        margin: "auto",
        display: "flex",
      }}
    >
      <Stack
        sx={{ width: "100%", gap: { xs: "30px", md: "80px" } }}
        display="flex"
        alignItems="center"
        gap="96px"
      >
        <Stack>
          <Typography
            component="span"
            sx={{
              color: "primary.main",
              fontWeight: 400,
              fontSize: "12px",
              cursor: "pointer",
            }}
          >
            {" "}
            <img
              src="/assets/images/evocoach-logo.png"
              alt="Evocoach"
              width="155"
            />
          </Typography>
        </Stack>
        <Stack>
          <Typography
            component="span"
            variant="h3"
            sx={{ mt: 3 }}
            fontWeight="700"
            fontSize="28px"
            textAlign="center"
          >
            {" "}
            Bienvenue !
          </Typography>
          <Typography
            component="span"
            variant="h3"
            sx={{ mt: 3 }}
            fontWeight="400"
            fontSize="14px"
            textAlign="center"
          >
            Veuillez entrer votre adresse mail et votre mot de passe pour vous
            connecter
          </Typography>
        </Stack>
        <Stack width="100%">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                ...values,
              };
              data.password = sha512(values.password);
              setSubmitting(true);
              axios
                .post(`/api/auth/coach/login`, data)
                .then((res) => {
                  axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${res?.data?.data?.accessToken}`;
                  Cookie.save("jwt", res?.data?.data?.accessToken);
                  const userData = { ...res?.data?.data };
                  delete userData.accessToken;
                  Cookie.save("user", JSON.stringify(userData));
                  const userDetails = jwt_decode(res?.data?.data?.accessToken);
                  dispatch(setUserDetails(userDetails));
                  navigate("/");
                  setSubmitting(false);
                })
                .catch((err) => {
                  setSubmitting(false);
                  toast.error(
                    err?.response?.data?.message || "Invalid Access."
                  );
                });
            }}
          >
            {(props) => {
              const { handleSubmit, isSubmitting } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    type="text"
                    label="Email"
                    placeholder="Email"
                    name="email"
                    mb={3}
                    validate={email}
                  />
                  <Field
                    type="password"
                    label="Password"
                    placeholder="Password"
                    name="password"
                    validate={required}
                  />
                  <Typography
                    component="div"
                    textAlign="right"
                    mb={2}
                    sx={{
                      fontWeight: 400,
                      textDecoration: "underline",
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "#0001FC",
                    }}
                    onClick={() => navigate("/forget-password")}
                  >
                    {" "}
                    Mot de passe oublié ?
                  </Typography>
                  <Stack width="100%" mb={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {" "}
                      Sign In
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default LoginForm;
