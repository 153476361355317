import { Button, Container, Stack } from "@mui/material";
import TitleHeader from "components/TitleHeader";
import { addDays } from "date-fns";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setHeaderDetails } from "store/actions/header";
import {
  getCurrentNutrition,
  getInitialNutrition,
  setNutritionData,
  setTotalWeekNutrition,
} from "store/actions/nutritions";
import { setPrompt } from "store/actions/prompt";
import { ATTENTION_MESSAGE } from "utils/constants";
import Loader from "../../components/Loader";
import NutritionDetails from "./NutritionDetails";

const CreateNutritionPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const nutritionData = useSelector((state) => state.nutritions.nutritionData);
  const isLoadingNutritins = useSelector(
    (state) => state.nutritions.isLoadingNutritins
  );
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [editorValue, setEditorValue] = useState("<p></p>");
  const [savedEditorValue, setSavedEditorValue] = useState("<p></p>");
  useEffect(() => {
    if (nutritionData) {
      setSavedEditorValue(nutritionData.description);
    }
  }, [nutritionData]);

  useEffect(() => {
    if (id && _.isEmpty(location.state)) {
      dispatch(getCurrentNutrition(id));
    }
    const navDetail = {
      isBreadcrumns: true,
      title: "",
      breadcrumsList: [
        {
          title: "Programmes de nutrition",
          link: "/nutrition/programs",
          command: () => {
            dispatch(
              setPrompt({
                isOpen: true,
                message: ATTENTION_MESSAGE,
                primaryBtnHandler: () => {
                  navigate("/nutrition/programs");
                },
                btnAlign: "right",
                primaryBtnColor: "error",
                secondaryBtnText: "Annuler",
              })
            );
          },
        },
        {
          title: "Étape 1",
          link: "",
        },
      ],
    };
    dispatch(setHeaderDetails(navDetail));
  }, []);

  if (isLoadingNutritins) {
    return <Loader isLoading={isLoadingNutritins} />;
  }
  const handleFormSubmit = () => {
    formRef.current?.handleSubmit();
  };
  return (
    <>
      <Container
        maxWidth="xxl"
        className="header-box"
        sx={{ padding: "0 !important", marginBottom: "20px" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <TitleHeader title="Créer un nouveau programme" />
          <Button
            type="submit"
            color="success"
            variant="contained"
            onClick={() => handleFormSubmit()}
            disabled={formRef.current?.isSubmitting}
          >
            Continuer
          </Button>
        </Stack>
      </Container>
      <Container className="detail" maxWidth="xxl">
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={nutritionData}
          onSubmit={(values) => {
            const data = {
              ...values,
              description: editorValue,
              end_date: dayjs(
                addDays(new Date(values.start_date), values?.program_weeks * 7)
              ),
            };
            setIsFormDirty(false);
            dispatch(setTotalWeekNutrition(values.program_weeks));
            dispatch(getInitialNutrition(values.program_weeks));
            dispatch(setNutritionData(data));
            setTimeout(() => {
              navigate(`/nutrition/programs/final${id ? `/${id}` : ""}`);
            }, 500);
          }}
        >
          {(props) => {
            const { dirty } = props;
            setIsFormDirty(dirty);
            return (
              <Form>
                <NutritionDetails
                  editorValue={editorValue}
                  setEditorValue={setEditorValue}
                  savedEditorValue={savedEditorValue}
                />
              </Form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};

export default CreateNutritionPage;
