import { Grid, styled } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookie from "utils/cookie";
import ForgetPasswordPage from "./ForgetPasswordPage";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";

const StyledImage = styled(Grid)(() => ({
  "& img": {
    height: "100vh",
    width: "100%",
  },
}));

const AuthPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (Cookie.get("jwt")) {
      navigate("/");
    }
  }, []);
  let coverImage = "";
  if (window.location.pathname === "/forget-password") {
    coverImage = "forgot_password_cover.jpg";
  }  else {
    coverImage = "login_image.jpg";
  }

  return (
    <Grid
      container
      component="main"
      sx={{ height: "100vh", overflow: "hidden" }}
    >
      <StyledImage
        item
        md={3}
        sx={{
          display: { xs: "none", md: "block" },
          backgroundImage: `url(/assets/images/${coverImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
          backgroundColor: "#ffffff",
        }}
      ></StyledImage>
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          height: "calc(100vh - 0px)",
          padding: { xs: "10px 20px", md: "20px 0" },
          overflowY: "auto",
          background: "white",
        }}
        display="flex"
      >
        {window.location.pathname === "/forget-password" && (
          <ForgetPasswordPage />
        )}
        {window.location.pathname === "/login" && <LoginForm />}
        {window.location.pathname === "/signup" && <SignupForm />}
      </Grid>
    </Grid>
  );
};

export default AuthPage;
