import {
    GET_RECIPES_LISTS,
    PAGINATE_RECIPE_DATA,
    SEARCH_RECIPE_DATA,
    SET_LOADING_RECIPES,
} from "store/actions/recipes";

export default function recipes(state = null, action) {
    if (state == null) {
        return {
            currentPage: 1,
            recipesLists: null,
            searchedRecipes: null,
            isLoadingRecipes: false,
        };
    }
    switch (action.type) {
        case GET_RECIPES_LISTS:
            return {
                ...state,
                recipesLists: action.payload,
                searchedRecipes: action.payload,
            };
        case SEARCH_RECIPE_DATA:
            return {
                ...state,
                currentPage: 1,
                recipesLists: action.payload,
            };
        case SET_LOADING_RECIPES:
            return {
                ...state,
                isLoadingRecipes: action.payload,
            };
        case PAGINATE_RECIPE_DATA:
            return {
                ...state,
                currentPage: action.payload,
            };
        default:
            return state;
    }
}
