import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import NavHeader from "./NavHeader";

// ----------------------------------------------------------------------//

const DRAWER_WIDTH = 271;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 80;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  color: "black",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: "20px 24px",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "0px none",
  },
}));

// ----------------------------------------------------------------------//

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <RootStyle className={location.pathname === "/" ? classes.root : ""}>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <DensityMediumIcon />
        </IconButton>
        {<NavHeader location={location.pathname} />}
        <Box sx={{ flexGrow: 1 }} />
      </ToolbarStyle>
    </RootStyle>
  );
}
