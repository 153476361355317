import axios from "axios";
import { setPrompt } from "./prompt";
export const GET_COACHES_DATA = "get_coaches_data";
export const getCoaches = () => {
  return (dispatch) => {
    dispatch(loadingCoaches(true));
    axios
      .get("/api/owner/coach/list")
      .then((res) => {
        dispatch({
          type: GET_COACHES_DATA,
          payload: res.data.data,
        });
        dispatch(loadingCoaches(false));
      })
      .catch(() => {
        dispatch({
          type: GET_COACHES_DATA,
          payload: [],
        });
        dispatch(loadingCoaches(false));
      });
  };
};

export const PAGINATION_COACHES_DATA = "pagination_coaches_data";
export const changePagination = (response = 1) => {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_COACHES_DATA,
      payload: response,
    });
  };
};

export const SEARCH_COACHES_DATA = "search_coaches_data";
export const searchCoaches = (response = "") => {
  return (dispatch, getState) => {
    const filteredItem = (getState().coaches.searchedCoaches || []).filter(
      (e) =>
        e.first_name?.toLowerCase().includes(response.toLowerCase()) ||
        e.last_name?.toLowerCase().includes(response.toLowerCase()) ||
        e.email?.toLowerCase().includes(response.toLowerCase())
    );
    dispatch({
      type: SEARCH_COACHES_DATA,
      payload: filteredItem,
    });
  };
};

export const inviteCoachHandler = (data, toggleInviteCoachModal) => {
  return (dispatch) => {
    return axios
      .post(`/api/owner/invite/coach`, data)
      .then(() => {
        dispatch(getCoaches());

        toggleInviteCoachModal();
      })
      .catch((err) => {
        toggleInviteCoachModal();
        dispatch(
          setPrompt({
            isOpen: true,
            message: err?.response?.data?.message,
            hidePrimaryBtn: true,
          })
        );
      });
  };
};

export const updateCoachDetailsHandler = (data, id, toggleInviteCoachModal) => {
  return (dispatch) => {
    return axios
      .patch(`/api/coach/users/${id}`, data)
      .then(() => {
        dispatch(getCoaches());

        toggleInviteCoachModal();
      })
      .catch((err) => {
        toggleInviteCoachModal();
      });
  };
};

export const deleteCoachById = (id = "", toggleDeleteModal) => {
  return (dispatch) => {
    return axios
      .delete(`/api/coach/users/${id}`)
      .then(() => {
        dispatch(getCoaches());
        toggleDeleteModal();
      })
      .catch((err) => {
        toggleDeleteModal();
      });
  };
};

export const LOADING_COACHES_DATA = "loading_coaches_data";
export const loadingCoaches = (response = false) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_COACHES_DATA,
      payload: response,
    });
  };
};
