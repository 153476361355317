import {
  Grid,
  InputAdornment,
  List,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import Field from "components/Field";
import { connect } from "formik";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import usePrevious from "utils/hooks/usePrevious";

const SubTitleCard = ({ formik, index, exerciseIndex, exercise }) => {
  const { values, setFieldValue } = formik;
  const activeDay = useSelector((state) => state.programs.activeDay);
  const activeWeek = useSelector((state) => state.programs.activeWeek);
  const [elements, setElements] = useState([0]);

  const whereTo = `week.${activeWeek}.[${activeDay}]`;
  const activeElement = values["week"]
    ? values["week"][activeWeek]
      ? values["week"][activeWeek][activeDay]
      : {}
    : {};
  useEffect(() => {
    setFieldValue(
      `${whereTo}.exercise_groups[${exerciseIndex}].exercises[${index}].exercise_id`,
      exercise["_id"]
    );
  }, []);
  useEffect(() => {
    const programMuscle =
      values.week[activeWeek][activeDay].exercise_groups[exerciseIndex]?.muscle;
    setElements(programMuscle ? _.range(programMuscle) : [0]);
  }, [
    values.week[activeWeek][activeDay].exercise_groups[exerciseIndex]?.muscle,
  ]);
  // Reset sets values when changed muscle0 dropdown
  const currentSets =
    activeElement["exercise_groups"][exerciseIndex]["exercises"][index]["sets"];

  const prevElements = usePrevious(elements);
  const updateCurrentSets = (current, exerciseParam) => {
    if (!current && elements.length !== prevElements?.length) {
      setFieldValue(
        exerciseParam,
        activeElement["exercise_groups"][exerciseIndex]["exercises"][index][
          "sets"
        ][0]
      );
    }
    if (elements.length < prevElements?.length) {
      currentSets.splice(elements.length, prevElements.length);
      setFieldValue(
        `${whereTo}.exercise_groups[${exerciseIndex}].exercises[${index}].sets`,
        currentSets
      );
    }
  };

  const setUnitNameValue = (frmStr, e) => {
    setFieldValue(`${frmStr}.unit2.name`, e.target.value);
    if (e.target.value === "durée") {
      setFieldValue(`${frmStr}.unit2.value`, "00:00");
    } else {
      setFieldValue(`${frmStr}.unit2.value`, "");
    }
  };

  const handleUnit2Change = (e, frmStr, index, i) => {
    let value = e.target.value;
    const selectedUnit = getCurrentUnit2Selection(index, i);
    if (selectedUnit === "durée") {
      // Remove all non-digit and non-colon characters
      value = value.replace(/[^0-9:]/g, "");

      // Ensure there is only one colon
      const colonCount = (value.match(/:/g) || []).length;
      if (colonCount > 1) {
        value = value.slice(0, value.lastIndexOf(":") + 1); // Remove extra colons
      }
      // Split the value by the colon to process minutes and seconds
      let [minutes, seconds] = value.split(":");

      // Limit minutes to two digits and ensure it's between 00 and 59
      if (minutes) {
        minutes = minutes.slice(0, 2);
        if (parseInt(minutes) > 59) {
          minutes = "59";
        }
      }
      // Limit seconds to two digits and ensure it's between 00 and 59
      if (seconds) {
        seconds = seconds.slice(0, 2);
        if (parseInt(seconds) > 59) {
          seconds = "59";
        }
      }

      // Ensure the minutes and seconds are always two digits
      if (minutes && seconds) {
        value = `${minutes}:${seconds}`;
      } else if (minutes) {
        value = `${minutes}:`;
      } else if (seconds) {
        value = `:${seconds}`;
      } else {
        value = "";
      }
      setFieldValue(`${frmStr}.unit2.value`, value);
    } else {
      setFieldValue(`${frmStr}.unit2.value`, value);
    }
  };
  const handleUnit2KeyDown = (e, index, i) => {
    const selectedUnit = getCurrentUnit2Selection(index, i);

    if (
      (selectedUnit === "durée" && e.key === "Backspace") ||
      e.key === "Delete"
    ) {
      e.preventDefault();
    }
  };
  const handleUnit2Click = (e, index, i) => {
    const selectedUnit = getCurrentUnit2Selection(index, i);
    if (selectedUnit !== "durée") {
      return;
    }
    const timeInput = e.target;
    const cursorPosition = timeInput.selectionStart;

    if (cursorPosition <= 2) {
      // If clicked near "mm"
      timeInput.setSelectionRange(0, 2);
    } else {
      // Otherwise, select "ss"
      timeInput.setSelectionRange(3, 5);
    }
  };

  const getCurrentUnit2Selection = (index, i) => {
    return activeElement["exercise_groups"][exerciseIndex]["exercises"][index][
      "sets"
    ][i]?.unit2?.name;
  };

  const renderExercies = useMemo(() => {
    let list = [];
    (elements || []).forEach((ele, i) => {
      const exerciseParam = `${whereTo}.exercise_groups[${exerciseIndex}].exercises[${index}].sets[${i}]`;
      const current =
        activeElement["exercise_groups"][exerciseIndex]["exercises"][index][
          "sets"
        ][i];
      updateCurrentSets(current, exerciseParam);
      list.push(
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          mb={i < elements.length - 1 ? 2 : 0}
          key={i}
        >
          <Typography
            variant="subtitle1"
            color="#222222"
            fontWeight="500"
            fontSize="12px"
            textTransform="capitalize"
            width="45px"
            textAlign="center"
          >
            {"Set " + (i + 1)}
          </Typography>
          <Grid container columnSpacing={2}>
            <Grid
              item
              md={4}
              sx={{
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
              }}
            >
              <Field
                type="text"
                name={exerciseParam + ".unit1.value"}
                size="small"
                label="Unité 1"
                placeholder="0"
                disabled={activeElement?.isCompleted}
                mb={0}
                InputProps={{
                  endAdornment: (
                    <Field
                      type="select"
                      placeholder="Aucune"
                      size="small"
                      mb={0}
                      disabled={activeElement?.isCompleted}
                      name={exerciseParam + ".unit1.name"}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "0 none",
                        },
                      }}
                    >
                      <MenuItem sx={{ fontSize: "14px" }} value="none">
                        Aucune
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value="kg" selected>
                        Kg
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value="vitesse">
                        Vitesse
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value="niv">
                        Niv
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value="%rm">
                        %RM
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value="rpe">
                        RPE
                      </MenuItem>
                    </Field>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              md={4}
              sx={{
                ".MuiInputBase-root": {
                  paddingRight: "0px",
                },
              }}
            >
              <Field
                type="text"
                step={2}
                name={exerciseParam + ".unit2.value"}
                size="small"
                label="Unité 2"
                placeholder="0"
                onChange={(e) => handleUnit2Change(e, exerciseParam, index, i)}
                onClick={(e) => handleUnit2Click(e, index, i)}
                onKeyDown={(e) => {
                  handleUnit2KeyDown(e, index, i);
                }}
                disabled={activeElement?.isCompleted}
                mb={0}
                InputProps={{
                  endAdornment: (
                    <Field
                      type="select"
                      placeholder="Aucune"
                      size="small"
                      onChange={(e) => setUnitNameValue(exerciseParam, e)}
                      name={exerciseParam + ".unit2.name"}
                      mb={0}
                      disabled={activeElement?.isCompleted}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "0 none",
                        },
                      }}
                    >
                      <MenuItem sx={{ fontSize: "14px" }} value="none">
                        Aucune
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value="rep">
                        Rep
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value="mètres">
                        Mètres
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value="durée">
                        Durée
                      </MenuItem>
                    </Field>
                  ),
                }}
              />
            </Grid>
            <Grid item md={4}>
              <Field
                type="number"
                name={exerciseParam + ".rest"}
                placeholder="0"
                size="small"
                label="Récupération"
                mb={0}
                disabled={activeElement?.isCompleted}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">repos</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      );
    });
    return list;
  }, [
    elements,
    activeWeek,
    activeDay,
    ...Array.from(
      elements,
      (_, i) =>
        activeElement?.exercise_groups?.[exerciseIndex]?.exercises?.[index]
          ?.sets?.[i]?.unit2?.name
    ),
  ]);

  return (
    <List component="div" disablePadding onClick={(e) => e.stopPropagation()}>
      {renderExercies}
    </List>
  );
};

export default connect(SubTitleCard);
