import {
  GET_CLIENTS_DATA,
  LOADING_CLIENTS_DATA,
  PAGINATION_CLIENTS_DATA,
  SEARCH_CLIENTS_DATA,
  SET_CLIENT_DOCUMENTS,
  SET_CLIENT_EVOLUTIONS,
  SET_CLIENT_FITNESS_PROGRAMS,
  SET_CLIENT_NOTES,
  SET_CLIENT_NUTRITION_PROGRAMS,
  SET_CLIENT_SESSIONS,
  SET_SELECTED_CLIENT_DETAILS,
} from "store/actions/clients";
export default function clients(state = null, action) {
  if (state == null) {
    return {
      searchedClients: [],
      clients: [],
      currentPage: 1,
      totalItem: 0,
      isLoadingClients: false,
      loggedInUser: {},
      selectedClient: {},
      clientDocuments: [],
      clientFitnessPrograms: [],
      clientSessions: [],
      clientNutritionPrograms: [],
      clientEvolutions: [],
      clientNotes: [],
    };
  }
  switch (action.type) {
    case GET_CLIENTS_DATA:
      return {
        ...state,
        clients: action.payload,
        searchedClients: action.payload,
      };
    case PAGINATION_CLIENTS_DATA:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SEARCH_CLIENTS_DATA:
      return {
        ...state,
        currentPage: 1,
        clients: action.payload,
      };
    case LOADING_CLIENTS_DATA:
      return {
        ...state,
        isLoadingClients: action.payload,
      };
    case SET_SELECTED_CLIENT_DETAILS:
      return {
        ...state,
        selectedClient: action.payload,
      };
    case SET_CLIENT_FITNESS_PROGRAMS: {
      return {
        ...state,
        clientFitnessPrograms: action.payload,
      };
    }
    case SET_CLIENT_SESSIONS: {
      return {
        ...state,
        clientSessions: action.payload,
      };
    }
    case SET_CLIENT_NUTRITION_PROGRAMS: {
      return {
        ...state,
        clientNutritionPrograms: action.payload,
      };
    }
    case SET_CLIENT_EVOLUTIONS: {
      return {
        ...state,
        clientEvolutions: action.payload,
      };
    }
    case SET_CLIENT_NOTES: {
      return {
        ...state,
        clientNotes: action.payload,
      };
    }
    case SET_CLIENT_DOCUMENTS: {
      return {
        ...state,
        clientDocuments: action.payload,
      };
    }
    default:
      return state;
  }
}
