import _ from "lodash";
export function filterSets(arr, num) {
  let result = [];
  for (let item of arr) {
    let sets = item.sets.slice(0, num);
    let filteredItem = {
      exercise_id: item.exercise_id,
      sets: sets,
    };
    result.push(filteredItem);
  }
  return result;
}

export function createOrOverrideElement(array1, array2) {
  let list = [];
  for (let i = 0; i < array1.length; i++) {
    const elementToUpdate = array2[i];
    list.push({
      ...array1[i],
      ...elementToUpdate,
    });
  }

  return list;
}

export function createArrayOfElements(n) {
  const emptyExercise = {
    unit1: {
      name: "",
      value: "",
    },
    unit2: {
      name: "",
      value: "",
    },
    rest: "",
  };

  const arr = [];
  for (let i = 0; i < n; i++) {
    arr.push(Object.assign({}, emptyExercise));
  }

  return arr;
}

function replaceEmptyWithZero(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        replaceEmptyWithZero(obj[key]);
      } else if (
        obj[key] === "" ||
        obj[key] === null ||
        obj[key] === undefined
      ) {
        obj[key] = 0;
      }
    }
  }
}

export function replaceArrayObjValues(arr, from, to, skipKeys) {
  if (arr.length > 0) {
    return _.map(arr, (obj) => {
      replaceEmptyWithZero(obj);
      return obj;
    });
  }
  return arr;
}
