import { Box, styled, Typography } from "@mui/material";
const StatusStyled = styled(Typography)(({ theme }) => ({
  background: "#E4EAFC",
  fontSize: "12px",
  padding: "4px 12px",
  color: "#0001FC",
  border: "1px solid #0001FC",
  minWidth: "116px",
  borderRadius: "44px",
  textAlign: "center",
  fontWeight: 600,
  span: {
    display: "inline-block",
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    background: "#0001FC",
    lineHeight: "12px",
    marginRight: "5px",
    marginBottom: "1px",
  },
  "&.inactive": {
    span: {
      background: "#E38C3C",
    },
    background: "#FBEDE0",
    borderColor: "#E38C3C",
    color: "#E38C3C",
  },
  "&.draft": {
    span: {
      background: "#757E97",
    },
    background: "#E0E4EA",
    borderColor: "#757E97",
    color: "#757E97",
  },
  "&.template": {
    span: {
      background: "#FFFFFF",
    },
    background: "#757E97",
    borderColor: "#757E97",
    color: "#FFFFFF",
  },
  "&.finished": {
    span: {
      background: "#119C83",
    },
    background: "#E4F2E5",
    borderColor: "#119C83",
    color: "#119C83",
  },
}));

const Status = ({ type, label }) => {
  const getStatusTypeClass = () => {
    const status = type.toLowerCase();
    let statusClass = "active";
    switch (status) {
      case "draft":
      case "brouillon":
        statusClass = "draft";
        break;
      case "modèle":
        statusClass = "template";
        break;
      case "inactif":
        statusClass = "draft";
        break;
      case "à venir":
        statusClass = "inactive";
        break;
      case "terminé":
        statusClass = "finished";
        break;
      case "complété":
        statusClass = "finished";
        break;
      default:
        statusClass = "active";
    }
    return statusClass;
  };
  return (
    <StatusStyled className={getStatusTypeClass()} component="span">
      <Box component="span"></Box>
      {label}
    </StatusStyled>
  );
};

export default Status;
