import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useEffect, useState } from "react";

const CustomDatePicker = (props) => {
  dayjs.locale("fr");
  const { label, name, formik } = props;
  const { setFieldValue, values } = formik;
  const [date, setDate] = useState(null);
  useEffect(() => {
    if (date) {
      setFieldValue(name, date);
    }
  }, [date]);
  useEffect(() => {
    if (values[`${name}`]) {
      setDate(dayjs(values[`${name}`]));
    }
  }, [!values[`${name}`]]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <DatePicker
        label={label}
        sx={{
          ".MuiFormLabel-root": {
            fontSize: "14px",
            top: "-6px",
            "&.MuiInputLabel-shrink": { top: "2px" },
          },
        }}
        onChange={(newValue) => setDate(newValue)}
        value={date}
        format="DD MMMM YYYY"
        slotProps={{
          openPickerButton: { color: "primary" },
        }}
      />
    </LocalizationProvider>
  );
};
export default CustomDatePicker;
