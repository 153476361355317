import {
  GET_SESSIONS_DATA,
  LOADING_SESSIONS_DATA,
  PAGINATION_SESSIONS_DATA,
  SEARCH_SESSIONS_DATA,
} from "store/actions/sessions";
export default function clients(state = null, action) {
  if (state == null) {
    return {
      searchedSessions: [],
      sessions: [],
      currentPage: 1,
      totalItem: 0,
      isLoadingSessions: false,
    };
  }
  switch (action.type) {
    case GET_SESSIONS_DATA:
      return {
        ...state,
        sessions: action.payload,
        searchedSessions: action.payload,
      };
    case PAGINATION_SESSIONS_DATA:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SEARCH_SESSIONS_DATA:
      return {
        ...state,
        currentPage: 1,
        sessions: action.payload,
      };
    case LOADING_SESSIONS_DATA:
      return {
        ...state,
        isLoadingSessions: action.payload,
      };
    default:
      return state;
  }
}
