import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Button,
  Checkbox,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";

const StyledMenu = styled(Menu)((theme) => ({
  ".MuiButton-root": {
    textTransform: "none",
  },
  ".MuiMenu-list": {
    border: "1px solid #0001FC",
    borderRadius: "8px",

    minWidth: "240px",
    overflow: "hidden",
  },
  ".MuiMenuItem-root": {
    "&:hover": {
      background: "#E4EAFC",
      ".MuiTypography-root, .MuiCheckbox-root": {
        color: "#0001FC",
      },
    },
  },
  ".MuiFormControlLabel-root": {
    marginLeft: 0,
    width: "100%",
  },
  ".MuiCheckbox-root": {
    padding: "5px",
  },
  ".MuiTypography-root": {
    flex: 2,
    color: "#111111",
    fontSize: "14px",
  },
}));

const FilterMenu = ({ menuTitle, menuList, getSelectedValues = () => {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedValues, setSelectedValues] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    getSelectedValues(selectedValues);
  }, [selectedValues]);
  const selectOptions = (value) => {
    if (!selectedValues.includes(value)) {
      setSelectedValues([...selectedValues, value]);
    } else {
      const arr = selectedValues;
      arr.splice(selectedValues.indexOf(value), 1);
      setSelectedValues([...arr]);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        sx={{ textTransform: "none" }}
        variant="outlined"
        color="primary"
        onClick={handleClick}
        startIcon={<FilterListIcon />}
      >
        {menuTitle}
      </Button>
      <StyledMenu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuList.map((item, index) => (
          <MenuItem onClick={() => selectOptions(item.name)} key={index}>
            <ListItemText primary={item.name} />
            <Checkbox checked={selectedValues.includes(item.name)} />
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default FilterMenu;
