export const required = (value) => (value ? "" : "Ce champ est obligatoire.");
export const multiSelect = (value) =>
  (value || []).length !== 0 ? "" : "Ce champ est obligatoire.";
export const email = (value = "") =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Email is not valid email"
    : undefined;
export const compare = (value, value1) => {
  return () =>
    value !== value1 ? "Les mots de passe ne correspondent pas." : "";
};
