import { Box, Grid, Paper, Typography, styled } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeaderDetails } from "store/actions/header";
import Cookie from "utils/cookie";
import useDocumentTitle from "utils/hooks/useDocumentTitle";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: "center",
  boxShadow: "0px 8px 40px 0px rgba(0, 0, 0, 0.2)",
  cursor: "pointer",
}));
const Welcome = () => {
  const userDetails = JSON.parse(Cookie.get("user"));
  useDocumentTitle("Bienvenue sur Evodream Coach");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectTo = (link) => {
    navigate(link);
  };
  useEffect(() => {
    const navDetail = {
      isBreadcrumns: false,
      title: "Bienvenue",
    };
    dispatch(setHeaderDetails(navDetail));
  }, []);
  return (
    <Box pb={4} sx={{ flexGrow: 1, marginTop: { xs: "100px" } }}>
      <Typography
        variant="h5"
        component="h5"
        align="center"
        mb={2}
        style={{ fontWeight: 700 }}
      >
        Content de vous revoir !
      </Typography>

      <Typography variant="body1" px={2} mb={5} align="center">
        Gagnez du temps en accédant rapidement à votre contenu et simplifiez
        votre expérience.
      </Typography>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={10} md={3}>
          <Item onClick={() => redirectTo("/fitness/programs")}>
            <img
              width="100%"
              src="/assets/images/welcome_program_fitness_icon.png"
              alt="Recipe Program"
            />
            <Typography mt={2} variant="body2" style={{ fontWeight: 600 }}>
              Programmes de fitness
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={10} md={3}>
          <Item onClick={() => redirectTo("/nutrition/programs")}>
            <img
              width="100%"
              src="/assets/images/welcome_program_nutrition_icon.png"
              alt="Recipe Program"
            />
            <Typography mt={2} variant="body2" style={{ fontWeight: 600 }}>
              Programme de nutrition
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={10} md={3}>
          <Item
            onClick={() => {
              window.open(userDetails?.marketing_site || "", "_blank");
            }}
          >
            <img
              width="100%"
              src="/assets/images/welcom_marketing_icon.png"
              alt="web"
            />
            <Typography mt={2} variant="body2" style={{ fontWeight: 600 }}>
              Mon site marketing
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Welcome;
