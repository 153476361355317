// ----------------------------------------------------------------------

export default function Typography(theme) {
  return {
    MuiTypography: {
      root: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
      },
      span: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
      },
    },
  };
}
