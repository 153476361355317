import {
  Alert,
  Box,
  Grid,
  IconButton,
  Link,
  styled,
  Typography,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import CustomIcon from "components/CustomIcon";
import Status from "components/Status";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils/common";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";
import {
  cleanAllProgramData,
  copyProgram,
  getInitialProgram,
} from "../../../store/actions/program";

const StyledAlert = styled(Alert)(() => ({
  cursor: "pointer",
  border: "1px solid #E0E4EA",
  marginBottom: "8px",
  "&:last-child": {
    marginBottom: "0px",
  },
  ".MuiAlert-icon": {
    display: "none",
  },
  ".MuiAlert-action": {
    alignItems: "center",
  },
  ".MuiAlert-message": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#4F5668",
    fontSize: "14px",
    padding: 0,
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 32,
  width: "50px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E4EAFC",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 32,
    backgroundColor: "#0001FC",
  },
}));

const useStyle = makeStyles(() => ({
  img: {
    height: "40px",
    width: "40px",
    borderRadius: "8px",
  },
  progressbarText: {
    fontWeight: 600,
    color: "#111111",
    fontSize: "12px",
    lineHeight: "16px",
  },
}));

function checkAndAddCopy(str) {
  if (str.includes("copy")) {
    const regex = /copy\s*(\d*)$/;
    const match = str.match(regex);
    let count = 1;
    if (match && match[1]) {
      count = parseInt(match[1], 10);
    }
    str = str.replace(regex, "");

    return str.trim() + " copy " + (count + 1);
  } else {
    return str.trim() + " copy";
  }
}

const ProgrammItem = ({ toggleDeleteModel, program }) => {
  const dispatch = useDispatch();
  const naviagate = useNavigate();
  const classes = useStyle();
  const fullName = (fName, lName) => {
    if (fName || lName) {
      return `${fName} ${lName}`;
    } else {
      return "-";
    }
  };
  return (
    <StyledAlert
      variant="outlined"
      severity="info"
      onClick={() => {
        dispatch(cleanAllProgramData());
        dispatch(getInitialProgram());
        naviagate(`/fitness/programs/${program._id}`);
      }}
    >
      <Grid container>
        <Grid
          item
          sm={3}
          sx={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          <img
            className={classes.img}
            src={program.thumbnail}
            alt="profile"
            onError={(event) => {
              event.target.src = THUMBNAIL_PLACEHOLDER_GRAY;
              event.onerror = null;
            }}
          />
          <Link
            underline="hover"
            color="#4F5668"
            sx={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(cleanAllProgramData());
              dispatch(getInitialProgram());
              naviagate(`/fitness/programs/${program._id}`);
            }}
          >
            {program.name}
          </Link>
        </Grid>
        <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
          <Link
            underline="hover"
            color="#4F5668"
            sx={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              if (program.user_id) {
                naviagate(`/clients/${program.user_id}`);
              }
            }}
          >
            {fullName(program.first_name, program.last_name)}
          </Link>
        </Grid>

        {program?.status === "Brouillon" || program?.status === "Modèle" ? (
          <Grid item sm={3} sx={{ display: "flex", alignItems: "center" }}>
            -
          </Grid>
        ) : (
          <Grid item sm={3} sx={{ display: "flex", alignItems: "center" }}>
            {`${formatDate(program.start_date) || ""} - ${
              formatDate(program.end_date) || ""
            }`}
          </Grid>
        )}
        <Grid item sm={1} sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "100%", mr: 1 }}>
            <BorderLinearProgress
              variant="determinate"
              value={program.progress || 0}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography
              className={classes.progressbarText}
              variant="body2"
            >{`${Math.round(program.progress || 0)}%`}</Typography>
          </Box>
        </Grid>
        <Grid item sm={2} pl={3} sx={{ display: "flex", alignItems: "center" }}>
          <Status type={program.status} label={program.status} />
        </Grid>
        <Grid item sm={1} sx={{ display: "flex", justifyContent: "right" }}>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => {
              dispatch(cleanAllProgramData());
              dispatch(getInitialProgram());
              naviagate(`/fitness/programs/${program._id}`);
            }}
          >
            <CustomIcon
              iconSrc="/assets/logo/detail_icon.svg"
              iconHoverSrc="/assets/logo/detail_icon_hover.svg"
            />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(copyProgram(program._id));
            }}
          >
            <CustomIcon
              iconSrc="/assets/logo/copy.svg"
              iconHoverSrc="/assets/logo/copy_hover.svg"
            />
          </IconButton>
          <IconButton
            color="info.main"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              toggleDeleteModel(program);
            }}
          >
            <CustomIcon
              iconSrc="/assets/logo/trash_icon.svg"
              iconHoverSrc="/assets/logo/trash_icon_hover.svg"
            />
          </IconButton>
        </Grid>
      </Grid>
    </StyledAlert>
  );
};

export default ProgrammItem;
