import { FormHelperText, Stack } from "@mui/material";
import {
  ErrorMessage,
  FieldArray,
  Field as FormikField,
  connect,
} from "formik";
import _ from "lodash";
import PropTypes from "prop-types";
import CustomDatePicker from "./CustomDatePicker";
import {
  CustomInputComponent,
  CustomNumberComponent,
  CustomPasswordComponent,
  CustomTimeComponent,
  FormControlMultiSelect,
  FormControlSelect,
} from "./CustomInputComponent";
import ListInput from "./FieldArray";
import { CheckboxComponent, SwitchComponent } from "./Switch";

const Field = (props) => {
  const { type, formik, name, mb = 4 } = props;
  const { errors, touched } = formik;

  const getNestedObjectPropertyFromString = (from, ...selectors) =>
    [...selectors].map((s) =>
      s
        .replace(/\[([^\[\]]*)\]/g, ".$1.")
        .split(".")
        .filter((t) => t !== "")
        .reduce((prev, cur) => prev && prev[cur], from)
    );

  const validateErrorsVal = _.compact(
    getNestedObjectPropertyFromString(errors, name)
  );
  const validateTouchedVal = _.compact(
    getNestedObjectPropertyFromString(touched, name)
  );
  const error = Boolean(
    validateErrorsVal.length > 0 && validateTouchedVal.length > 0
  );

  let fieldItem = (
    <FormikField {...props} error={Boolean(error)} as={CustomInputComponent} />
  );

  if (type === "number") {
    fieldItem = (
      <FormikField
        {...props}
        onWheel={(e) => e.target.blur()}
        error={Boolean(error)}
        as={CustomNumberComponent}
      />
    );
  }

  if (type === "password") {
    fieldItem = (
      <FormikField
        {...props}
        type={type}
        error={Boolean(error)}
        as={CustomPasswordComponent}
      />
    );
  }

  if (type === "time") {
    fieldItem = (
      <FormikField
        {...props}
        type={type}
        error={Boolean(error)}
        as={CustomTimeComponent}
      />
    );
  }

  if (type === "file") {
    fieldItem = <FormikField type="file" {...props} />;
  }

  if (type === "select") {
    fieldItem = (
      <FormikField {...props} error={Boolean(error)} as={FormControlSelect} />
    );
  }

  if (type === "multiselect") {
    fieldItem = (
      <FormikField
        {...props}
        error={Boolean(error)}
        as={FormControlMultiSelect}
      />
    );
  }

  if (type === "list") {
    fieldItem = <FieldArray {...props} render={ListInput} />;
  }

  if (type === "switch") {
    fieldItem = (
      <FormikField>
        {({ field }) => (
          <SwitchComponent
            field={{ ...field, name: props.name }}
            label={props.label}
            customLable={props.customLable || false}
          />
        )}
      </FormikField>
    );
  }

  if (type === "checkbox") {
    fieldItem = (
      <FormikField>
        {({ field }) => (
          <CheckboxComponent
            field={{ ...field, name: props.name }}
            label={props.label}
          />
        )}
      </FormikField>
    );
  }

  if (type === "date") {
    fieldItem = (
      <FormikField
        {...props}
        InputLabelProps={{
          shrink: true, // Ensure the label floats by default
        }}
        error={Boolean(error)}
        as={CustomDatePicker}
      />
    );
  }

  return (
    <Stack sx={props.sx} mb={mb}>
      {fieldItem}
      {error && (
        <ErrorMessage
          name={name}
          render={(error) => (
            <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
          )}
        />
      )}
    </Stack>
  );
};

Field.propTypes = {
  type: PropTypes.oneOf([
    "password",
    "text",
    "select",
    "switch",
    "checkbox",
    "file",
    "multiselect",
    "number",
    "list",
    "date",
    "time",
  ]).isRequired,
  validate: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  sx: PropTypes.object,
  name: PropTypes.string.isRequired,
  notched: PropTypes.bool,
};
Field.defaultProps = {
  className: "mb-3",
  name: "default_name",
  sx: null,
  mb: 2,
};
export default connect(Field);
