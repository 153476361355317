import axios from "axios";
import history from "components/history";
import { filterRecords } from "utils/common";

export const LOADING_EXERCISE_DATA = "loading_exercise_data";
export const loadingExercise = (response = false) => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_EXERCISE_DATA,
      payload: response,
    });
  };
};

export const GET_EXERCISE_DATA = "get_exercise_data";
export const getExercises = () => {
  return (dispatch, getState) => {
    dispatch(loadingExercise(true));
    axios
      .get("/api/coach/exercises")
      .then((res) => {
        dispatch(loadingExercise(false));
        dispatch({
          type: GET_EXERCISE_DATA,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(loadingExercise(false));
      });
  };
};

export const createExercises = (values = {}, id = null) => {
  return () => {
    return axios({
      method: id ? "PATCH" : "POST",
      url: id ? `/api/coach/exercises/${id}` : "/api/coach/exercises",
      data: values,
    })
      .then((res) => {
        history.push("/fitness/exercices");
      })
      .catch((err) => {});
  };
};
export const SEARCH_EXERCISE_DATA = "search_exercise_data";
export const searchExercise = (
  search = "",
  ownerFilters = [],
  muscleFilters = []
) => {
  return (dispatch, getState) => {
    let filteredItem = getState().exercise.searchedExercise || [];

    filteredItem = filterRecords(
      filteredItem,
      search,
      muscleFilters,
      "primary_muscle"
    );
    if (ownerFilters.length > 0) {
      if (ownerFilters.includes("Moi") && ownerFilters.includes("Evocoach")) {
        filteredItem = (filteredItem || []).filter(
          (e) =>
            e?.created_by === process.env.REACT_APP_EVOCOACH_USER_ID ||
            e?.created_by !== process.env.REACT_APP_EVOCOACH_USER_ID
        );
      } else {
        if (ownerFilters.includes("Moi")) {
          filteredItem = (filteredItem || []).filter(
            (e) => e?.created_by !== process.env.REACT_APP_EVOCOACH_USER_ID
          );
        }
        if (ownerFilters.includes("Evocoach")) {
          filteredItem = (filteredItem || []).filter(
            (e) => e?.created_by === process.env.REACT_APP_EVOCOACH_USER_ID
          );
        }
      }
    }
    dispatch({
      type: SEARCH_EXERCISE_DATA,
      payload: filteredItem,
    });
  };
};
export const filterExerciseByMuscles = (filters = []) => {
  return (dispatch, getState) => {
    if (filters.length === 0) return;
    let filteredItem = [];
    if (filters.length === 0)
      filteredItem = (getState().exercise.searchedExercise || []).filter((e) =>
        e?.primary_muscle?.split(",").some((value) => filters.includes(value))
      );
    dispatch({
      type: SEARCH_EXERCISE_DATA,
      payload: filteredItem,
    });
  };
};

export const PAGINATION_EXERCISE_DATA = "pagination_exercise_data";
export const changePagination = (response = 1) => {
  return (dispatch, getState) => {
    dispatch({
      type: PAGINATION_EXERCISE_DATA,
      payload: response,
    });
  };
};

export const GET_EXERCISE_CURRENT = "get_exercise_current";
export const cleanupExercise = () => {
  return {
    type: GET_EXERCISE_CURRENT,
  };
};
export const getCurrentExercises = (id = null) => {
  return (dispatch) => {
    dispatch(loadingExercise(true));
    return axios
      .get(`/api/coach/exercises/${id}`)
      .then((res) => {
        dispatch(loadingExercise(false));
        dispatch({
          type: GET_EXERCISE_CURRENT,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(loadingExercise(false));
      });
  };
};

export const copyExercises = (id = null) => {
  return (dispatch) => {
    return axios
      .get(`api/coach/exercise/${id}/copy`)
      .then((res) => {
        dispatch(getExercises());
      })
      .catch((err) => {});
  };
};
