import jwt_decode from "jwt-decode";
import { SET_USER_DETAILS, SET_USER_PROFILE } from "store/actions/user";
import Cookie from "utils/cookie";
export default function clients(state = null, action) {
  if (state == null) {
    let user = {};
    const token = Cookie.get("jwt");
    if (token) {
      user = jwt_decode(token);
    }
    return {
      userDetails: user,
    };
  }
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      };
    default:
      return state;
  }
}
