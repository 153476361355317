export default function Container(theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginLeft: "24px",
          marginRight: "24px",
          width: "auto",
          "a:hover": {
            color: theme.palette.primary.main,
          },
        },
        maxWidthXxl: {
          padding: "30px 16px !important",
          backgroundColor: "#ffffff",
          borderRadius: "16px",
          "&.detail": {
            padding: "30px !important",
          },
          "&.header-box": {
            backgroundColor: "transparent",
            padding: "0px !important",
          },
          "&.no-bg": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  };
}
