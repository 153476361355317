import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setPrompt } from "store/actions/prompt";
import { isDynamicRouteMatch } from "utils/common";
import { ATTENTION_MESSAGE } from "utils/constants";
import Cookie from "utils/cookie";
import { adminConfig } from "../config";

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  position: "relative",
  textTransform: "capitalize",
  color: "#4F5668",
  fontWeight: 400,
  padding: 0,
  ".hover": {
    display: "none",
    padding: "7px 8px 3px 8px",
    borderRadius: "8px",
    backgroundColor: "#0001FC",
  },
  "&:hover, &.active": {
    fontWeight: 600,
    color: "#111111",
    background: "none",
    ".hover": {
      display: "block",
    },
    ".icon": {
      display: "none",
    },
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "32px",
  width: "32px",
  paddingRight: "15px",
});
// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItemLabel(props) {
  return (
    <ListItemText
      disableTypography
      primary={props.title}
      sx={{
        textTransform: "uppercase",
        padding: "10px 20px",
        color: "#7A7A7A",
        fontWeight: 600,
        mt: 3,
        mb: 1,
      }}
    />
  );
}
function NavItem({ item, active, role }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isActiveRoot = active(item.references);
  const { title, path, icon, info, children } = item;
  const userDetails = JSON.parse(Cookie.get("user"));
  const marketing_site = userDetails?.marketing_site || "";
  const showPromptModal = (nextUrl) => {
    if (isDynamicRouteMatch(location.pathname)) {
      dispatch(
        setPrompt({
          isOpen: true,
          message: ATTENTION_MESSAGE,
          primaryBtnHandler: () => {
            navigate(nextUrl);
          },
          btnAlign: "right",
          primaryBtnColor: "error",
          secondaryBtnText: "Annuler",
        })
      );
    } else {
      navigate(nextUrl);
    }
  };
  const activeRootStyle = {
    fontWeight: 600,
    color: "#111111",
  };

  if (item.headerLabel) {
    return <NavItemLabel title={item.headerLabel} />;
  }
  if (children) {
    return (
      <Box
        sx={{
          borderBottom: "1px solid #E0E4EA",
          pt: 3,
          pb: 1,
          "&:last-child": { borderBottom: "0 none" },
        }}
      >
        <Box
          sx={{
            fontSize: "12px",
            color: "#757E97",
            fontWeight: 600,
          }}
        >
          {title}
        </Box>
        <List component="div" disablePadding>
          {children.map((item, idx) => {
            const { title, path, references } = item;
            const isActiveSub = active(references);
            if (item.permissions?.includes(role)) {
              return (
                <ListItemStyle
                  className={isActiveSub ? "active" : ""}
                  sx={{
                    mb: 1,
                    mt: 1,
                    height: 30,
                    ...(isActiveSub && activeRootStyle),
                  }}
                  key={idx}
                  onClick={() => {
                    if (item.title === "Site web" && marketing_site) {
                      window.open(marketing_site, "_blank");
                    } else {
                      showPromptModal(path);
                      // navigate(path);
                    }
                  }}
                >
                  <ListItemIconStyle>
                    <Box className="icon">{item.icon}</Box>
                    <Box className="hover">{item.iconHover}</Box>
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            }
          })}
        </List>
      </Box>
    );
  }

  return (
    <Box sx={{ borderBottom: "1px solid #E0E4EA", paddingBottom: "10px" }}>
      <ListItemStyle
        component={Box}
        onClick={() => {
          showPromptModal(path ? path : "");
        }}
        sx={{
          height: "3rem",
          position: "relative",
          ...(isActiveRoot && activeRootStyle),
        }}
      >
        <ListItemIconStyle>
          <Box className="icon">{item.icon}</Box>
          <Box className="hover">{item.iconHover}</Box>
        </ListItemIconStyle>
        <ListItemText disableTypography primary={title} />
        {info && info}
      </ListItemStyle>
    </Box>
  );
}

const NavSection = ({ role }) => {
  const { pathname } = useLocation();
  const [accessFeatures, setAccessFeatures] = useState([
    "dashboard",
    "membres",
    "support",
  ]);
  useEffect(() => {
    const userDetails = JSON.parse(Cookie.get("user"));
    const features = userDetails?.subscription?.features || {};
    const trueKeysArray = Object.entries(features)
      .filter(([key, value]) => value === true)
      .map(([key]) => key);
    setAccessFeatures(_.uniq([...accessFeatures, ...trueKeysArray]));
  }, []);

  const match = (path) => (path ? !!path.includes(pathname) : false);
  return (
    <Box>
      <List disablePadding sx={{ p: 1, mt: 2, px: 3 }}>
        {adminConfig.map(
          (item, idx) =>
            item.permissions?.includes(role) &&
            accessFeatures.includes(item.featureName) && (
              <NavItem key={idx} item={item} role={role} active={match} />
            )
        )}
      </List>
    </Box>
  );
};

export default NavSection;
