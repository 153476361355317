import { Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import Field from "components/Field";
import { email } from "components/Field/validate";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ForgetPasswordPage = () => {
  const navigate = useNavigate();
  return (
    <Grid
      item
      lg={7}
      md={12}
      sx={{
        width: "100%",
        margin: "auto",
        display: "flex",
      }}
    >
      <Stack
        sx={{ width: "100%", gap: { xs: "30px", md: "70px" } }}
        display="flex"
        alignItems="center"
        gap="96px"
      >
        <Stack>
          <Typography
            component="span"
            sx={{
              color: "primary.main",
              fontWeight: 400,
              fontSize: "12px",
              cursor: "pointer",
            }}
          >
            {" "}
            <img
              src="/assets/images/evocoach-logo.png"
              alt="google"
              width="255"
            />
          </Typography>
        </Stack>
        <Stack>
          <Typography
            component="span"
            variant="h3"
            sx={{ mt: 3 }}
            fontWeight="700"
            fontSize="28px"
            textAlign="center"
          >
            Mot de passe oublié ?
          </Typography>
          <Typography
            component="span"
            variant="h3"
            sx={{ mt: 3 }}
            fontWeight="400"
            fontSize="14px"
            lineHeight="24px"
            textAlign="center"
          >
            Pas de problème ! Veuillez entrer l’adresse mail associée à votre
            compte, nous vous enverrons un lien sécurisé pour réinitialiser
            votre mot de passe.
          </Typography>
        </Stack>
        <Stack width="100%">
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              axios
                .post(`/api/auth/forgotpassword`, values)
                .then((res) => {
                  setSubmitting(false);
                  toast.success(
                    res?.data?.data?.message || "Password Reset Requested."
                  );
                  navigate("/login");
                })
                .catch((err) => {
                  setSubmitting(false);
                  toast.error(
                    err?.response?.data?.message || "Invalid Access."
                  );
                });
            }}
          >
            {(props) => {
              const { handleSubmit, isSubmitting } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container width="100%" spacing={1}>
                    <Grid item sm={10}>
                      <Field
                        type="email"
                        label="Email"
                        placeholder="Enter email here..."
                        name="email"
                        width="100%"
                        validate={email}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <Button
                        type="email"
                        variant="contained"
                        sx={{ minHeight: 40 }}
                        disabled={isSubmitting}
                      >
                        {" "}
                        submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
          <Typography
            component="span"
            textAlign="center"
            mt={4}
            sx={{
              fontWeight: 400,
              textDecoration: "underline",
              fontSize: "14px",
              cursor: "pointer",
              color: "#0001FC",
            }}
            onClick={() => navigate("/login")}
          >
            {" "}
            Retour
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default ForgetPasswordPage;
