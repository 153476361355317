import {
  Box,
  Button,
  Container,
  Divider,
  MenuItem,
  Select,
  Stack,
  styled,
} from "@mui/material";
import SessionForm from "components/CreateProgramPage/SessionForm";
import { FormContext } from "components/FormContext";
import ProgramSaveAsTemplateModal from "components/ProgramSaveAsTemplateModal";
import SendProgramModal from "components/SendProgramModal";
import TitleHeader from "components/TitleHeader";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setHeaderDetails } from "store/actions/header";
import { getInitialProgram } from "store/actions/program";
import { setPrompt } from "store/actions/prompt";
import { ATTENTION_MESSAGE } from "utils/constants";

const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
  color: "#4F5668",
  borderRadius: "8px",
  backgroundColor: "#ffffff",
  padding: "1px 12px",
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E0E4EA",
  },
}));
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 600,
  color: "#4F5668",
}));

const StyledSaveSelect = styled(Select)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
  color: "#119C83",
  borderRadius: "8px",
  verticalAlign: "middle",
  backgroundColor: "#E4F2E5",
  padding: "0px 12px",
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid #119C83",
  },
  ".MuiSelect-select": {
    color: "#119C83",
  },
}));
const StyledSaveMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "14px",
  color: "#111111",
  paddingTop: "10px",
  paddingBottom: "10px",
  "&:hover": {
    backgroundColor: "#E4F2E5",
  },
}));
const CreateSession = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { formikRef, handleSubmit } = useContext(FormContext);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [showSendProgramModal, setShowSendProgramModal] = useState(false);
  const [sessionDetails, setSessionDetails] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const session = formikRef?.current?.values?.week;
    setTimeout(() => {
      setSessionDetails(session ? session[0][0] : []);
    }, 500);
  }, [JSON.stringify(formikRef)]);
  useEffect(() => {
    dispatch(getInitialProgram());
    const navDetail = {
      isBreadcrumns: true,
      title: "",
      breadcrumsList: [
        {
          title: "Séances",
          link: "/fitness/sessions",
          command: () => {
            dispatch(
              setPrompt({
                isOpen: true,
                message: ATTENTION_MESSAGE,
                primaryBtnHandler: () => {
                  navigate("/fitness/sessions");
                },
                btnAlign: "right",
                primaryBtnColor: "error",
                secondaryBtnText: "Annuler",
              })
            );
          },
        },
        {
          title: "Création de séance",
          link: "",
        },
      ],
    };
    dispatch(setHeaderDetails(navDetail));
  }, []);
  const handleFitnessSaveActions = (event) => {
    if (event.target.value === "SAVE_AS_TEMPLATE") {
      setShowSaveTemplateModal(true);
    } else if (event.target.value === "SAVE_AS_DRAFT") {
      formikRef.current?.setFieldValue("user_id", "");
      formikRef.current?.setFieldValue("status", "Brouillon");
      handleSubmit();
    }
  };
  return (
    <>
      <Container maxWidth="xxl" className="no-bg">
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <TitleHeader title="Création de séance" />
          <Box>
            <StyledSaveSelect
              id="save"
              value={""}
              sx={{
                marginRight: "10px",
                display:
                  id &&
                  (sessionDetails?.status === "Actif" ||
                    sessionDetails?.status === "Modèle")
                    ? "none"
                    : "inline-block",
              }}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "Sauvegarder";
                }
              }}
              onChange={handleFitnessSaveActions}
            >
              <StyledSaveMenuItem value="SAVE_AS_TEMPLATE">
                En tant que modèle
              </StyledSaveMenuItem>
              <StyledSaveMenuItem value="SAVE_AS_DRAFT">
                En tant que brouillon
              </StyledSaveMenuItem>
            </StyledSaveSelect>
            {id && sessionDetails?.status === "Modèle" && (
              <Button
                type="submit"
                color="success"
                variant="outlined"
                sx={{ marginRight: "10px" }}
                onClick={() => setShowSaveTemplateModal(true)}
              >
                Sauvegarder
              </Button>
            )}
            <Button
              type="submit"
              color="success"
              variant="contained"
              onClick={() => {
                if (id && sessionDetails?.status === "Actif") {
                  handleSubmit();
                } else {
                  setShowSendProgramModal(true);
                }
              }}
            >
              {id && sessionDetails?.status
                ? "Sauvergarder les modifications"
                : "Envoyer"}
            </Button>
          </Box>
        </Stack>
        <Divider sx={{ margin: "20px 0" }} />
        <SessionForm isIndependentSession={true} />
      </Container>
      <ProgramSaveAsTemplateModal
        showSaveTemplateModal={showSaveTemplateModal}
        setShowSaveTemplateModal={setShowSaveTemplateModal}
        isIndependentSession={true}
        handleSubmit={() => {
          formikRef.current?.setFieldValue("status", "Modèle");
          handleSubmit();
        }}
      />
      <SendProgramModal
        showSendProgramModal={showSendProgramModal}
        isIndependentSession={true}
        setShowSendProgramModal={setShowSendProgramModal}
        handleDraftSubmit={() => {
          formikRef.current?.setFieldValue("status", "Brouillon");
          setShowSendProgramModal(false);
          handleSubmit();
        }}
      />
    </>
  );
};

export default CreateSession;
