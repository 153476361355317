import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal } from "@mui/material";
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  p: 3,
  pt: 3,
  borderRadius: "12px",
};
const closeIconStyle = {
  position: "absolute",
  right: "15px",
  top: "10px",
  width: "auto",
};
const titleStyle = {
  fontWeight: 700,
  fontSize: "28px",
  lineHeight: "42px",
};
const CustomModal = ({
  open,
  onCloseHandler,
  children,
  modalWidth = 400,
  title = "",
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...modalStyle, width: modalWidth }}>
        <Box
          component="h2"
          sx={titleStyle}
          mt={0}
          mb={4}
          id="parent-modal-title"
        >
          {title}
        </Box>
        <IconButton
          sx={{ ...closeIconStyle }}
          aria-label="close"
          onClick={onCloseHandler}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
