import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setPrompt } from "store/actions/prompt";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiTypography-root": {
    paddingBottom: 0,
  },
  "& .MuiPaper-root": {
    borderRadius: "12px",
  },
  "& .MuiDialogActions-root": {
    padding: "0 32px 32px",
  },
  "& .MuiDialogContent-root ": {
    padding: "32px",
    paddingTop: "10px",
  },
  "&  .MuiTypography-h6": {
    fontSize: "28px",
    color: "#111111",
    fontWeight: 700,
    padding: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          {" "}
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const PromptModal = (props) => {
  const prompt = useSelector((state) => state.prompt);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setPrompt({ ...prompt, isOpen: false }));
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      open={prompt?.isOpen}
      maxWidth={prompt?.maxWidth}
      fullWidth={true}
    >
      <BootstrapDialogTitle>{prompt?.title}</BootstrapDialogTitle>
      <DialogContent>{prompt?.message}</DialogContent>
      {prompt?.showActions && (
        <DialogActions
          sx={{ display: "flex", justifyContent: prompt?.btnAlign, gap: 2 }}
        >
          {!prompt?.hideSecondaryBtn && (
            <Button
              variant="outlined"
              color={
                prompt?.secondaryBtncolor
                  ? prompt?.secondaryBtncolor
                  : "secondary"
              }
              onClick={handleClose}
            >
              {" "}
              {prompt?.secondaryBtnText}
            </Button>
          )}
          {!prompt?.hidePrimaryBtn && (
            <Button
              variant="contained"
              color={
                prompt?.primaryBtnColor ? prompt?.primaryBtnColor : "primary"
              }
              onClick={() => {
                prompt?.primaryBtnHandler();
                handleClose();
              }}
            >
              {" "}
              {prompt?.primaryBtnText}
            </Button>
          )}
        </DialogActions>
      )}
    </BootstrapDialog>
  );
};

PromptModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  body: PropTypes.any,
  showActions: PropTypes.bool,
};

PromptModal.defaultProps = {
  showActions: true,
  maxWidth: "xs",
  confirm: "Retour",
};

export default PromptModal;
