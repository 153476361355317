import {
    GET_EXERCISE_CURRENT,
    GET_EXERCISE_DATA,
    LOADING_EXERCISE_DATA,
    PAGINATION_EXERCISE_DATA,
    SEARCH_EXERCISE_DATA,
} from "../actions/exercise";

export default function exercise(state = null, action) {
    if (state == null) {
        return {
            searchedExercise: [],
            exercises: null,
            currentPage: 1,
            totalItem: 0,
            isLoadingExercise: false,
            currentExercise: null,
        };
    }

    switch (action.type) {
        case GET_EXERCISE_CURRENT:
            return {
                ...state,
                currentExercise: action.payload,
            };
        case LOADING_EXERCISE_DATA:
            return {
                ...state,
                isLoadingExercise: action.payload,
            };
        case SEARCH_EXERCISE_DATA:
            return {
                ...state,
                currentPage: 1,
                exercises: action.payload,
            };
        case PAGINATION_EXERCISE_DATA:
            return {
                ...state,
                currentPage: action.payload,
            };
        case GET_EXERCISE_DATA:
            return {
                ...state,
                exercises: action.payload,
                searchedExercise: action.payload,
            };
        default:
            return state;
    }
}
