import { programs } from "utils/programs.utils";
import {
  CLEAN_WHOLE_PROGRAM,
  GET_INITIAL_PRGRAM,
  GET_PROGRAM_LISTS,
  IS_UPLOADING_FILE,
  PAGINATION_PROGRAM_DATA,
  SEARCH_PROGRAMS_DATA,
  SET_ACTIVE_DAY,
  SET_ACTIVE_WEEK,
  SET_CURRENT_COPIED_ITEM,
  SET_CURRENT_PROGRAM,
  SET_FORM_DATA,
  SET_LOADING_PROGRAMS,
  SET_PROGRAM_DATA,
  SET_SESSION_COUNT,
  SET_TOTAL_WEEK,
  TOGGLE_EXERCISE_MODEL,
  TOGGLE_SWAP_EXERCISE_MODEL,
} from "../actions/program";

export default function program(state = null, action) {
  if (state == null) {
    return {
      isOpenExerciseModel: false,
      isOpenSwapExerciseModel: false,
      isLoadingProgramms: false,
      programItem: {
        weeks: { 1: {} },
      },
      isLoadingFile: false,
      currentPage: 1,
      programms: null,
      searchedProgramms: null,
      programData: null,
      currentElment: null,
      activeDay: 0,
      activeWeek: 0,
      totalWeek: 1,
      formData: programs.initialValues,
      copiedSession: null,
      initialProgramValue: {},
      confirmationModal: false,
      sessionCount: 0,
    };
  }

  switch (action.type) {
    case GET_INITIAL_PRGRAM:
      return {
        ...state,
        initialProgramValue: action.payload,
      };
    case SET_CURRENT_COPIED_ITEM:
      return {
        ...state,
        copiedSession: action.payload,
      };
    case CLEAN_WHOLE_PROGRAM:
      return {
        ...state,
        programData: null,
        currentElment: null,
        programItem: {
          weeks: { 1: {} },
        },
        activeDay: 0,
        activeWeek: 0,
        totalWeek: 1,
        formData: programs.initialValues,
      };
    case SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case SET_TOTAL_WEEK:
      return {
        ...state,
        totalWeek: action.payload ? action.payload : state.totalWeek + 1,
      };
    case SET_ACTIVE_WEEK:
      return {
        ...state,
        activeWeek: action.payload,
      };
    case SET_ACTIVE_DAY:
      return {
        ...state,
        activeDay: action.payload,
      };
    case SET_PROGRAM_DATA:
      return {
        ...state,
        programData: action.payload,
      };
    case PAGINATION_PROGRAM_DATA:
      return {
        ...state,
        currentPage: action.payload,
      };
    case IS_UPLOADING_FILE:
      return {
        ...state,
        isLoadingFile: action.payload,
      };
    case SET_LOADING_PROGRAMS:
      return {
        ...state,
        isLoadingProgramms: action.payload,
      };
    case SEARCH_PROGRAMS_DATA:
      return {
        ...state,
        currentPage: 1,
        programms: action.payload,
      };
    case GET_PROGRAM_LISTS:
      return {
        ...state,
        programms: action.payload,
        searchedProgramms: action.payload,
      };
    case SET_CURRENT_PROGRAM:
      return {
        ...state,
        programItem: action.payload,
      };
    case TOGGLE_SWAP_EXERCISE_MODEL:
      return {
        ...state,
        isOpenSwapExerciseModel: !state.isOpenSwapExerciseModel,
      };
    case TOGGLE_EXERCISE_MODEL:
      return {
        ...state,
        isOpenExerciseModel: !state.isOpenExerciseModel,
        currentElment: action.payload,
      };
    case SET_SESSION_COUNT:
      return {
        ...state,
        sessionCount: action.payload,
      };
    default:
      return state;
  }
}
