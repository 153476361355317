import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveDay } from "store/actions/nutritions";

const CreateNutritionDays = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState([]);
  const activeDay = useSelector((state) => state.nutritions.activeDay);
  const nutritionItem = useSelector((state) => state.nutritions.nutritionItem);
  const activeWeek = useSelector((state) => state.nutritions.activeWeek);

  const toggleButton = (response) => {
    dispatch(setActiveDay(response));
  };

  useEffect(() => {
    if (nutritionItem?.weeks) {
      if (nutritionItem["weeks"][activeWeek + 1]) {
        setValues(Object.keys(nutritionItem["weeks"][activeWeek + 1] || {}));
      } else {
        let program = nutritionItem["weeks"];
        program[activeWeek + 1] = {};
        setValues(Object.keys(program[activeWeek + 1] || {}));
      }
    }
  }, [nutritionItem, activeWeek, activeDay]);

  return (
    <Stack my={2}>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap="8px"
        justifyContent="space-between"
        mt={2}
      >
        {[1, 2, 3, 4, 5, 6, 7].map((ele, index) => (
          <Button
            color={
              activeDay === index || values.includes(String(ele))
                ? "primary"
                : "info"
            }
            key={index}
            variant={
              activeDay === index || !values.includes(String(ele))
                ? "contained"
                : "outlined"
            }
            onClick={() => toggleButton(index)}
            sx={{
              flex: 1,
              borderRadius: "16px",
              padding: "8px 16px",
              boxShadow: "none",
              minHeight: "64px",
              textTransform: "capitalize",
              fontSize: "12px",
              "&.MuiButton-outlinedPrimary": {
                backgroundColor: "#ffffff",
                border: "0 none",
                color: "#0001FC",
              },
              "&.MuiButton-containedInfo": {
                backgroundColor: "transparent",
                border: "1px dashed #0001FC",
                color: "#0001FC",
              },
            }}
          >
            {" "}
            Jour {ele}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};

export default CreateNutritionDays;
