import { Grid, styled } from "@mui/material";

const Header = styled(Grid)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  color: "#757E97",
}));

const SessionListHeader = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "6px 16px",
      }}
    >
      <Header
        item
        xs={4}
        sx={{ display: "flex", gap: "10px", alignItems: "center" }}
      >
        Titre
      </Header>
      <Header item md={4}>
        Client
      </Header>
      <Header item md={2}>
        Statut
      </Header>
      <Header item md={2} sx={{ textAlign: "right" }}>
        Actions
      </Header>
    </Grid>
  );
};

export default SessionListHeader;
