import { Stack, Typography } from "@mui/material";

const TitleHeader = ({
  title,
  mb = 0,
  mt = 0,
  fs = 28,
  fw = 700,
  el = "h1",
  sx = {},
  color = "#111111",
  children,
}) => {
  return (
    <Stack>
      <Typography
        component={el}
        display="flex"
        alignItems="center"
        color={color}
        fontSize={fs}
        fontWeight={fw}
        mb={mb}
        mt={mt}
        sx={sx}
      >
        {title}
        {children}
      </Typography>
    </Stack>
  );
};

export default TitleHeader;
