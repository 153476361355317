import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import CustomModal from "components/CustomModal";
import Field from "components/Field";
import { required } from "components/Field/validate";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import {
  inviteCoachHandler,
  updateCoachDetailsHandler,
} from "store/actions/coaches";
const InviteCoach = ({
  title = "Invite Coach",
  showModal = false,
  toggleInviteCoachModal,
  onDeleteHandler = () => {},
  coachDetails,
}) => {
  const dispatch = useDispatch();
  const closeModalHandler = () => {
    toggleInviteCoachModal();
  };
  return (
    <CustomModal
      open={showModal}
      onCloseHandler={closeModalHandler}
      modalWidth="636"
      title={title}
    >
      <Formik
        initialValues={{
          first_name: coachDetails?.first_name || "",
          last_name: coachDetails?.last_name || "",
          email: coachDetails?.email || "",
          phone: coachDetails?.phone || "",
          role: coachDetails?.role || "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (coachDetails?._id) {
            dispatch(
              updateCoachDetailsHandler(
                values,
                coachDetails?._id,
                toggleInviteCoachModal
              )
            );
          } else {
            dispatch(inviteCoachHandler(values, toggleInviteCoachModal));
          }
        }}
      >
        {(props) => {
          const { handleSubmit, isSubmitting } = props;
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Field
                    type="text"
                    label="Prénom"
                    placeholder="Prénom"
                    name="first_name"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Field
                    type="text"
                    label="Nom"
                    placeholder="Nom"
                    name="last_name"
                  />
                </Grid>
                <Grid item sm={12}>
                  <Field
                    type="text"
                    label="Adresse e-mail *"
                    placeholder="Adresse e-mail *"
                    name="email"
                    validate={required}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Field
                    type="text"
                    label="Numéro de téléphone"
                    placeholder="Numéro de téléphone"
                    name="phone"
                  />
                </Grid>
              </Grid>
              {coachDetails && (
                <>
                  <Divider sx={{ margin: "10px 0 20px 0" }} />
                  <Box mb={3} sx={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      disabled={isSubmitting}
                      color="error"
                      onClick={() => {
                        closeModalHandler();
                        onDeleteHandler(coachDetails);
                      }}
                      endIcon={
                        <CustomIcon iconSrc="/assets/logo/trash_white.svg" />
                      }
                    >
                      Supprimer l’entraîneur
                    </Button>
                  </Box>
                </>
              )}

              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                mt={2}
              >
                <Button
                  sx={{ width: "100%" }}
                  color="secondary"
                  variant="outlined"
                  disabled={isSubmitting}
                  onClick={() =>
                    closeModalHandler(coachDetails._id, toggleInviteCoachModal)
                  }
                >
                  Fermer
                </Button>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {" "}
                  Inviter
                </Button>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default InviteCoach;
