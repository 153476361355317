import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import CardImageUploader from "components/CardImageUploader";
import ConfirmationModel from "components/ConfirmationModel";
import CustomIcon from "components/CustomIcon";
import Field from "components/Field";
import TitleHeader from "components/TitleHeader";
import { Formik, connect } from "formik";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getExercises } from "store/actions/exercise";
import {
  copySessionData,
  createNewProgram,
  deleteSession,
  getSessionList,
  pasteSessionData,
  setSessionCount,
  updateProgram,
  validateSession,
} from "store/actions/program";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";
import { programs } from "utils/programs.utils";
import { required } from "../../Field/validate";
import { FormContext } from "../../FormContext";
import SeanceExercise from "../SeanceExercise";

const SessionForm = ({
  formik,
  isDeleteSessionDisabled,
  isIndependentSession = false,
}) => {
  const { formikRef } = useContext(FormContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeWeek = useSelector((state) => state.programs.activeWeek);
  const activeDay = useSelector((state) => state.programs.activeDay);
  const programItem = useSelector((state) => state.programs.programItem);
  const sessionCount = useSelector((state) => state.programs.sessionCount);
  const copiedSession = useSelector((state) => state.programs.copiedSession);
  const [totalWeeks, setTotalWeeks] = useState({});
  const [formData, setFormData] = useState();
  const [sessionStatus, setSessionStatus] = useState(false);
  const initialProgramValue = useSelector(
    (state) => state.programs.initialProgramValue
  );
  const programData = useSelector((state) => state.programs.programData);
  const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false);

  const [open, setOpen] = useState([0, 1, 2, 3, 4, 5, 6]);

  useEffect(() => {
    if (formikRef && !isIndependentSession) {
      setTimeout(() => {
        setSessionStatus(
          formikRef?.current?.values?.week[activeWeek][activeDay]?.isCompleted
        );
      }, 700);
    }
  }, [JSON.stringify(formikRef), activeDay, activeWeek]);
  useEffect(() => {
    if (_.isEmpty(programData) && !isIndependentSession) {
      navigate(id ? `/fitness/programs/${id}` : "/fitness/programs/create");
    }
    if (id && isIndependentSession) {
      const sessionObj = { 1: { 1: { session: id } } };
      dispatch(getSessionList(sessionObj, formikRef));
    } else if (isIndependentSession) {
      formikRef.current.setValues(initialProgramValue);
      if (Object.keys(formikRef.current.values).length === 0) {
        navigate(id ? `/fitness/sessions/${id}` : "/fitness/sessions/create");
      }
      dispatch(getExercises());
    }
  }, []);

  useEffect(() => {
    if (sessionCount > 0 && sessionCount === objKeyCount(totalWeeks)) {
      let data = programData;
      data.weeks = programs.convertKeys(programItem.weeks);
      data.thumbnail = data.thumbnail
        ? data.thumbnail
        : THUMBNAIL_PLACEHOLDER_GRAY;
      if (id) {
        dispatch(updateProgram(id, data));
      } else {
        dispatch(createNewProgram(data));
      }
    }
  }, [sessionCount]);

  const whereTo = `week.${activeWeek}.[${activeDay}]`;
  const objKeyCount = (object) => {
    let count = 0;
    _.forEach(object, async (value, key) => {
      count = count + Object.keys(value).length;
    });
    return count;
  };

  const handleSaveSingleSession = (values) => {
    const formVal = id
      ? { ...values, weeks: { 1: { 1: { session: id } } } }
      : values;
    dispatch(
      validateSession(formVal, 0, 0, formikRef, null, isIndependentSession)
    );
  };

  const toggle = (day) => {
    if (open.includes(day)) {
      const index = open.indexOf(day);
      const data = open;
      data.splice(index, 1);
      setOpen([...data]);
    } else {
      setOpen((prev) => [...prev, day]);
    }
  };

  const toggleDeleteModel = (ele = "") => {
    setShowDeleteSessionModal(!showDeleteSessionModal);
  };

  const handleSaveSessions = async (values) => {
    const weeksDummyObj = {};
    dispatch(setSessionCount(0));
    await _.forEach(values.week, async (value, key) => {
      await _.forEach(value, async (session, inx) => {
        if (session.name) {
          weeksDummyObj[parseInt(key) + 1] = {
            ...weeksDummyObj[parseInt(key) + 1],
            [inx + 1]: 1,
          };
          setTotalWeeks(weeksDummyObj);
          dispatch(
            validateSession(
              session,
              parseInt(key),
              inx,
              formikRef,
              values.weeks
            )
          );
        }
      });
    });
  };
  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialProgramValue}
        onSubmit={(values) => {
          if (isIndependentSession) {
            handleSaveSingleSession(values);
          } else {
            handleSaveSessions(values);
          }
        }}
      >
        {(props) => {
          const { handleSubmit, values } = props;
          return (
            <>
              <form onSubmit={handleSubmit}>
                <Box
                  py={2}
                  px={3}
                  sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      component="h2"
                      fontSize="20px"
                      fontWeight="600"
                      fontFamily="Poppins"
                      display="flex"
                      alignItems="center"
                      gap="10px"
                      pr={2}
                    >
                      <IconButton onClick={() => toggle(activeDay)}>
                        {(open || []).includes(activeDay) ? (
                          <ExpandMore />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </IconButton>
                      Présentation de la séance
                    </Typography>
                    {!isIndependentSession && (
                      <Stack direction="row" alignItems="center">
                        <IconButton
                          color="inherit"
                          size="small"
                          sx={{ marginRight: "5px" }}
                          onClick={() => dispatch(copySessionData(formikRef))}
                        >
                          <CustomIcon
                            title="Copy Program"
                            iconSrc="/assets/logo/copy_big.svg"
                          />
                        </IconButton>
                        {copiedSession && (
                          <IconButton
                            color="inherit"
                            size="small"
                            onClick={() =>
                              dispatch(pasteSessionData(formikRef))
                            }
                          >
                            <CustomIcon
                              title="Past Program"
                              iconSrc="/assets/logo/past.svg"
                            />
                          </IconButton>
                        )}
                        <IconButton
                          color="inherit"
                          size="small"
                          sx={{ marginRight: "5px" }}
                          disabled={isDeleteSessionDisabled}
                          onClick={() => toggleDeleteModel()}
                        >
                          <CustomIcon
                            title="Delete Program"
                            iconSrc="/assets/logo/trash_big.svg"
                          />
                        </IconButton>
                      </Stack>
                    )}
                  </Stack>
                  <Collapse
                    in={open.includes(activeDay)}
                    timeout="auto"
                    sx={{ width: "100%", mt: 3 }}
                    unmountOnExit
                  >
                    <Grid container spacing={5}>
                      <Grid item sm={6}>
                        <Box pr={5} sx={{ borderRight: "1px solid #E0E4EA" }}>
                          <Field
                            type="text"
                            label="Titre de la séance"
                            placeholder="Titre de la séance"
                            name={whereTo + `.name`}
                            size="small"
                            validate={required}
                            disabled={sessionStatus}
                            mb={4}
                          />
                          <Grid container spacing={2}>
                            <Grid item sm={6}>
                              <Field
                                type="number"
                                label="Durée"
                                placeholder="Durée"
                                name={whereTo + `.duration`}
                                size="small"
                                disabled={sessionStatus}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      min
                                    </InputAdornment>
                                  ),
                                }}
                                mb={4}
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <Field
                                type="number"
                                label="Calories"
                                placeholder="Calories"
                                name={whereTo + `.calories`}
                                size="small"
                                disabled={sessionStatus}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      Kcal
                                    </InputAdornment>
                                  ),
                                }}
                                mb={4}
                              />
                            </Grid>
                          </Grid>
                          <Field
                            type="multiselect"
                            notched={true}
                            label="Equipements"
                            placeholder="Equipements"
                            name={whereTo + `.equipments`}
                            size="small"
                            disabled={sessionStatus}
                            options={programs.equipments}
                          />
                        </Box>
                      </Grid>
                      <Grid item sm={6}>
                        <TitleHeader
                          el="h3"
                          mb={2}
                          title="Affiche de la séance"
                          fw={600}
                          fs={12}
                        />
                        {values["week"] &&
                          values["week"][activeWeek] &&
                          values["week"][activeWeek][activeDay] && (
                            <CardImageUploader
                              name={whereTo + `.thumbnail`}
                              label="Ajouter une affiche"
                              view="landscape"
                              libraryApi="/api/coach/session/image/library"
                              apiKey="/api/upload/coach/thumbs/exercise/new_thumb"
                              thumbnail={
                                values["week"][activeWeek][activeDay]?.thumbnail
                              }
                            />
                          )}
                      </Grid>
                    </Grid>
                  </Collapse>
                </Box>
                <Box>
                  <SeanceExercise />
                </Box>
              </form>
            </>
          );
        }}
      </Formik>
      <ConfirmationModel
        isOpen={showDeleteSessionModal}
        title="Supprimer l’session"
        body="Vous êtes sur le point de supprimer un client. Cela le supprimera définitivement de votre bibliothèque."
        handleClose={toggleDeleteModel}
        onConfirm={() => dispatch(deleteSession(formikRef, toggleDeleteModel))}
      />
    </>
  );
};

export default connect(SessionForm);
