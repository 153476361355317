export const muscle = [
  { id: 1, name: "Pectoraux" },
  { id: 2, name: "Épaules" },
  { id: 3, name: "Deltoïdes antérieurs" },
  { id: 4, name: "Deltoïdes postérieurs" },
  { id: 5, name: "Deltoïdes latéraux" },
  { id: 6, name: "Trapèzes" },
  { id: 7, name: "Dorsaux" },
  { id: 8, name: "Lombaires" },
  { id: 9, name: "Quadriceps" },
  { id: 10, name: "Ischio-jambiers" },
  { id: 11, name: "Mollets" },
  { id: 12, name: "Fessiers" },
  { id: 13, name: "Adducteurs" },
  { id: 14, name: "Abducteurs" },
  { id: 15, name: "Biceps" },
  { id: 16, name: "Triceps" },
  { id: 17, name: "Avant-bras" },
  { id: 18, name: "Abdominaux" },
  { id: 19, name: "Cardio" },
  { id: 20, name: "Haut du corps" },
  { id: 21, name: "Bas du corps" },
  { id: 22, name: "Jambes" },
  { id: 23, name: "Bras" },
  { id: 24, name: "Buste" },
  { id: 25, name: "Poitrine" },
  { id: 26, name: "Corps en entier" },
  { id: 27, name: "Dos" },
  { id: 28, name: "Fesses" },
];

export const explorerCategory = [
  { id: 1, name: "Nouveautés" },
  { id: 2, name: "Top Evodream" },
  { id: 3, name: "Cibler une zone" },
  { id: 4, name: "Perdre du poids" },
  { id: 5, name: "Se tonifier" },
  { id: 6, name: "Gagner du muscle" },
  { id: 7, name: "Être en forme" },
  { id: 8, name: "Améliorer son cardio" },
];

export const objectFit = [
  { id: 1, name: "Perdre du poids" },
  { id: 2, name: "Renforcement musculaire" },
  { id: 3, name: "Prise de masse musculaire" },
  { id: 4, name: "Rester en forme" },
];

export const sexeOptions = [
  { id: 1, name: "Femme" },
  { id: 2, name: "Homme" },
];

export const PrimaryOption = [
  { id: 7, name: "Upper Body", disabled: false },
  { id: 8, name: "Lower Body", disabled: false },
  { id: 9, name: "Full Body", disabled: false },
  { id: 1, name: "Poitrine", disabled: false },
  { id: 2, name: "Dos", disabled: false },
  { id: 3, name: "Épaules", disabled: false },
  { id: 4, name: "Bras", disabled: false },
  { id: 5, name: "Abdominaux", disabled: false },
  { id: 6, name: "Jambes", disabled: false },
];

export const secondaryOption = [
  { id: 7, name: "Upper Body", disabled: false },
  { id: 8, name: "Lower Body", disabled: false },
  { id: 9, name: "Full Body", disabled: false },
  { id: 1, name: "Poitrine", disabled: false },
  { id: 2, name: "Dos", disabled: false },
  { id: 3, name: "Fesses", disabled: false },
  { id: 4, name: "Bras", disabled: false },
  { id: 5, name: "Abdominaux", disabled: false },
  { id: 6, name: "Jambes", disabled: false },
];

export const bmrValue = [
  "1000-1200",
  "1201-1400",
  "1401-1600",
  "1601-1800",
  "1801-2000",
  "2001-2200",
  "2201-2400",
  "2401-2600",
  "2601-2800",
  "2801-3000",
  "3001-3200",
  "3201-3400",
  "3401-3600",
  "3601-3800",
  "3801-4000",
];
