export default function Pagination(theme) {
  return {
    MuiPagination: {
      styleOverrides: {
        root: {
          ".MuiPaginationItem-root": {
            background: "#ffffff",
            border: "1px solid #E0E4EA",
            padding: 1,
            borderRadius: 6,
            color: "#757E97",
            fontSize: "12px",
            fontWeight: "600",
            minHeight: "32px",
            "&.Mui-selected, &:hover": {
              border: "1px solid #0001FC",
              background: "#ffffff",
              color: "#0001FC",
            },
          },
          ".MuiPaginationItem-previousNext": {
            color: "#0001FC",
            "&.Mui-disabled": {
              color: "#757E97",
            },
          },
        },
      },
    },
  };
}
