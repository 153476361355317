import { Alert, IconButton, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomIcon from "components/CustomIcon";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { copyExercises } from "store/actions/exercise";
import { THUMBNAIL_PLACEHOLDER } from "utils/constants";

const StyledAlert = styled(Alert)(() => ({
  cursor: "pointer",
  border: "1px solid #E0E4EA",
  borderRadius: "8px",
  marginBottom: "8px",
  paddingTop: "0px",
  paddingBottom: "0px",
  "&:last-child": {
    marginBottom: "0px",
  },
  ".MuiAlert-icon": {
    display: "none",
  },
  ".MuiAlert-action": {
    alignItems: "center",
  },
  ".MuiAlert-message": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    color: "#4F5668",
    fontSize: "14px",
  },
}));

const useStyle = makeStyles(() => ({
  img: {
    height: "40px",
    width: "40px",
    borderRadius: "8px",
  },
}));

const ExcerciceItem = ({ toggleDeleteModel, item }) => {
  const naviagate = useNavigate();
  const userDetails = useSelector((state) => state.user.userDetails);
  const classes = useStyle();
  const dispatch = useDispatch();
  return (
    <StyledAlert
      variant="outlined"
      severity="info"
      action={
        <>
          <IconButton color="inherit" size="small">
            <CustomIcon
              iconSrc="/assets/logo/detail_icon.svg"
              iconHoverSrc="/assets/logo/detail_icon_hover.svg"
            />
          </IconButton>
          {process.env.REACT_APP_EVOCOACH_USER_ID === userDetails.user_id ||
            (item.created_by !== process.env.REACT_APP_EVOCOACH_USER_ID && (
              <>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(copyExercises(item._id));
                  }}
                >
                  <CustomIcon
                    iconSrc="/assets/logo/copy.svg"
                    iconHoverSrc="/assets/logo/copy_hover.svg"
                  />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDeleteModel(item);
                  }}
                >
                  <CustomIcon
                    iconSrc="/assets/logo/trash_icon.svg"
                    iconHoverSrc="/assets/logo/trash_icon_hover.svg"
                  />
                </IconButton>
              </>
            ))}
        </>
      }
      onClick={() => naviagate(`/fitness/exercices/${item._id}`)}
    >
      <img
        src={item.thumbnail}
        alt="profile"
        className={classes.img}
        onError={(event) => {
          event.target.src = THUMBNAIL_PLACEHOLDER;
          event.onerror = null;
        }}
        style={{ objectFit: "cover", borderRadius: "10px" }}
      />
      {item.name}
    </StyledAlert>
  );
};

export default ExcerciceItem;
