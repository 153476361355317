import { Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import Field from "components/Field";
import { email, required } from "components/Field/validate";
import { Formik } from "formik";
import { sha512 } from "js-sha512";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const SignupForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const [queryEmail, setQueryEmail] = useState("");
  useEffect(() => {
    const query = location.search;
    const matches = query.match(/email=([^&]*)/);
    setQueryEmail(matches ? matches[1] : "");
  }, []);

  const createUser = (data, resetForm) => {
    const apiUrl =
      params.get("role") && params.get("role") === "owner"
        ? "/api/owner/account_setup"
        : "/api/coach/account_setup";
    axios
      .post(apiUrl, data)
      .then((res) => {
        resetForm();
        navigate("/login");
      })
      .catch((err) => {
        resetForm();
        toast.error(err?.response?.data?.message || "Invalid Access.");
      });
  };
  return (
    <Grid
      item
      lg={7}
      md={12}
      sx={{
        width: "100%",
        margin: "auto",
        display: "flex",
      }}
    >
      <Stack
        sx={{ width: "100%", gap: { xs: "30px", md: "50px" } }}
        display="flex"
        alignItems="center"
        gap="96px"
      >
        <Stack>
          <Typography
            component="span"
            sx={{
              color: "primary.main",
              fontWeight: 400,
              fontSize: "12px",
              cursor: "pointer",
            }}
          >
            {" "}
            <img
              src="/assets/images/evocoach-logo.png"
              alt="Evocoach"
              width="155"
            />
          </Typography>
        </Stack>
        <Stack>
          <Typography
            component="span"
            variant="h3"
            sx={{ mt: 3 }}
            fontWeight="700"
            fontSize="28px"
            textAlign="center"
          >
            {" "}
            Bienvenue !
          </Typography>
          <Typography
            component="span"
            variant="h3"
            sx={{ mt: 3 }}
            fontWeight="400"
            fontSize="14px"
            textAlign="center"
          >
            Veuillez entrer votre adresse mail et votre mot de passe pour vous
            connecter
          </Typography>
        </Stack>
        <Stack width="100%">
          <Formik
            enableReinitialize={true}
            initialValues={{
              email: queryEmail,
              first_name: "",
              last_name: "",
              password: "",
              org_name: "",
            }}
            onSubmit={(values, { resetForm }) => {
              const data = {
                ...values,
              };
              data.password = sha512(values.password);
              createUser(data, resetForm);
            }}
          >
            {(props) => {
              const { handleSubmit, isSubmitting, setFieldValue } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    type="text"
                    label="Email"
                    placeholder="Email"
                    name="email"
                    mb={3}
                    validate={email}
                    disabled={queryEmail}
                  />
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <Field
                        type="text"
                        label="Nom"
                        placeholder="Nom"
                        name="first_name"
                        mb={3}
                        validate={required}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Field
                        type="text"
                        label="Prénom"
                        placeholder="Prénom"
                        name="last_name"
                        mb={3}
                        validate={required}
                      />
                    </Grid>
                  </Grid>
                  {params.get("role") && params.get("role") === "owner" && (
                    <Field
                      type="text"
                      label="Nom de société"
                      placeholder="Nom de société"
                      name="org_name"
                      mb={3}
                    />
                  )}
                  <Field
                    type="password"
                    label="Password"
                    placeholder="Password"
                    name="password"
                    mb={3}
                    validate={required}
                  />

                  <Stack width="100%" mb={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      Continuer
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default SignupForm;
