import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Link,
  Stack,
  styled,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import CustomIcon from "components/CustomIcon";
import Field from "components/Field";
import TitleHeader from "components/TitleHeader";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createClientEvolution,
  deleteClientEvolution,
  editClientEvolution,
  getClientEvolutions,
} from "store/actions/clients";
import { uploadFile } from "store/actions/program";
import { formatDate } from "utils/common";

const StyledEvolutionBox = styled(Box)(() => ({
  border: "1px solid #E0E4EA",
  padding: "16px",
  borderRadius: "16px",
  marginBottom: "16px",
  "&:last-child": {
    marginBottom: "0px",
  },
}));
const NoEvolutionBox = styled(Box)(({ theme }) => ({
  border: "1px solid #E0E4EA",
  borderRadius: "8px",
  padding: "10px",
  textAlign: "center",
  fontSize: "14px",
  color: "#4F5668",
  backgroundColor: "#ffffff",
}));
const StyleUploadBox = styled(Box)(() => ({
  border: "1px dashed #757E97",
  padding: "35px 16px 16px",
  borderRadius: "16px",
  backgroundColor: "#f5f5f5",
  textAlign: "center",
  minHeight: "180px",
  ".MuiStack-root": {
    margin: 0,
  },
  input: {
    width: "100%",
    padding: "0px",
    height: "0",
    margin: 0,
  },
  a: {
    fontSize: "14px",
    display: "block",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "140px",
    overflow: "hidden",
  },
}));
const StyledBodyDataBox = styled(Box)(() => ({
  border: "1px solid #E0E4EA",
  padding: "8px 16px",
  borderRadius: "8px",
  minHeight: "66px",
  ".name": {
    fontSize: "12px",
    fontWeight: "600",
    color: "#4F5668",
  },
  ".value": {
    fontSize: "14px",
    "&.weight": {
      fontSize: "20px",
      fontWeight: "600",
      lineHeight: "20px",
    },
    span: {
      fontSize: "12px",
      color: "#757E97",
      fontWeight: "400",
    },
  },
  "&.mgrmnt-bg": {
    backgroundColor: "#f5f5f5",
    border: "0 none",
  },
}));

const ClientEvolutions = () => {
  const [isValidFile, setIsValidFile] = useState(true);
  const isLoadingFile = useSelector((state) => state.programs.isLoadingFile);
  const [showAddMeasurementsModal, setShowAddMeasurementsModal] =
    useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const clientEvolutions = useSelector(
    (state) => state.clients.clientEvolutions
  );
  const formRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showDeleteModel, setShowDeleteModel] = useState();
  const [selectedEvolution, setSelectedEvolutions] = useState();

  const toggleDeleteModel = () => {
    setShowDeleteModel(!showDeleteModel);
  };
  useEffect(() => {
    if (clientEvolutions?.length === 0) {
      dispatch(getClientEvolutions(params?.id));
    }
  }, []);
  const toggleMeasurementsModal = () => {
    setShowAddMeasurementsModal(!showAddMeasurementsModal);
  };
  const validateUploadFile = (file) => {
    const fileSize = file?.size / (1024 * 1024);
    let validFile = true;
    const allowFileTypes = ["image/jpeg", "image/png"];
    if (!allowFileTypes.includes(file.type) || fileSize > 30) {
      validFile = false;
    }
    setIsValidFile(validFile);
    return validFile;
  };
  const uploadBox = (index) => {
    return (
      <StyleUploadBox
        fInx={index}
        onClick={(e) => {
          document.querySelector(`#file_uploader_${index}`).click();
        }}
      >
        <>
          <Field
            type="file"
            name={`file_${index}`}
            dataFile={index}
            accept="image/png, image/jpeg"
            style={{ visibility: "hidden" }}
            id={`file_uploader_${index}`}
            multiple={false}
            onChange={(e) => {
              if (validateUploadFile(e.target.files[0])) {
                return dispatch(
                  uploadFile(
                    e.target.files[0],
                    "/api/upload/coach/thumbs/exercise/new_thumb"
                  )
                ).then((res) => {
                  let files = selectedFiles;
                  const fileIndex = e.target.name.split("_")[1];
                  files[fileIndex] = {
                    name: e.target.files[0].name,
                    file: e.target.files[0],
                    url: res,
                  };
                  setSelectedFiles([...files]);
                });
              }
            }}
          />
          <CustomIcon iconSrc="/assets/images/camera.png" />
          {selectedEvolution?.photo?.length > 0 ? (
            <Link href="#" mb={1} color="primary">
              {selectedEvolution?.photo[index]
                ? selectedEvolution?.photo[index].split("/")[
                    selectedEvolution?.photo[index].split("/").length - 1
                  ]
                : "Ajouter une photo"}
            </Link>
          ) : (
            <Link href="#" mb={1} color="primary">
              {selectedFiles[index]?.name
                ? selectedFiles[index]?.name
                : "Ajouter une photo"}
            </Link>
          )}
          <TitleHeader
            fs={12}
            fw={400}
            mt={1}
            title="Format JPEG ou PNG, fichier d’une taille maximale de 5Mo."
            color={isValidFile ? "#757E97" : "#B72136"}
          />
        </>
      </StyleUploadBox>
    );
  };
  return (
    <>
      <Box py={3}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          mb={4}
        >
          <TitleHeader title="Évolutions" fs={20} fw={600} />
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setSelectedEvolutions([]);
              setSelectedFiles([]);
              setShowAddMeasurementsModal(true);
            }}
          >
            Ajouter une évolution
          </Button>
        </Stack>
        {clientEvolutions?.length === 0 && (
          <NoEvolutionBox>Aucune évolution ajoutée.</NoEvolutionBox>
        )}
        {clientEvolutions?.map((item) => (
          <StyledEvolutionBox key={item._id}>
            <Grid container>
              <Grid
                item
                sm={11}
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                {formatDate(item.created_on)}
              </Grid>
              <Grid
                item
                sm={1}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <IconButton color="inherit" size="small">
                  <Box
                    component="img"
                    src="/assets/logo/pencile_gray.svg"
                    alt="Edit Notes"
                    onClick={(e) => {
                      setShowAddMeasurementsModal(true);
                      setSelectedEvolutions(item);
                    }}
                  ></Box>
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => {
                    toggleDeleteModel();
                    setSelectedEvolutions(item);
                  }}
                >
                  <Box
                    component="img"
                    src="/assets/logo/trash_icon.svg"
                    alt="Exercise delete"
                  ></Box>
                </IconButton>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 2 }} />
            <Grid container spacing={4}>
              <Grid item sm={6}>
                <ImageList cols={3} sx={{ marginBottom: 0 }} gap={8}>
                  <ImageListItem sx={{ img: { borderRadius: "12px" } }} key={1}>
                    <img
                      src={
                        item.photo[0] ||
                        `/assets/images/no_evolution_img.jpg?w=192&h=240&fit=crop&auto=format`
                      }
                      alt=""
                      loading="lazy"
                    />
                  </ImageListItem>
                  <ImageListItem sx={{ img: { borderRadius: "12px" } }} key={1}>
                    <img
                      src={
                        item.photo[1] ||
                        `/assets/images/no_evolution_img.jpg?w=192&h=240&fit=crop&auto=format`
                      }
                      alt=""
                      loading="lazy"
                    />
                  </ImageListItem>
                  <ImageListItem sx={{ img: { borderRadius: "12px" } }} key={1}>
                    <img
                      src={
                        item.photo[2] ||
                        `/assets/images/no_evolution_img.jpg?w=192&h=240&fit=crop&auto=format`
                      }
                      alt=""
                      loading="lazy"
                    />
                  </ImageListItem>
                </ImageList>
              </Grid>
              <Grid item sm={6}>
                <Box pt={3} pl={1}>
                  <TitleHeader
                    title="Données corporelles"
                    fs={12}
                    fw={600}
                    mb={1}
                    color="#4F5668"
                  />
                  <Grid container>
                    <Grid item sm={3}>
                      <StyledBodyDataBox>
                        <Box className="name" mb={1}>
                          Poids
                        </Box>
                        <Box className="value weight">
                          {item.weight || "-"}
                          <Box component="span"> kg</Box>
                        </Box>
                      </StyledBodyDataBox>
                    </Grid>
                    <Grid item sm={1}></Grid>
                    <Grid item sm={8}>
                      <StyledBodyDataBox>
                        <Grid container spacing={2}>
                          <Grid item sm={4}>
                            <Box className="name" mb={1}>
                              IMC
                            </Box>
                            <Box className="value">{item.imc || "-"}</Box>
                          </Grid>
                          <Grid item sm={4}>
                            <Box className="name" mb={1}>
                              % Muscle
                            </Box>
                            <Box className="value">{item.muscle || "-"}</Box>
                          </Grid>
                          <Grid item sm={4}>
                            <Box className="name" mb={1}>
                              % Graisse
                            </Box>
                            <Box className="value">{item.fat || "-"}</Box>
                          </Grid>
                        </Grid>
                      </StyledBodyDataBox>
                    </Grid>
                  </Grid>
                  <TitleHeader
                    title="Mensurations"
                    fs={12}
                    fw={600}
                    mb={1}
                    mt={3}
                    color="#4F5668"
                  />
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems="center"
                    flexBasis={0}
                    gap={1}
                  >
                    <StyledBodyDataBox flex={1} className="mgrmnt-bg">
                      <Box className="name" mb={1}>
                        Poitrine
                      </Box>
                      <Box className="value ">{item.chest || "-"}</Box>
                    </StyledBodyDataBox>
                    <StyledBodyDataBox flex={1} className="mgrmnt-bg">
                      <Box className="name" mb={1}>
                        Dos
                      </Box>
                      <Box className="value ">{item.back || "-"}</Box>
                    </StyledBodyDataBox>
                    <StyledBodyDataBox flex={1} className="mgrmnt-bg">
                      <Box className="name" mb={1}>
                        Ventre
                      </Box>
                      <Box className="value ">{item.belly || "-"}</Box>
                    </StyledBodyDataBox>
                    <StyledBodyDataBox flex={1} className="mgrmnt-bg">
                      <Box className="name" mb={1}>
                        Bras
                      </Box>
                      <Box className="value ">{item.arm || "-"}</Box>
                    </StyledBodyDataBox>
                    <StyledBodyDataBox flex={1} className="mgrmnt-bg">
                      <Box className="name" mb={1}>
                        Cuisses
                      </Box>
                      <Box className="value ">{item.thighs || "-"}</Box>
                    </StyledBodyDataBox>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </StyledEvolutionBox>
        ))}
      </Box>
      <ConfirmationModel
        isOpen={showAddMeasurementsModal}
        confirmButtonColor="primary"
        back="Annuler"
        confirm="Ajouter"
        title="Ajouter une évolution"
        width="672"
        body={
          <>
            <Formik
              innerRef={formRef}
              enableReinitialize={true}
              initialValues={{
                weight: selectedEvolution?.weight || 0,
                imc: selectedEvolution?.imc || 0,
                muscle: selectedEvolution?.muscle || 0,
                fat: selectedEvolution?.fat || 0,
                chest: selectedEvolution?.chest || 0,
                back: selectedEvolution?.back || 0,
                belly: selectedEvolution?.belly || 0,
                arm: selectedEvolution?.arm || 0,
                thighs: selectedEvolution?.thighs || 0,
                user_id: selectedEvolution?.user_id || 0,
                photo: [],
              }}
            >
              {(props) => {
                return (
                  <Form>
                    <TitleHeader
                      title="Photos"
                      mb={2}
                      fs={12}
                      fw={600}
                      color="#4F5668"
                    />
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      alignItems="center"
                      justifyContent="space-between"
                      gap={1}
                      mb={4}
                    >
                      {uploadBox(0)}
                      {uploadBox(1)}
                      {uploadBox(2)}
                    </Stack>
                    <Box>
                      <TitleHeader
                        title="Données corporelles"
                        mb={2}
                        fs={12}
                        fw={600}
                        color="#4F5668"
                      />
                      <Grid container spacing={1}>
                        <Grid item sm={3}>
                          <Field
                            variant="outlined"
                            type="text"
                            label="Poids"
                            placeholder="0.0"
                            name="weight"
                            mb={4}
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <Field
                            variant="outlined"
                            type="text"
                            label="IMC"
                            placeholder="0.0"
                            name="imc"
                            mb={4}
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <Field
                            variant="outlined"
                            type="text"
                            label="% Muscle"
                            placeholder="0.0"
                            name="muscle"
                            mb={4}
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <Field
                            variant="outlined"
                            type="text"
                            label="% Graisse"
                            placeholder="0.0"
                            name="fat"
                            mb={4}
                          />
                        </Grid>
                      </Grid>
                      <TitleHeader
                        title="Mensurations"
                        mb={2}
                        fs={12}
                        fw={600}
                        mt={0}
                        color="#4F5668"
                      />
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        alignItems="center"
                        justifyContent="space-between"
                        gap={1}
                      >
                        <Field
                          variant="outlined"
                          type="text"
                          label="Poitrine"
                          placeholder="0.0"
                          name="chest"
                        />
                        <Field
                          variant="outlined"
                          type="text"
                          label="Dos"
                          placeholder="0.0"
                          name="back"
                        />
                        <Field
                          variant="outlined"
                          type="text"
                          label="Ventre"
                          placeholder="0.0"
                          name="belly"
                        />
                        <Field
                          variant="outlined"
                          type="text"
                          label="Bras"
                          placeholder="0.0"
                          name="arm"
                        />
                        <Field
                          variant="outlined"
                          type="text"
                          label="Cuisses"
                          placeholder="0.0"
                          name="thighs"
                        />
                      </Stack>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </>
        }
        maxWidth="sm"
        handleClose={() => {
          toggleMeasurementsModal(false);
          setSelectedEvolutions([]);
        }}
        onConfirm={() => {
          const photosUrl = [];
          selectedFiles.forEach((photo) => {
            photosUrl.push(photo.url);
          });
          let data = {
            ...formRef.current?.values,
            user_id: params?.id,
            photo: photosUrl,
          };
          if (selectedEvolution?._id) {
            data = {
              ...selectedEvolution,
              ...data,
            };
            dispatch(editClientEvolution(data));
          } else {
            dispatch(createClientEvolution(data));
          }
          toggleMeasurementsModal(false);
        }}
      />
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer Évolution"
        body={[
          "Vous êtes sur le point de supprimer le bilan d'evolution, cette opération ne peut pas être inversée.",
        ]}
        maxWidth="sm"
        handleClose={toggleDeleteModel}
        onConfirm={() => {
          dispatch(
            deleteClientEvolution(selectedEvolution?._id, toggleDeleteModel)
          );
        }}
      />
    </>
  );
};
export default ClientEvolutions;
