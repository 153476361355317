import {
  Alert,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import Status from "components/Status";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fullName } from "utils/common";

const StyledAlert = styled(Alert)(() => ({
  cursor: "pointer",
  border: "1px solid #E0E4EA",
  borderRadius: "8px",
  marginBottom: "8px",
  paddingTop: "0px",
  paddingBottom: "0px",
  "&:last-child": {
    marginBottom: "0px",
  },
  ".MuiAlert-icon": {
    display: "none",
  },
  ".MuiAlert-action": {
    alignItems: "center",
  },
  ".MuiAlert-message": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#4F5668",
    fontSize: "14px",
  },
}));

const CoachesListBody = ({
  toggleDeleteModel,
  coach,
  toggleInviteCoachModal,
}) => {
  const dispatch = useDispatch();
  const naviagate = useNavigate();
  const { type } = useSelector((state) => state.user.userDetails);
  return (
    <StyledAlert
      variant="outlined"
      severity="info"
      onClick={() => toggleInviteCoachModal(coach)}
    >
      <Grid container>
        <Grid
          item
          sm={4}
          sx={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          {fullName(coach.first_name, coach.last_name)}
        </Grid>
        <Grid item sm={3} sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={coach.email} arrow>
            <Typography
              mr={2}
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "100%",
                fontSize: "14px",
              }}
            >
              {coach.email}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid
          item
          sm={2}
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          {coach.no_of_clients}
        </Grid>
        <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
          <Status type={coach.status} label={coach.status} />
        </Grid>
        <Grid item sm={1} sx={{ textAlign: "right" }}>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => toggleInviteCoachModal(coach)}
          >
            <Box
              component="img"
              src="/assets/logo/detail_icon.svg"
              alt="Coach details"
            ></Box>
          </IconButton>
          <IconButton
            color="info.main"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              toggleDeleteModel(coach);
            }}
          >
            <Box
              component="img"
              src="/assets/logo/trash_icon.svg"
              alt="Coach delete"
            ></Box>
          </IconButton>
        </Grid>
      </Grid>
    </StyledAlert>
  );
};

export default CoachesListBody;
