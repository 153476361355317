import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Stack,
  styled,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import FileUploaderModel from "components/EditProgram/FileUploaderModel";
import TitleHeader from "components/TitleHeader";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  createClientDocument,
  deleteClientDocument,
  getClientDocuments,
} from "store/actions/clients";
import { uploadFile } from "store/actions/program";
import { formatDate } from "utils/common";

const StyledBox = styled(Box)(() => ({
  border: "1px solid #E0E4EA",
  width: "100%",
  padding: "12px 16px",
  alignItems: "center",
  color: "#4F5668",
  fontSize: "14px",
  borderRadius: "8px",
  marginBottom: "8px",
  backgroundColor: "#ffffff",
  "&:last-child": {
    marginBottom: "0px",
  },
}));
const NoDocumentBox = styled(Box)(({ theme }) => ({
  border: "1px solid #E0E4EA",
  borderRadius: "8px",
  padding: "10px",
  textAlign: "center",
  fontSize: "14px",
  color: "#4F5668",
  backgroundColor: "#ffffff",
}));
const ClientDocuments = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [showImageUploader, setImageUploader] = useState(false);
  const clientDocuments = useSelector((state) => state.clients.clientDocuments);
  const [showDeleteModel, setShowDeleteModel] = useState();
  const [selectedDocument, setSelectedDocument] = useState();

  const toggleDeleteModel = () => {
    setShowDeleteModel(!showDeleteModel);
  };
  useEffect(() => {
    if (clientDocuments.length === 0) {
      dispatch(getClientDocuments(params?.id));
    }
  }, []);
  const toggleImageUploader = () => {
    setImageUploader(!showImageUploader);
  };
  return (
    <>
      <Box py={3}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          mb={4}
        >
          <TitleHeader title="Documents" fs={20} fw={600} />
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              toggleImageUploader();
            }}
          >
            Ajouter un document
          </Button>
        </Stack>
        {clientDocuments?.length === 0 && (
          <NoDocumentBox>Aucune document ajoutée.</NoDocumentBox>
        )}
        {clientDocuments?.map((item) => (
          <StyledBox variant="outlined" severity="info">
            <Grid container>
              <Grid
                item
                sm={9}
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Link
                  href="#"
                  onClick={() => toggleImageUploader()}
                  color="#4F5668"
                  underline="hover"
                >
                  {item.name}
                </Link>
              </Grid>
              <Grid
                item
                sm={2}
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                {formatDate(item.created_on)}
              </Grid>
              <Grid
                item
                sm={1}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => {
                    setSelectedDocument(item);
                    toggleDeleteModel();
                  }}
                >
                  <Box
                    component="img"
                    src="/assets/logo/trash_icon.svg"
                    alt="Exercise delete"
                  ></Box>
                </IconButton>
              </Grid>
            </Grid>
          </StyledBox>
        ))}
      </Box>
      <Formik enableReinitialize={true} initialValues={{ note: "" }}>
        <FileUploaderModel
          isOpen={showImageUploader}
          toggle={toggleImageUploader}
          isVideo={false}
          name="document"
          title="Ajouter un document"
          validationMsg="Uniquement les fichiers PDF jusqu'à 30MB."
          allowFileTypes={["application/pdf"]}
          callback={(file) => {
            return dispatch(
              uploadFile(
                file,
                "/api/upload/coach/documents/document/new_document",
                params?.id
              )
            ).then((res) => {
              const data = {
                name: file.name.split(".")[0],
                url: res,
                user_id: params?.id,
              };
              dispatch(createClientDocument(data));
              toggleImageUploader();
            });
          }}
        />
      </Formik>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer Document"
        body={[
          "Vous êtes sur le point de supprimer le bilan d'document, cette opération ne peut pas être inversée.",
        ]}
        maxWidth="sm"
        handleClose={toggleDeleteModel}
        onConfirm={() => {
          dispatch(
            deleteClientDocument(selectedDocument?._id, toggleDeleteModel)
          );
        }}
      />
    </>
  );
};
export default ClientDocuments;
