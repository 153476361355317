import { Box, Button, Container, Stack } from "@mui/material";
import IngrediantsContent from "components/EditNutritionPage/IngrediantsContent";
import RecipeDescription from "components/EditNutritionPage/RecipeDescription";
import RecipePreview from "components/EditNutritionPage/RecipePreview";
import { FormContext } from "components/FormContext";
import Loader from "components/Loader";
import TitleHeader from "components/TitleHeader";
import { Formik } from "formik";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setHeaderDetails } from "store/actions/header";
import {
  createNewRecipe,
  getCurrentRecipe,
  updateRecipe,
} from "store/actions/recipes";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";
import { recipes } from "utils/recipe.utils";

const EditNutrition = () => {
  const { formikRef } = useContext(FormContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const userDetails = useSelector((state) => state.user.userDetails);
  const [currentRecipe, setCurrentRecioe] = useState({});

  const isLoadingRecipes = useSelector(
    (state) => state.recipes.isLoadingRecipes
  );

  const recipeList = useSelector((state) => state.recipes.recipesLists);

  useEffect(() => {
    if (id) {
      dispatch(getCurrentRecipe(id, formikRef.current));
      recipeList?.forEach((rec) => {
        if (rec._id === id) {
          setCurrentRecioe(rec);
        }
      });
    }
    const navDetail = {
      isBreadcrumns: true,
      title: "",
      breadcrumsList: [
        {
          title: "Recettes",
          link: "/nutrition/recipes",
        },
        {
          title: "Créer une recette",
          link: "",
        },
      ],
    };

    dispatch(setHeaderDetails(navDetail));
  }, []);
  const handleFormSubmit = () => {
    formikRef.current?.handleSubmit();
  };
  return (
    <>
      <Container
        maxWidth="xxl"
        className="header-box"
        sx={{ padding: "0 !important", marginBottom: "20px" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <TitleHeader title="Créer une recette" />
          {(!id || currentRecipe?.created_by === userDetails?.user_id) && (
            <Button
              type="submit"
              color="success"
              variant="contained"
              onClick={() => handleFormSubmit()}
              disabled={formikRef.current?.isSubmitting}
            >
              Sauvegarder
            </Button>
          )}
        </Stack>
      </Container>
      <Container className="detail" maxWidth="xxl">
        <Loader isLoading={isLoadingRecipes} />
        <div style={{ display: isLoadingRecipes ? "none" : "" }}>
          <Formik
            innerRef={formikRef}
            initialValues={{ ...recipes.initialValues }}
            onSubmit={(values) => {
              setIsFormDirty(false);
              const data = _.pick(values, [
                "name",
                "prep_time",
                "cook_time",
                "kcal",
                "thumbnail",
                "video",
                "steps",
                "recipe_ingredients",
                "serving",
                "meal_type",
              ]);
              data.recipe_nutritions = [
                { name: "protin", value: values.protin },
                { name: "lipides", value: values.lipides },
                { name: "glucides", value: values.glucides },
              ];
              data.recipe_ingredients = _.map(
                data.recipe_ingredients,
                (value, index) => {
                  return {
                    name: `Ingredient ${index + 1}`,
                    description: value,
                  };
                }
              );
              data.thumbnail = data.thumbnail
                ? data.thumbnail
                : THUMBNAIL_PLACEHOLDER_GRAY;
              if (id) {
                dispatch(updateRecipe(id, data));
              } else {
                dispatch(createNewRecipe(data));
              }
            }}
          >
            {(props) => {
              const { handleSubmit, isSubmitting, dirty } = props;
              if (!isSubmitting) setIsFormDirty(dirty);
              return (
                <form onSubmit={handleSubmit}>
                  <Box gap={4} display="grid">
                    <Box gap={4} display="grid">
                      <Stack>
                        <RecipePreview />
                      </Stack>
                      <Stack>
                        <TitleHeader
                          el="h2"
                          mb={3}
                          title="Ingrédients"
                          fw={600}
                          fs={20}
                        />
                        <IngrediantsContent />
                      </Stack>
                      <Stack>
                        <TitleHeader
                          el="h2"
                          mb={3}
                          title="Étapes de préparation"
                          fw={600}
                          fs={20}
                        />
                        <RecipeDescription />
                      </Stack>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default EditNutrition;
