import { Grid } from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import { FormContext } from "components/FormContext";
import TitleHeader from "components/TitleHeader";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
const ProgramSaveAsTemplateModal = ({
  showSaveTemplateModal,
  setShowSaveTemplateModal,
  handleSubmit,
  isFitnessProgram = false,
  isIndependentSession = false,
}) => {
  const { formikRef } = useContext(FormContext);
  const nutritionData = useSelector((state) => state.nutritions.nutritionData);
  const programData = useSelector((state) => state.programs.programData);

  const [programDetails, setProgramDetails] = useState();
  useEffect(() => {
    if (isFitnessProgram) {
      setProgramDetails(programData);
    } else if (isIndependentSession) {
      const sessionDetails = formikRef?.current?.values?.week;
      setProgramDetails(sessionDetails ? sessionDetails[0][0] : []);
    } else {
      setProgramDetails(nutritionData);
    }
  }, [isFitnessProgram, isIndependentSession && formikRef?.current]);
  return (
    <ConfirmationModel
      isOpen={showSaveTemplateModal}
      confirmButtonColor="primary"
      confirm="Sauvegarder en Modèle"
      back="Retour"
      title="Sauvegarder en Modèle"
      body={
        <>
          <TitleHeader title="Récapitulatif" fs={16} fw="600" />
          {isIndependentSession ? (
            <Grid container fontSize={14} mt={3} mb={1}>
              <Grid item sm={6}>
                Titre de la séance :
              </Grid>
              <Grid item fontWeight={700} textAlign="right" sm={6}>
                {programDetails?.name || "-"}
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container fontSize={14} mt={3} mb={1}>
                <Grid item sm={6}>
                  Nom du programme :
                </Grid>
                <Grid item fontWeight={700} textAlign="right" sm={6}>
                  {programDetails?.name || "-"}
                </Grid>
              </Grid>
              <Grid container fontSize={14}>
                <Grid item sm={6}>
                  Durée du programme :
                </Grid>
                <Grid fontWeight={700} textAlign="right" item sm={6}>
                  {programDetails?.program_weeks} semaines
                </Grid>
              </Grid>
            </>
          )}
        </>
      }
      maxWidth="sm"
      handleClose={() => setShowSaveTemplateModal(false)}
      onConfirm={() => {
        handleSubmit();
      }}
    />
  );
};

export default ProgramSaveAsTemplateModal;
