import Button from "./Button";
import Container from "./Container";
import Pagination from "./Pagination";
import TextField from "./TextField";
import Typography from "./Typography";

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Typography(theme),
    Container(theme),
    TextField(theme),
    Button(theme),
    Pagination(theme)
  );
}
