export const SET_PROMPT = "set_error";
export const initialPromptData = {
  isOpen: false,
  title: "Attention",
  showActions: true,
  message: "",
  maxWidth: "sm",
  hideSecondaryBtn: false,
  secondaryBtnText: "fermer",
  secondaryBtncolor: "secondary",
  primaryBtnText: "Continuer",
  primaryBtnColor: "primary",
  hidePrimaryBtn: false,
  primaryBtnHandler: () => {},
  secondaryBtnHandler: () => {},
  btnAlign: "center",
};
export const setPrompt = (response = {}) => {
  return (dispatch) => {
    dispatch({
      type: SET_PROMPT,
      payload: response,
    });
  };
};
export const resetPromptData = (response = {}) => {
  return (dispatch) => {
    dispatch({
      type: SET_PROMPT,
      payload: { ...initialPromptData },
    });
  };
};
