import {
  Box,
  IconButton,
  List,
  ListItemAvatar,
  Stack,
  styled,
} from "@mui/material";
import ListItemButton from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CustomIcon from "components/CustomIcon";
import { connect } from "formik";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { toggleExerciseModel } from "store/actions/program";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";

const StyledListItem = styled(ListItemButton)(() => ({
  padding: 0,
  mb: 3,
  gap: "10px",
  overflow: "hidden",
  cursor: "pointer",
}));

const ExerciseCard = ({ exercisesList, index: exerciseIndex, formik }) => {
  const dispatch = useDispatch();
  const { setFieldValue, values } = formik;
  const activeWeek = useSelector((state) => state.nutritions.activeWeek);
  const activeDay = useSelector((state) => state.nutritions.activeDay);

  const activeElement = values["week"][activeWeek][activeDay];
  const whereTo = `week.${activeWeek}.[${activeDay}]`;

  const renderNutritionCards = () => {
    let list = [];
    (exercisesList || []).forEach((exercise, index) => {
      const recipes = activeElement.meals[exerciseIndex]
        ? activeElement.meals[exerciseIndex].recipes
        : [];
      list.push(
        <Draggable
          draggableId={whereTo + `.meals[${exerciseIndex}].recipes[${index}]`}
          index={index}
        >
          {(provided) => (
            <Stack
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
              mb={1}
              key={index}
              sx={{
                borderRadius: "8px",
                overflow: "hidden",
                background: "#ffffff",
                border: "1px solid #E0E4EA",
              }}
            >
              <List component="div" disablePadding>
                <StyledListItem
                  secondaryAction={
                    <Stack direction="row" alignItems="center">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          let data = [...recipes];
                          data.splice(index, 1);
                          setFieldValue(
                            whereTo + `.meals[${exerciseIndex}].recipes`,
                            [...data]
                          );
                        }}
                      >
                        <CustomIcon
                          title="Delete Recipe"
                          iconSrc="/assets/logo/trash_icon.svg"
                          iconHoverSrc="/assets/logo/trash_icon_hover.svg"
                        />
                      </IconButton>
                    </Stack>
                  }
                >
                  <ListItemAvatar
                    sx={{ display: "flex" }}
                    onClick={() => dispatch(toggleExerciseModel(exercise))}
                  >
                    <img
                      src={exercise?.thumbnail}
                      width="75px"
                      height="56"
                      alt={exercise?.name}
                      style={{ objectFit: "cover" }}
                      onError={(event) => {
                        event.target.src = THUMBNAIL_PLACEHOLDER_GRAY;
                        event.onerror = null;
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={exercise?.name} />
                </StyledListItem>
              </List>
            </Stack>
          )}
        </Draggable>
      );
    });
    return list;
  };
  return (
    <Droppable
      droppableId={`recipeGroup_${exerciseIndex}`}
      type="droppableSubItem"
    >
      {(provided) => (
        <Box mb={1} ref={provided.innerRef} {...provided.droppableProps}>
          {renderNutritionCards()}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};

export default connect(ExerciseCard);
