import { SET_HEADER_DETAILS } from "store/actions/header";
export default function header(state = null, action) {
  if (state == null) {
    return {
      isBreadcrumns: false,
      title: "",
      breadcrumsList: [],
    };
  }
  switch (action.type) {
    case SET_HEADER_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
