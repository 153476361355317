import styled from "@emotion/styled";
import CreateIcon from "@mui/icons-material/Create";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import CustomIcon from "components/CustomIcon";
import FileUploaderModel from "components/EditProgram/FileUploaderModel";
import Field from "components/Field";
import { compare, required } from "components/Field/validate";
import TitleHeader from "components/TitleHeader";
import { Formik } from "formik";
import { sha512 } from "js-sha512";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderDetails } from "store/actions/header";
import { uploadFile } from "store/actions/program";
import {
  changePassword,
  deleteMyAccount,
  getProfile,
  updateNotificationPreference,
  updateProfile,
} from "store/actions/user";
import { formatDate } from "utils/common";

const StyleList = styled(List)(() => ({
  ".MuiListItem-root": {
    padding: "24px 32px 32px 32px",
  },
  ".MuiListItemAvatar-root": {
    minWidth: "120px",
  },
  ".MuiAvatar-root": {
    width: "96px",
    height: "96px",
  },
}));

const Settings = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userDetails);
  const userProfile = useSelector((state) => state.user.userProfile);
  const [showImageUploader, setImageUploader] = useState(false);
  const [userPicture, setUserPicture] = useState(userProfile?.picture || "");
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  useEffect(() => {
    dispatch(getProfile());
    const navDetail = {
      isBreadcrumns: false,
      title: "Paramètres du compte",
    };
    dispatch(setHeaderDetails(navDetail));
  }, []);
  const toggleImageUploader = () => {
    setImageUploader(!showImageUploader);
  };
  const toggleDeleteModal = (ele = "") => {
    setShowDeleteUserModal(!showDeleteUserModal);
  };
  return (
    <>
      <Container
        maxWidth="xxl"
        sx={{ backgroundColor: "#E4EAFC", padding: "0px !important" }}
      >
        <StyleList>
          <ListItem alignItems="flex-center">
            <ListItemAvatar>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <IconButton
                    onClick={toggleImageUploader}
                    sx={{
                      backgroundColor: "#0001FC",
                      height: "32px",
                      width: "32px",
                      borderRadius: "50%",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                  >
                    <CreateIcon fontSize="small" />
                  </IconButton>
                }
              >
                {userPicture ? (
                  <Avatar alt="user image" src={userPicture} />
                ) : (
                  <Avatar sx={{ bgcolor: "#0001FC", fontSize: "40px" }}>
                    {userProfile?.first_name ? userProfile?.first_name[0] : ""}
                  </Avatar>
                )}
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <TitleHeader
                    title={`${userProfile?.first_name || ""} ${
                      userProfile?.last_name || ""
                    }`}
                    fs={28}
                    fw={700}
                  ></TitleHeader>
                </>
              }
              secondary={
                <>
                  <Typography
                    component="div"
                    mb={0.5}
                    color="#111111"
                    variant="body2"
                  >
                    Membre depuis : {formatDate(userProfile?.created_on)}
                  </Typography>
                </>
              }
            />
          </ListItem>
        </StyleList>
      </Container>
      <Container
        maxWidth="xxl"
        sx={{ marginTop: 3, padding: "32px !important" }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            first_name: userProfile?.first_name || "",
            last_name: userProfile?.last_name || "",
            phone: userProfile?.phone || "",
            email: userProfile?.email || "",
          }}
          onSubmit={(values) => {
            const data = {
              ...values,
              role: userProfile?.role,
              _id: userProfile?._id,
            };
            dispatch(updateProfile(data));
          }}
        >
          {(props) => {
            const { handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  mb={4}
                >
                  <TitleHeader title="Profil" fs={20} fw={600} />
                  <Button color="primary" type="submit" variant="contained">
                    Sauvegarder
                  </Button>
                </Stack>
                <Grid container spacing={3} mb={2}>
                  <Grid item sm={6}>
                    <Field
                      variant="outlined"
                      type="text"
                      label="Nom"
                      placeholder="Nom"
                      name="first_name"
                      validate={required}
                      mb={1}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Field
                      variant="outlined"
                      type="text"
                      label="Prénom"
                      placeholder="Prénom"
                      name="last_name"
                      validate={required}
                      mb={1}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Field
                      variant="outlined"
                      type="text"
                      label="Email"
                      placeholder="Email"
                      name="email"
                      validate={required}
                      mb={1}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Field
                      variant="outlined"
                      type="text"
                      label="Numéro"
                      placeholder="Numéro"
                      name="phone"
                      mb={1}
                    />
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
        <Divider sx={{ margin: "40px 0 50px 0" }} />
        <Formik
          enableReinitialize={true}
          initialValues={{
            current_password: "",
            new_password: "",
            confirm_password: "",
          }}
          onSubmit={(values, { resetForm }) => {
            const data = {
              current_password: sha512(values.current_password),
              new_password: sha512(values.new_password),
              confirm_password: sha512(values.confirm_password),
            };
            dispatch(changePassword(data)).then(() => {
              resetForm();
            });
          }}
        >
          {(props) => {
            const { values, handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  mb={4}
                >
                  <TitleHeader title="Mot de passe" fs={20} fw={600} />
                  <Button color="primary" type="submit" variant="contained">
                    Sauvegarder
                  </Button>
                </Stack>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <Field
                      variant="outlined"
                      type="password"
                      label="Mot de passe actuel*"
                      placeholder="Mot de passe actuel"
                      name="current_password"
                      validate={required}
                      mb={1}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Field
                      variant="outlined"
                      type="password"
                      label="Nouveau mot de passe"
                      placeholder="Nouveau mot de passe"
                      name="new_password"
                      validate={required}
                      mb={1}
                    />
                  </Grid>
                  <Grid item sm={6}></Grid>
                  <Grid item sm={6}>
                    <Field
                      variant="outlined"
                      type="password"
                      label="Confirmer le nouveau mot de passe"
                      placeholder="Confirmer le nouveau mot de passe"
                      name="confirm_password"
                      validate={compare(
                        values.new_password,
                        values.confirm_password
                      )}
                      mb={1}
                    />
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
        <Divider sx={{ margin: "40px 0 50px 0" }} />

        <Formik
          enableReinitialize={true}
          initialValues={{
            client_program_completion:
              userProfile?.notification_preferences
                ?.client_program_completion || false,
            client_session_completion:
              userProfile?.notification_preferences
                ?.client_session_completion || false,
            client_account_setup:
              userProfile?.notification_preferences?.client_account_setup ||
              false,
            client_account_deletion:
              userProfile?.notification_preferences?.client_account_deletion ||
              false,
          }}
          onSubmit={(values) => {
            dispatch(updateNotificationPreference(values));
          }}
        >
          {(props) => {
            const { handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  mb={4}
                >
                  <TitleHeader title="Notifications" fs={20} fw={600} />
                  <Button color="primary" type="submit" variant="contained">
                    Sauvegarder
                  </Button>
                </Stack>
                <TitleHeader title="M’alerter quand" mb={3} fs={16} fw={600} />
                <Field
                  type="switch"
                  label="Un client supprime son compte"
                  name="client_account_deletion"
                />
                <Field
                  type="switch"
                  label="Un client accepte une invitation"
                  name="client_account_setup"
                />
                <Field
                  type="switch"
                  label="Un client complète un programme"
                  name="client_program_completion"
                />
                <Field
                  type="switch"
                  label="Un client complète une séance"
                  name="client_session_completion"
                />
              </form>
            );
          }}
        </Formik>
        <Box sx={{ display: "none" }}>
          <Divider sx={{ margin: "40px 0 50px 0" }} />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="space-between"
            gap={1}
            mb={4}
          >
            <TitleHeader title="Abonnement" fs={20} fw={600} />
            <Button color="primary" variant="contained">
              Changer mon abonnement
            </Button>
          </Stack>
          <Box sx={{ border: "1px solid #E38C3C", borderRadius: "24px" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent="space-between"
              p={3}
              sx={{
                backgroundColor: "#FBEDE0",
                borderRadius: "24px 24px 0 0",
              }}
              gap={1}
            >
              <TitleHeader title="EVO COACH PLUS" fs={20} fw={600} />
              <TitleHeader title="3 Months" fs={20} fw={600} />
            </Stack>
            <Box p={3}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                mb={2}
              >
                <TitleHeader title="Membre depuis" fs={14} fw={400} />
                <TitleHeader title="15 mai 2024" fs={14} fw={700} />
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                justifyContent="space-between"
                gap={1}
              >
                <TitleHeader
                  title="Paiement automatiquement renouvelable le "
                  fs={14}
                  fw={400}
                >
                  &nbsp;<strong>15 juin, 2023</strong>
                </TitleHeader>
                <Link
                  underline="hover"
                  color="#F05063"
                  sx={{
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Annuler l’abonnement
                </Link>
              </Stack>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ margin: "40px 0 50px 0" }} />
        <TitleHeader title="Suppression de compte" fs={20} mb={3} fw={600} />
        <Box sx={{ backgroundColor: "#FDE3E6", borderRadius: "16px" }} p={3}>
          <Button
            variant="contained"
            onClick={() => {
              toggleDeleteModal();
            }}
            color="error"
            endIcon={<CustomIcon iconSrc="/assets/logo/trash_white.svg" />}
          >
            SUPPRIMER MON COMPTE
          </Button>
          <TitleHeader
            title="** Veuillez noter qu'une fois votre compte supprimé, vos informations et votre contenu seront définitivement supprimés. **"
            fs={12}
            mt={2}
            fw={400}
          />
        </Box>
        <ConfirmationModel
          isOpen={showDeleteUserModal}
          title=" Supprimer le compte ?"
          body="Vous êtes sur le point de supprimer votre compte. Cette action irréversible et toutes vos données seront perdues."
          handleClose={toggleDeleteModal}
          onConfirm={() => dispatch(deleteMyAccount(toggleDeleteModal))}
          confirm="Confirmer"
          back="Annuler"
        />
        <Formik enableReinitialize={true} initialValues={{ user_picture: "" }}>
          <FileUploaderModel
            isOpen={showImageUploader}
            toggle={toggleImageUploader}
            isVideo={false}
            name="user_picture"
            title="Ajouter un document"
            validationMsg="Uniquement les fichiers JPEG, PNG jusqu'à 30MB."
            allowFileTypes={["image/jpeg", "image/png"]}
            callback={(file) => {
              return dispatch(
                uploadFile(file, "/api/upload/coach/thumbs/picture/new_thumb")
              ).then((res) => {
                if (res) {
                  setUserPicture(res);
                  const data = {
                    ...userProfile,
                    picture: res,
                  };
                  dispatch(updateProfile(data, "photo_upload"));
                }
                toggleImageUploader();
              });
            }}
          />
        </Formik>
      </Container>
    </>
  );
};

export default Settings;
