// ----------------------------------------------------------------------

export default function TextField(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ".MuiInputBase-input": {
            color: "#111111",
            paddingTop: "10px",
            paddingBottom: "10px",
            fontSize: "14px",
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#757E97",
            borderRadius: "8px",
          },
          ".Mui-disabled": {
            zIndex: 1,
          },
          ".Mui-disabled + .MuiOutlinedInput-notchedOutline": {
            backgroundColor: "#E0E4EA",
          },
          ".Mui-disabled  + .MuiInputAdornment-positionEnd + .MuiOutlinedInput-notchedOutline":
            {
              backgroundColor: "#E0E4EA",
            },
          ".Mui-disabled + .MuiStack-root + .MuiOutlinedInput-notchedOutline": {
            backgroundColor: "#E0E4EA",
          },
        },
      },
    },
  };
}
