import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/styles";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0001FC",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? "theme.palette.grey[100]"
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const getNestedObjectPropertyFromString = (from, ...selectors) =>
  [...selectors].map((s) =>
    s
      .replace(/\[([^\[\]]*)\]/g, ".$1.")
      .split(".")
      .filter((t) => t !== "")
      .reduce((prev, cur) => prev && prev[cur], from)
  );
export const SwitchComponent = (props) => {
  const switchChecked = getNestedObjectPropertyFromString(
    props.field.value,
    props.field.name
  )[0];
  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {props.customLable && <Typography>{props.label}</Typography>}
      <FormControlLabel
        label={!props.customLable ? props.label : ""}
        control={
          <IOSSwitch {...props.field} checked={switchChecked} sx={{ m: 1 }} />
        }
      />
    </Stack>
  );
};

export const CheckboxComponent = (props) => {
  return (
    <FormControlLabel
      label={props.label}
      control={
        <Checkbox
          {...props.field}
          checked={
            props.field.value[props.field.name]
              ? props.field.value[props.field.name]
              : false
          }
        />
      }
    />
  );
};
