import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Grid, IconButton } from "@mui/material";
import CardImageUploader from "components/CardImageUploader";
import Field from "components/Field";
import { required } from "components/Field/validate";
import TitleHeader from "components/TitleHeader";
import WysiwygEditor from "components/WysiwygEditor";
import { connect } from "formik";

const NutritionDetails = ({
  formik,
  editorValue,
  setEditorValue,
  savedEditorValue,
}) => {
  const { values, setFieldValue } = formik;
  return (
    <>
      <Grid container spacing={5}>
        <Grid item sm={6} xs={12}>
          <TitleHeader el="h3" mb={3} title="Details" fw={600} fs={16} />
          <Box pr={5} sx={{ borderRight: "1px solid #E0E4EA" }}>
            <Field
              variant="outlined"
              type="text"
              label="Titre du programme"
              placeholder="Titre du programme"
              name="name"
              validate={required}
              mb={4}
            />
            <Grid container spacing={2}>
              <Grid item sm={9} xs={12}>
                <Field
                  variant="outlined"
                  type="date"
                  label="Date de début"
                  placeholder="Date de début"
                  name="start_date"
                  validate={required}
                  mb={4}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Field
                  type="number"
                  label="Semaines"
                  placeholder="0"
                  name="program_weeks"
                  size="small"
                  sx={{ ".MuiInputBase-input": { textAlign: "center" } }}
                  validate={required}
                  min={1}
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        edge="start"
                        onClick={() =>
                          setFieldValue(
                            "program_weeks",
                            values.program_weeks - 1
                          )
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                    ),
                    endAdornment: (
                      <IconButton
                        edge="end"
                        onClick={() =>
                          setFieldValue(
                            "program_weeks",
                            values.program_weeks + 1
                          )
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <WysiwygEditor
              editorValue={editorValue}
              setEditorValue={setEditorValue}
              savedEditorValue={savedEditorValue}
            />
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TitleHeader el="h3" mb={3} title="Media" fw={600} fs={16} />
          <Box mb={2} sx={{ fontSize: "12px", fontWeight: "600" }}>
            Affiche du programme
          </Box>
          <CardImageUploader
            name="thumbnail"
            libraryApi="/api/coach/nutrition/image/library"
            label="Ajouter une affiche"
            view="portrait"
            apiKey="/api/upload/coach/thumbs/exercise/new_thumb"
            thumbnail={values ? values.thumbnail : null}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default connect(NutritionDetails);
