import { Navigate, useRoutes } from "react-router-dom";

import Dashboard from "layout/commanLayout";
import LayoutContent from "layout/layoutContent";
import CoachesList from "screens/Coaches/CoachesList";
import CreateNutritionPage from "screens/CreateNutritionPage";
import CreateProgramPage from "screens/CreateProgramPage";
import EditExercice from "screens/EditExercice";
import EditNutrition from "screens/EditNutrition";
import EditProgramPage from "screens/EditProgramPage";
import HomePage from "screens/HomePage";
import ClientDetails from "screens/MyClients/ClientDetails";
import MyClientsList from "screens/MyClients/ClientsList";
import NutritionInfo from "screens/NutritionInfo";
import NutritionPage from "screens/NutritionPage";
import NutritionPlans from "screens/NutritionPlans";
import ProgrammsPage from "screens/ProgrammsPage";
import CreateSession from "screens/Session/CreateSession";
import SessionList from "screens/Session/SessionList";
import Settings from "screens/Settings";
import Welcome from "screens/Welcome";
import AuthPage from "screens/auth";
import Cookie from "./utils/cookie";

const Routes = () => {
  const routes = [
    {
      path: "/",
      element: <Dashboard />,
      children: [
        {
          path: "fitness/exercices",
          element: <LayoutContent element={HomePage} />,
        },
        {
          path: "fitness/exercices/new",
          element: <LayoutContent element={EditExercice} />,
        },
        {
          path: "fitness/exercices/:id",
          element: <LayoutContent element={EditExercice} />,
        },

        {
          path: "nutrition/recipes",
          element: <LayoutContent element={NutritionPage} />,
        },
        {
          path: "nutrition/recipes/create",
          element: <LayoutContent element={EditNutrition} />,
        },
        {
          path: "nutrition/recipes/:id",
          element: <LayoutContent element={EditNutrition} />,
        },

        {
          path: "nutrition/programs",
          element: <LayoutContent element={NutritionPlans} />,
        },
        {
          path: "nutrition/programs/create",
          element: <LayoutContent element={CreateNutritionPage} />,
        },
        {
          path: "nutrition/programs/final",
          element: <LayoutContent element={NutritionInfo} />,
        },
        {
          path: "nutrition/programs/:id",
          element: <LayoutContent element={CreateNutritionPage} />,
        },
        {
          path: "nutrition/programs/final/:id",
          element: <LayoutContent element={NutritionInfo} />,
        },

        {
          path: "fitness/programs",
          element: <LayoutContent element={ProgrammsPage} />,
        },
        {
          path: "fitness/programs/create",
          element: <LayoutContent element={EditProgramPage} />,
        },
        {
          path: "fitness/programs/final",
          element: <LayoutContent element={CreateProgramPage} />,
        },
        {
          path: "fitness/programs/:id",
          element: <LayoutContent element={EditProgramPage} />,
        },
        {
          path: "fitness/programs/final/:id",
          element: <LayoutContent element={CreateProgramPage} />,
        },
        {
          path: "fitness/sessions",
          element: Cookie.get("jwt") ? (
            <LayoutContent element={SessionList} />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "fitness/sessions/:id",
          element: Cookie.get("jwt") ? (
            <LayoutContent element={CreateSession} />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "fitness/sessions/create",
          element: Cookie.get("jwt") ? (
            <LayoutContent element={CreateSession} />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "/",
          element: Cookie.get("jwt") ? (
            <LayoutContent element={Welcome} />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "/clients",
          element: Cookie.get("jwt") ? (
            <LayoutContent element={MyClientsList} />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "/clients/:id",
          element: Cookie.get("jwt") ? (
            <LayoutContent element={ClientDetails} />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "/coaches",
          element: Cookie.get("jwt") ? (
            <LayoutContent element={CoachesList} />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "/settings",
          element: Cookie.get("jwt") ? (
            <LayoutContent element={Settings} />
          ) : (
            <Navigate to="/login" />
          ),
        },
      ],
    },
    { path: "/login", element: <AuthPage /> },
    { path: "/forget-password", element: <AuthPage /> },
    { path: "/signup", element: <AuthPage /> },
    { path: "*", element: <Navigate to="/login" /> },
  ];
  return useRoutes(routes);
};

export default Routes;
