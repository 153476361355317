import { Grid, styled } from "@mui/material";

const Header = styled(Grid)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  color: "#757E97",
}));

const ClientListHeader = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "6px 16px",
      }}
    >
      <Header
        item
        xs={4}
        sx={{ display: "flex", gap: "10px", alignItems: "center" }}
      >
        Nom du client
      </Header>
      <Header item md={3}>
        Adresse email
      </Header>
      <Header item md={2}>
        Membre depuis
      </Header>
      <Header item md={2}>
        Statut
      </Header>
      <Header item md={1} sx={{ textAlign: "right" }}>
        Actions
      </Header>
    </Grid>
  );
};

export default ClientListHeader;
