const questionId = {
  1: "onb_5UVANMZOsETZ",
  2: "onb_xu6W1nBFOwyj",
  3: "onb_24ks0oRkeQbE",
  4: "onb_bQ2T8C6REMKU",
};
export const programStatuses = [
  {
    id: 1,
    name: "Actif",
  },
  {
    id: 2,
    name: "Brouillon",
  },
  {
    id: 3,
    name: "Modèle",
  },
  {
    id: 4,
    name: "Complété",
  },
];
export const clientStatuses = [
  {
    id: 1,
    name: "Inactif",
  },
  {
    id: 2,
    name: "À Venir",
  },
  {
    id: 3,
    name: "Actif",
  },
];
export const programs = {
  programsFields: [
    "name",
    "description",
    "thumbnail",
    "primary_muscle",
    "status",
    "start_date",
    "end_date",
    "program_weeks",
    "weeks",
    "next_program_id",
    "user_id",
  ],
  sessionFields: [
    "name",
    "thumbnail",
    "calories",
    "duration",
    "equipments",
    "exercise_groups",
    "in_explorer",
    "isCompleted",
  ],
  initialValues: {
    status: "Actif",
    equipments: [],
    weeks: {},
    thumbnail: "",
    name: "",
    primary_muscle: [],
    description: "",
    program_weeks: 2,
    start_date: "",
    end_date: "",
    next_program_id: "",
  },
  equipments: [
    { id: 0, name: "Corde à sauter" },
    { id: 1, name: "Ballon de gym" },
    { id: 2, name: "Haltères" },
    { id: 3, name: "Elastiques" },
    { id: 4, name: "Tapis" },
    { id: 5, name: "Step" },
    { id: 6, name: "Medecine ball" },
  ],
  convertKeys: function (obj) {
    if (typeof obj === "object" && obj !== null) {
      if (Array.isArray(obj)) {
        return obj.map((item) => this.convertKeys(item));
      } else {
        const newObject = {};
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            const newKey = key.replace(/^{(.+)}$/, "$1");
            const value = obj[key];
            newObject[newKey] = this.convertKeys(value);
          }
        }
        return newObject;
      }
    }
    return obj;
  },
  reverseObject: function (obj) {
    const newObject = {};
    for (const key in obj) {
      const newKey = `{${key}}`;
      if (typeof obj[key] === "object") {
        newObject[newKey] = this.reverseObject(obj[key]);
      } else {
        newObject[newKey] = obj[key];
      }
    }
    return newObject;
  },
  getValues: function (value = "", id = 1) {
    return {
      question_id: questionId[id],
      answers: [
        {
          value: value,
          operator: "=",
        },
      ],
      answers_condition: "all_of",
    };
  },
  generateQuestionArray: function (arr = [], id = 1) {
    var elementId = questionId[id];
    var answers = [];

    for (var i = 0; i < arr.length; i++) {
      var answer = {
        value: arr[i],
        operator: "=",
      };
      answers.push(answer);
    }

    return {
      question_id: elementId,
      answers: answers,
      answers_condition: "all_of",
    };
  },
};
