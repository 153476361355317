import { styled } from "@mui/material/styles";
import PromptModal from "components/PromptModal";
import DashboardNavbar from "layout/DashboardNavbar";
import DashboardSidebar from "layout/DashboardSidebar";
import React from "react";
import { Outlet } from "react-router-dom";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  height: "100%",
  backgroundColor: "#F5F5F5",
}));
// ----------------------------------------------------------------------

export default function Dashboard() {
  const [open, setOpen] = React.useState(false);
  return (
    <RootStyle>
      <PromptModal />
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <Outlet />
    </RootStyle>
  );
}
