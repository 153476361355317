import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiTypography-root": {
    paddingBottom: 0,
  },
  "& .MuiPaper-root": {
    borderRadius: "12px",
  },
  "& .MuiDialogActions-root": {
    padding: "0 32px 32px",
  },
  "& .MuiDialogContent-root ": {
    padding: "32px",
    paddingTop: "10px",
  },
  "&  .MuiTypography-h6": {
    fontSize: "28px",
    color: "#111111",
    fontWeight: 700,
    padding: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          {" "}
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ConfirmationModel = (props) => {
  const {
    isOpen,
    handleClose,
    title,
    body,
    showActions,
    maxWidth,
    onConfirm,
    back,
    confirm,
    disableConfirmButton = false,
    confirmButtonColor,
    hideBackButton = false,
    onSecondarySubmit = null,
  } = props;
  return (
    <BootstrapDialog
      onClose={handleClose}
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth={true}
    >
      <BootstrapDialogTitle onClose={handleClose}>{title}</BootstrapDialogTitle>
      <DialogContent>{body}</DialogContent>
      {showActions && (
        <DialogActions sx={{ display: "flex", gap: 2 }}>
          {!hideBackButton && (
            <Button
              sx={{ width: "100%" }}
              onClick={onSecondarySubmit ? onSecondarySubmit : handleClose}
              variant="outlined"
              color="secondary"
            >
              {" "}
              {back}
            </Button>
          )}
          <Button
            variant="contained"
            color={confirmButtonColor ? confirmButtonColor : "error"}
            onClick={onConfirm}
            disabled={disableConfirmButton}
            sx={{ width: "100%" }}
          >
            {" "}
            {confirm}
          </Button>
        </DialogActions>
      )}
    </BootstrapDialog>
  );
};

ConfirmationModel.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  body: PropTypes.any,
  showActions: PropTypes.bool,
};

ConfirmationModel.defaultProps = {
  showActions: true,
  maxWidth: "xs",
  back: "Retour",
  confirm: "SUPPRIMER",
};

export default ConfirmationModel;
