import { Grid, MenuItem } from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import Field from "components/Field";
import { FormContext } from "components/FormContext";
import { Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getClients } from "store/actions/clients";
import { setProgramData } from "store/actions/program";
import { formatDate } from "utils/common";
const SendProgramModal = ({
  showSendProgramModal,
  handleDraftSubmit,
  setShowSendProgramModal,
  isFitnessProgram = false,
  isIndependentSession = false,
}) => {
  const { formikRef, handleSubmit } = useContext(FormContext);

  const clientList = useSelector((state) => state.clients.clients);
  const nutritionData = useSelector((state) => state.nutritions.nutritionData);
  const programData = useSelector((state) => state.programs.programData);
  const dispatch = useDispatch();
  const currentRef = useRef(null);
  const [programDetails, setProgramDetails] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    if (isFitnessProgram) {
      setProgramDetails(programData);
    } else if (isIndependentSession) {
      const sessionDetails = formikRef?.current?.values?.week;
      setProgramDetails(sessionDetails ? sessionDetails[0][0] : []);
    } else {
      setProgramDetails(nutritionData);
    }
  }, [isFitnessProgram, isIndependentSession && formikRef?.current]);
  useEffect(() => {
    if (clientList.length === 0) {
      dispatch(getClients());
    }
  }, [clientList]);
  return (
    <ConfirmationModel
      isOpen={showSendProgramModal}
      confirmButtonColor="primary"
      confirm="Envoyer"
      back="Sauvegarder en brouillon"
      title={
        isIndependentSession ? "Envoyer la séance" : "Envoyer la programme"
      }
      hideBackButton={
        id &&
        (programDetails?.status === "Actif" ||
          programDetails?.status === "Modèle")
      }
      body={
        <>
          {isIndependentSession ? (
            <Grid container fontSize={14} mt={3} mb={3}>
              <Grid item sm={6}>
                Titre de la séance :
              </Grid>
              <Grid item fontWeight={700} textAlign="right" sm={6}>
                {programDetails?.name || "-"}
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container fontSize={14} mt={3} mb={1}>
                <Grid item sm={6}>
                  Nom de programme:
                </Grid>
                <Grid item fontWeight={700} textAlign="right" sm={6}>
                  {programDetails?.name || "-"}
                </Grid>
              </Grid>
              <Grid container fontSize={14} mb={1}>
                <Grid item sm={6}>
                  Date de début:
                </Grid>
                <Grid fontWeight={700} textAlign="right" item sm={6}>
                  {formatDate(new Date(programDetails?.start_date) || "")}
                </Grid>
              </Grid>
              <Grid container fontSize={14} mb={3}>
                <Grid item sm={6}>
                  Durée:
                </Grid>
                <Grid fontWeight={700} textAlign="right" item sm={6}>
                  {programDetails?.program_weeks} semaines
                </Grid>
              </Grid>
            </>
          )}
          <Formik
            innerRef={currentRef}
            enableReinitialize={true}
            initialValues={{ user_id: programDetails?.user_id || "" }}
          >
            <Field
              type="select"
              label="Ajouter un client"
              placeholder="Ajouter un client"
              name="user_id"
              notched={true}
              options={clientList}
              disabled={id && programDetails?.status === "Actif"}
            >
              {clientList?.map((item) => (
                <MenuItem value={item._id} key={item._id}>
                  {`${item.first_name || "-"} ${item.last_name}`}
                </MenuItem>
              ))}
            </Field>
          </Formik>
        </>
      }
      maxWidth="sm"
      handleClose={() => setShowSendProgramModal(false)}
      onSecondarySubmit={() => handleDraftSubmit()}
      onConfirm={() => {
        if (isFitnessProgram) {
          dispatch(
            setProgramData({
              ...programData,
              status: "Actif",
              user_id: currentRef.current.values.user_id,
            })
          );
        }
        formikRef.current?.setFieldValue("status", "Actif");
        formikRef.current?.setFieldValue(
          "user_id",
          currentRef.current.values.user_id
        );
        handleSubmit();
      }}
    />
  );
};

export default SendProgramModal;
