import { FormContext } from "components/FormContext";
import { Form, Formik } from "formik";
import _ from "lodash";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { returnWeeksLists } from "store/actions/nutritions";
import { getRecipeLists } from "store/actions/recipes";
import ExerciseNutrition from "../ExerciseNutrition";

const NutritionFinalForm = () => {
  const { formikRef } = useContext(FormContext);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeWeek = useSelector((state) => state.nutritions.activeWeek);
  const activeDay = useSelector((state) => state.nutritions.activeDay);
  const initialNutritionValue = useSelector(
    (state) => state.nutritions.initialNutritionValue
  );
  const nutritionData = useSelector((state) => state.nutritions.nutritionData);

  useEffect(() => {
    if (_.isEmpty(nutritionData.name)) {
      navigate("/nutrition/programs");
    }
    dispatch(getRecipeLists());
  }, []);

  const whereTo = `week.${activeWeek}.[${activeDay}]`;
  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={{ ...initialNutritionValue }}
        onSubmit={(values) => {
          dispatch(returnWeeksLists(values, id));
        }}
      >
        {(props) => {
          const { values } = props;
          return (
            <Form>
              <ExerciseNutrition />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default NutritionFinalForm;
