import { Box } from "@mui/material";
const CustomIcon = ({ iconSrc, iconHoverSrc, sx, title = "" }) => {
  return (
    <Box
      onMouseEnter={(e) =>
        iconHoverSrc ? (e.target.src = iconHoverSrc) : iconSrc
      }
      onMouseLeave={(e) => (e.target.src = iconSrc)}
      component="img"
      src={iconSrc}
      sx={{ cursor: "pointer", ...sx }}
      title={title}
    ></Box>
  );
};
export default CustomIcon;
